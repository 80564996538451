import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { pasteThunk } from "./clipboard.thunks";

type ClipboardItemType = {
  name: string;
  origin: string;
  value?: string[];
};

type ClipboardStateType = {
  values: ClipboardItemType[] | null;
};

const initialState: ClipboardStateType = {
  values: null,
};

const clipboardSlice = createSlice({
  name: "Clipboard",
  initialState,
  reducers: {
    copy(state, action: PayloadAction<ClipboardItemType>) {
      const index = state.values?.findIndex((item) => item.name === action.payload.name) ?? -1;
      if (index > -1) {
        state.values![index].value = action.payload.value;
      } else {
        state.values = [...(state.values || []), action.payload];
      }
    },
    clear(state) {
      state.values = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(pasteThunk.fulfilled, (state, action) => {
      state.values = state.values?.filter((item) => item.name !== action.payload.name) ?? null;
    });
  },
});

export default clipboardSlice.reducer;

export const { copy, clear } = clipboardSlice.actions;
