import React, { forwardRef } from "react";
import QRCode from "app/QRCode";

import { getId } from "logic/utils";
import { receiveCartItemType, receiveType } from "api/receive";

const columns = [
  { header: "Line", get: (r: receiveCartItemType, idx: number) => idx + 1, color: "#e70202" },
  { header: "Item NO.", get: (r: any, idx: number) => r._itemNo || r.itemNo, color: "#6a6a6a" },
  {
    header: "Item Name",
    get: (r: any, idx: number) => r._LineItemObject?.ItemId?.name || r.itemName,
    color: "#6a6a6a",
  },
  { header: "Ordered Qty", get: (r: any, idx: number) => r.qty || r.ordered, color: "#6a6a6a" },
  { header: "Received Qty", get: (r: any, idx: number) => r.received || r.quantity, color: "#6a6a6a" },
  {
    header: "Remainder Qty",
    get: (r: any, idx: number) => Math.abs((r.received || r.quantity || 0) - (r.qty || r.ordered || 0)),
    color: "#6a6a6a",
  },
];

const ReceivingLineItems = forwardRef<
  HTMLTableElement,
  { lines: (receiveCartItemType & { ReceiveId: string })[]; data: receiveType }
>(({ lines }, ref) => {
  return (
    <table
      ref={ref}
      style={{
        width: "100%",
      }}
    >
      <thead>
        <tr
          style={{
            width: "100%",
            height: "40px",
          }}
        >
          {columns.map((c) => (
            <th
              key={c.header}
              style={{
                textAlign: "start",
                paddingLeft: "10px",
              }}
            >
              <div style={{ borderBottom: "2px solid", borderColor: c.color, color: c.color, textAlign: "center" }}>
                {c.header}
              </div>
            </th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {lines?.map((l, index) => (
          <tr key={index} style={{ borderBottom: "2px solid #E1E1E1" }}>
            {columns.map((c, idx) => (
              <td
                key={c.header}
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {c.get(l, idx)}
              </td>
            ))}
            <td
              className="qrcode-column"
              style={{
                padding: "15px 0",
                verticalAlign: "middle",
                width: 96,
                height: 96,
              }}
            >
              <div
                style={{
                  padding: 10,
                  backgroundColor: "#e1e1e1",
                  borderTopLeftRadius: 10,
                  borderBottomLeftRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <QRCode
                  width={64}
                  height={64}
                  value={JSON.stringify({
                    type: "receive",
                    ItemId: getId(l.ItemId),
                    ReceiveId: l.ReceiveId,
                    id: getId(l),
                  })}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default ReceivingLineItems;
