import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { ArrowBackRounded } from "@mui/icons-material";
import { Formik, Form } from "formik";
import useSWR, { mutate } from "swr";

import Button from "app/Button";
import { IEmployee, addRoleToEmployee } from "api/employee";
import LinkField from "app/Inputs/LinkFields";
import RoleChip from "modules/Employee/RoleChip";

export default function RoleForm({
  employeeId,
  onBack,
  setRefresh,
}: {
  employeeId: string;
  onBack: () => void;
  setRefresh: React.Dispatch<React.SetStateAction<number>>;
}) {
  const [roleId, setRoleId] = useState();
  const { data: employee } = useSWR<IEmployee>(`/employee/${employeeId}`);
  console.log(employee?.roles);

  const handleSubmit = async (data: any, { setSubmitting }: { setSubmitting: (v: boolean) => void }) => {
    try {
      setSubmitting(true);
      await addRoleToEmployee(employeeId, data.role);

      setSubmitting(false);
      mutate(`/employee/${employeeId}`);
      setRefresh((p) => p + 1);
    } catch (error) {
      console.log(data);
    }
  };

  return (
    <Formik initialValues={{} as IEmployee} onSubmit={handleSubmit}>
      {({ values, setFieldValue, isSubmitting, handleSubmit }) => (
        <Form>
          <Button onClick={onBack} sx={{ mb: 2 }} startIcon={<ArrowBackRounded />}>
            Back
          </Button>
          <Box display={"flex"} gap={2}>
            <LinkField
              label="Role"
              filterLabel="name"
              getOptionLabel={(i) => i?.name || ""}
              getOptionList={(r) => r?.result || []}
              getOptionValue={(i) => i.id}
              path="/role"
              choseItem={values.role}
              value={roleId}
              onChange={(e, nv) => {
                setFieldValue("role", nv.id);
                setRoleId(nv.id);
              }}
              style={{ flex: 1 }}
            />
            {isSubmitting ? (
              <CircularProgress size={"small"} />
            ) : (
              <Button type="button" onClick={() => handleSubmit()} kind="add" disabled={isSubmitting} dialogMode>
                Add
              </Button>
            )}
          </Box>
          <Box mt={2} display={"flex"} flexWrap={"wrap"} gap={2} alignItems={"center"}>
            {employee?.roles?.map((role) => (
              <RoleChip
                key={role}
                employeeId={employeeId}
                roleId={role}
                onRemoveDone={() => setRefresh((p) => p + 1)}
              />
            ))}
          </Box>
        </Form>
      )}
    </Formik>
  );
}
