import React, { forwardRef } from "react";
import useSWR from "swr";
import { IVendor } from "api/vendor";
import { IPO } from "api/po";
import { formatTimestampToDate } from "logic/date";
import dspm from "assets/icons/dspm.png";
import { getId } from "logic/utils";

const PoHeader = forwardRef<HTMLDivElement, { vendor?: IVendor; po?: IPO & { _so_number?: string } }>(
  ({ po, vendor }, ref) => {
    const { data: so } = useSWR(po?.SOId ? `/so/${getId(po?.SOId)}` : null);

    return (
      <div
        ref={ref}
        style={{
          width: "100%",
          marginBottom: "10px",
          position: "relative",
        }}
      >
        <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} />
        <p
          style={{
            fontSize: "22px",
            fontWeight: "bold",
            margin: "20px",
          }}
        >
          PURCHASE ORDER
        </p>
        <div
          style={{
            background: "#f1f1f1",
            width: "75%",
            padding: "10px 20px",
            paddingRight: 60,
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 8,
            margin: "8px 0",
          }}
        >
          <div>
            <h3 style={{ fontSize: 14, marginTop: 0 }}>Vendor:</h3>
            <div>{po?.vendorName}</div>
          </div>
          <div>
            <h3 style={{ fontSize: 14, marginTop: 0 }}>SO No:</h3>
            <div>{so?.number || po?._so_number}</div>
          </div>
        </div>
        <div
          style={{
            background: "#f1f1f1",
            width: "75%",
            padding: "4px 16px",
            paddingRight: 60,
            borderTopRightRadius: 50,
            borderBottomRightRadius: 50,
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: 8,
          }}
        >
          <div>
            <h3 style={{ fontSize: 14, marginTop: 0 }}>Bill to:</h3>
            <div>{po?.billingCompany}</div>
            <div>{po?.billingAddress}</div>
            <div>{po?.billingCity + " " + po?.billingState + " " + po?.billingZipcode}</div>
            <div>{po?.billingEmail}</div>
            <div>{po?.billingPhone}</div>
          </div>
          <div>
            <h3 style={{ fontSize: 14, marginTop: 0 }}>Ship to:</h3>
            <div>{po?.shippingCompany}</div>
            <div>Attn: {po?.shippingAttn}</div>
            <div>{po?.shippingAddress}</div>
            <div>{po?.shippingCity + " " + po?.shippingState + " " + po?.shippingZipcode}</div>
            <div>{po?.shippingEmail}</div>
            <div>{po?.shippingPhone}</div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
            width: 280,
            height: 280,
            display: "flex",
            justifyContent: "center",
            background: "#ccc",
            borderBottomLeftRadius: 32,
            border: "4px solid white",
          }}
        >
          <div
            style={{
              marginTop: 40,
              position: "relative",
              zIndex: 5,
              color: "#000",
              width: 200,
            }}
          >
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Purchase Order#: </span>
              <span style={{ fontSize: 12 }}>{po?.number}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Date: </span>
              <span style={{ fontSize: 12 }}>{formatTimestampToDate(po?.createdAt)}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Phone #:</span>
              <span style={{ fontSize: 12 }}>(323) 363-7777</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Fax #:</span>
              <span style={{ fontSize: 12 }}>(323) 363-7777</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>RMA No.:</span>
              <span style={{ fontSize: 12 }}>{}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Terms:</span>
              <span style={{ fontSize: 12 }}>{po?.terms}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Date Acknowledged:</span>
              <span style={{ fontSize: 12 }}>{formatTimestampToDate(po?.ackDate)}</span>
            </div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
              <span style={{ fontSize: 12, fontWeight: "bold" }}>Ship Via:</span>
              <span style={{ fontSize: 12 }}>{po?.shippingCompany}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default PoHeader;
