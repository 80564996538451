import React, { ReactNode, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { KeyboardBackspace } from "@mui/icons-material";
import AssignmentIcon from "@mui/icons-material/Assignment";
import QrIcon from "@mui/icons-material/QrCode";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Divider,
  Toolbar,
  AppBar,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import useSWR from "swr";
import { IEmployee } from "api/employee";
import { BasePaper } from "app/Paper";
import { useLock } from "common/Lock";
import { logout } from "features/Session/sessionsSlice";
import QuestionModal from "components/question/questionModal";
import MoreButton from "common/MoreButton";
import wUser from "assets/icons/proile_icons/wUser.svg";
import wNotif from "assets/icons/proile_icons/wNotif.svg";
import wMsg from "assets/icons/proile_icons/wMsg.svg";
import wKey from "assets/icons/proile_icons/wKey.svg";
import log_out from "assets/icons/proile_icons/exit.svg";
import question from "assets/icons/proile_icons/question.svg";
import ConfirmModal from "features/Modals/Confirm";
import { clearTableColumnsOnServer } from "api/table";
import Toast from "app/Toast";

const dstyle = {
  marginBottom: "4px",
  width: "210px",
};
const adstyle = {
  marginTop: "10px",
  width: "210px",
  backgroundColor: "#E68031",
  display: "flex",
  borderRadius: " 0 10px 10px 0",
  height: "40px",
  alignItems: "center",
  paddingTop: "4px",
};

const profileList = [
  {
    name: "Edit Profile",
    link: "Profile",
    image: <img alt="user" src={wUser} style={{ width: "15px", height: "15px" }} />,
  },
  {
    name: "Notifications",
    link: "profileNotification",
    image: <img alt="user" src={wNotif} style={{ width: "18px", height: "18px" }} />,
  },
  {
    name: "Tickets",
    link: "tickets",
    image: <img alt="user" src={wMsg} style={{ width: "18px", height: "18px" }} />,
  },
  // {
  //   name: "ActionPlan",
  //   link: "ActionPlan",
  //   image: <AssignmentIcon style={{ width: "18px", height: "18px", color: "white" }} />,
  // },
  {
    name: "Tasks",
    link: "tasks",
    image: <AssignmentIcon style={{ width: "18px", height: "18px", color: "white" }} />,
  },
  {
    name: "Change Password",
    link: "changePassword",
    image: <img alt="user" src={wKey} style={{ width: "18px", height: "18px" }} />,
  },
  // {
  //   name: "Question",
  //   link: "questions",
  //   image: <img src={question} alt="question" style={{ width: "18px", height: "18px" }} />,
  // },
  {
    name: "Attendance QR",
    link: "attendance_qr",
    image: <QrIcon />,
  },
];

export default function DashboardLayout({ children, title }: { children: ReactNode; title: string }) {
  const phone = useMediaQuery("(max-width:600px)");
  const location = useLocation();
  const history = useHistory();
  const { setLock } = useLock();
  const dispatch = useDispatch();

  const { data: me } = useSWR<IEmployee>("/employee/me");
  const [open, setOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  return (
    <>
      <ConfirmModal
        title="Clear Saved Table Orders"
        confirmButtonTitle="Clear"
        text="Are you sure you want to clear all changed orders on tables"
        open={confirm}
        onClose={() => setConfirm(false)}
        onConfirm={() => {
          clearTableColumnsOnServer().then(() => {
            setConfirm(false);
            Toast("Orders cleared", "success");
          });
        }}
      />
      <QuestionModal open={open} onClose={() => setOpen(false)} />
      <AppBar
        position="fixed"
        sx={{
          height: "54px",
          BorderBottom: "1px solid gray",
          backgroundColor: "#202831",
          color: "white",
          padding: "0 !important",
          borderRadius: "0px !important",
        }}
      >
        <IconButton
          sx={{ display: "flex", justifyContent: "flex-start", px: phone ? 3 : 6, py: 2.3 }}
          disableRipple
          color="inherit"
          onClick={() => {
            history.push("/panel/home");
          }}
          edge="start"
        >
          <KeyboardBackspace />
        </IconButton>
      </AppBar>
      <Toolbar />
      <Box display="flex" flexDirection={phone ? "column" : "row"} alignItems={"start"}>
        {!phone && (
          <Box sx={{ width: "270px", background: "#202831", height: "92vh" }}>
            <Box display="flex" alignItems="center" p={2}>
              <Avatar />
              <Box mx={1}>
                <Typography sx={{ color: "#FFFFFF" }}>{me?.username}</Typography>
                <Typography sx={{ color: "#FFFFFF" }}>{me?.email}</Typography>
              </Box>
            </Box>
            <Divider sx={{ borderColor: "#8C8C8C" }} />
            <List style={{ marginBottom: "auto", paddingTop: "2px" }}>
              {profileList.map((item, i) => (
                <div style={location.pathname.includes(item.link) ? adstyle : dstyle} onClick={() => setLock(true)}>
                  <Link key={i} to={item.link} style={{ textDecoration: "none", border: "none", outline: "none" }}>
                    <ListItem
                      sx={{
                        p: "3px 12px",
                        color: "#FFFFFF",
                        fontWeight: location.pathname.includes(item.link) ? "bold" : "normal",
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          fill: location.pathname.includes(item.link) ? "#fff" : "#8e8e8e",
                        }}
                        className={location.pathname.includes(item.link) ? "Active" : ""}
                      >
                        {item.image}
                      </ListItemIcon>
                      <ListItemText> {item.name} </ListItemText>
                    </ListItem>
                    <Divider sx={{ color: "#FFFFFF" }} />
                  </Link>
                </div>
              ))}
            </List>

            <ListItem
              style={{
                marginBottom: 12,
                padding: "0 12px",
                width: "100%",
                color: "#000000",
                cursor: "pointer",
              }}
              onClick={() => setConfirm(true)}
            >
              <ListItemIcon>
                <DeleteOutlineIcon style={{ width: "22px", height: "22px", color: "white" }} />
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }}>Clear Table Orders</ListItemText>
            </ListItem>
            <ListItem
              style={{
                width: "100%",
                color: "#000000",
                cursor: "pointer",
              }}
              onClick={() => dispatch(logout())}
            >
              <ListItemIcon>
                <img alt="logout" src={log_out} style={{ width: "18px", height: "18px" }} />
              </ListItemIcon>
              <ListItemText sx={{ color: "#FFFFFF" }}> Exit </ListItemText>
            </ListItem>
          </Box>
        )}

        {phone && (
          <div style={{ paddingTop: 20, paddingLeft: 20 }}>
            <MoreButton width={270}>
              <Box>
                <Box display="flex" alignItems="center" p={2}>
                  <Box mx={1}>
                    <Typography>{me?.username}</Typography>
                    <Typography>{me?.email}</Typography>
                  </Box>
                </Box>
                <Divider sx={{ borderColor: "#8C8C8C" }} />
                <List style={{ marginBottom: "auto", paddingTop: "2px" }}>
                  {profileList.map((item, i) => (
                    <div style={location.pathname.includes(item.link) ? adstyle : dstyle} onClick={() => setLock(true)}>
                      <Link key={i} to={item.link} style={{ textDecoration: "none", border: "none", outline: "none" }}>
                        <ListItem
                          sx={{
                            p: "3px 12px",
                            color: location.pathname.includes(item.link) ? "#FFFFFF" : "#000000",
                            fontWeight: location.pathname.includes(item.link) ? "bold" : "normal",
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              fill: location.pathname.includes(item.link) ? "#fff" : "#000",
                            }}
                            className={location.pathname.includes(item.link) ? "Active" : ""}
                          >
                            {item.image}
                          </ListItemIcon>
                          <ListItemText> {item.name} </ListItemText>
                        </ListItem>
                        <Divider sx={{ color: "#FFFFFF" }} />
                      </Link>
                    </div>
                  ))}
                </List>

                <ListItem
                  style={{
                    width: "100%",
                    color: "#000000",
                    cursor: "pointer",
                  }}
                >
                  <ListItemIcon>
                    <DeleteOutlineIcon />
                  </ListItemIcon>
                  <ListItemText>Clear Table Orders</ListItemText>
                </ListItem>
                <ListItem
                  style={{
                    width: "100%",
                    color: "#000000",
                    cursor: "pointer",
                  }}
                  onClick={() => dispatch(logout())}
                >
                  <ListItemIcon>
                    <img alt="logout" src={log_out} style={{ width: "18px", height: "18px" }} />
                  </ListItemIcon>
                  <ListItemText> Exit </ListItemText>
                </ListItem>
              </Box>
            </MoreButton>
          </div>
        )}

        <BasePaper sx={{ m: "5px auto", height: "auto", width: "95%" }}>
          <Box p={2}>{children}</Box>
        </BasePaper>
      </Box>
    </>
  );
}
