import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { useHistory } from "react-router-dom";
import ProfileMenu from "./Menu";
import { useSelector } from "react-redux";
import { selectSession } from "features/Session/sessionsSlice";
import profile from "assets/icons/header/profile.svg";

export default function NotificationButton() {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const session = useSelector(selectSession);
  const history = useHistory();

  return (
    <Box display={"flex"} alignItems="center" mr={3} style={{ cursor: "pointer" }}>
      <IconButton
        size="small"
        title="User"
        color="inherit"
        onClick={() => history.push("/employee/profileNotification")}
      >
        <img alt="user" src={profile} style={{ width: 26, height: 26 }} />
      </IconButton>
      <span style={{ fontSize: 12 }} onClick={() => history.push("/employee/profileNotification")}>
        {session?.session?.username}
      </span>
      <ProfileMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </Box>
  );
}
