import React, { ReactNode } from "react";
import { CircularProgress, SxProps, Theme } from "@mui/material";
import Button from "app/Button";
import { Gradients } from "theme";

export default function LoadingButton({
  sx,
  loading,
  disabled,
  onlyButton,
  children,
  onClick,
}: {
  sx?: SxProps<Theme>;
  loading?: boolean;
  disabled?: boolean;
  onlyButton?: boolean;
  children?: ReactNode;
  onClick?: () => void;
}) {
  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Button
      variant="contained"
      type={onlyButton ? "button" : "submit"}
      sx={{
        ...sx,
        "&:hover": {
          background: Gradients.success,
        },
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children || "Submit"}
    </Button>
  );
}
