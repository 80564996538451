import React, { forwardRef } from "react";
import phone from "assets/pdf/quote/phone.svg";
import email from "assets/pdf/quote/email.svg";
import footer from "assets/pdf/quote/footer_circle.png";
import numberBg from "assets/pdf/quote/noBg.png";
import { PartFlowType } from "api/partFlow";

const IssueFooter = forwardRef<HTMLDivElement, { data?: PartFlowType }>(({ data }, ref) => {
  return (
    <div
      style={{
        width: "21cm",
        display: "flex",
        justifyContent: "center",
        bottom: 0,
      }}
    >
      <div
        style={{
          width: "100%",
          height: "32px",
          backgroundColor: "#1c2532",
          position: "relative",
          alignItems: "center",
          zIndex: 6,
          bottom: 50,
        }}
      >
        <img alt="" src={footer} style={{ position: "absolute", bottom: -25, left: 0, objectFit: "contain" }} />
        <div
          ref={ref}
          style={{
            width: "75%",
            color: "#fff",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: 40,
            paddingLeft: 80,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img alt="phone" src={phone} />
            <span style={{ fontSize: 12, marginLeft: 10 }}>1.877.377.6769</span>
          </div>
          <p style={{ color: "#FFFFFF" }}>|</p>
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            <img alt="email" src={email} />
            <span style={{ fontSize: 12, marginLeft: 10 }}>Quote@dspmanufacturing.com</span>
          </div>
        </div>

        <div style={{ display: "flex", justifyContent: "center", position: "absolute", bottom: -15, right: 0 }}>
          <img
            alt=""
            src={numberBg}
            width={200}
            height={45}
            style={{ position: "absolute", bottom: 10, right: 0, objectFit: "contain" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              position: "relative",
              zIndex: 6,
              bottom: 10,
              alignItems: "center",
            }}
          >
            <p
              style={{
                fontSize: "12px",
                color: "#1C2532",
                fontWeight: "bold",
                textAlign: "start",
                marginRight: "10px",
              }}
            >
              Partflow No:
            </p>
            <p
              style={{
                fontSize: "12px",
                color: "#1C2532",
                fontWeight: "bold",
                marginRight: "20px",
              }}
            >
              {data?.number}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default IssueFooter;
