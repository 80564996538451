import React, { ReactNode } from "react";
import { Skeleton } from "@mui/material";
import useSWRImmutable from "swr/immutable";

export default function FetchValue({
  url,
  getField,
}: {
  url: string;
  getField: (result?: any) => string | number | boolean | undefined | null | ReactNode;
}) {
  const { data, isLoading } = useSWRImmutable<any>(url);

  if (isLoading) {
    return <Skeleton variant="text" animation="pulse" width={100} />;
  }

  if (!getField(data)) {
    return <></>;
  }

  return <>{getField(data)}</>;
}
