import React, { forwardRef } from "react";
import { styled } from "@mui/material";
import { format } from "date-fns";
import useSWR from "swr";

import { ListResponse } from "api";
import { IInvoice } from "api/invoice";
import { ISO } from "api/so";
import FetchValue from "components/Datagrid/FetchValue";
import { formatCurrency, getId, resizeArray } from "logic/utils";

import dspm from "assets/icons/dspm.png";
import { IUnit } from "api/units";
import { formatTimestampToDate } from "logic/date";

const Wrapper = styled("div")`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  width: 21cm;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .logo {
    border: 1px solid;
    background-color: yellow;
    padding: 10px;
    width: 200px;
  }
  .invoice-details {
    text-align: right;
  }
  .addresses {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .address {
    width: 45%;
    border: 1px solid #000;
    padding: 10px;
  }
  .info-table th,
  .info-table td {
    width: 3cm;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  table th {
    background-color: black;
    color: white;
  }
  table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  th,
  td {
    font-size: 9px;
    padding: 5px;
    text-align: left;
  }
  .totals {
    display: flex;
    gap: 8px;
  }
  .totals p {
    flex: 2;
  }
  .totals table {
    flex: 1;
  }
  .totals td {
    text-align: right;
  }
  .footer {
    display: flex;
    justify-content: space-between;
  }
`;

function InvoiceOld({ invoice }: { invoice: IInvoice }, ref: any) {
  const { data } = useSWR<ListResponse<any>>(`/invoicelineitem?InvoiceId=${getId(invoice)}`);
  const { data: so } = useSWR<ISO>(`/so/${getId(invoice.SOId)}`);
  const { data: soUnits } = useSWR<ListResponse<IUnit>>(invoice.SOId ? `/unit?SOId=${getId(invoice.SOId)}` : null);

  return (
    <Wrapper ref={ref}>
      <div className="header">
        <div className="logo">
          <p>
            DSPM, Inc
            <br />
            PO BOX 277
            <br />
            Mentone, CA, 92359
          </p>
        </div>
        <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} />
        <div className="invoice-details">
          <h1>Invoice</h1>
          <table>
            <tr>
              <th>Date:</th>
              <td>{format(invoice.date, "MM/dd/YYY")}</td>
            </tr>
            <tr>
              <th>Invoice #:</th>
              <td>{invoice.number}</td>
            </tr>
          </table>
        </div>
      </div>

      <div className="addresses">
        <div className="address">
          <h3>Bill To:</h3>
          <p>
            {so?.billingCompany}
            <br />
            {so?.billingAddress}
            <br />
            {so?.billingState}
            {so?.billingState ? "," : ""} {so?.billingCity}
            {so?.billingCity ? "," : ""} {so?.billingZip}
          </p>
        </div>
        <div className="address">
          <h3>Ship To:</h3>
          <p>
            {so?.shippingCompany}
            <br />
            {so?.shippingAddress}
            <br />
            {so?.shippingState}
            {so?.shippingState ? "," : ""} {so?.shippingCity}
            {so?.shippingCity ? "," : ""} {so?.shippingZip}
          </p>
        </div>
      </div>

      <table className="info-table">
        <tr>
          <th>S.O. No.</th>
          <th>Serial No.</th>
          <th>P.O. No.</th>
          <th>Terms</th>
          <th>Ship Date</th>
          <th>Ship Via</th>
          <th>FOB</th>
        </tr>
        <tr>
          <td>{so?.number}</td>
          <td>
            {soUnits?.result
              ?.find((i) => i.class === "device")
              ?.serial?.split("-")
              ?.at(-1)}
          </td>
          <td>{so?.customerPONumber}</td>
          <td>
            {so?.paymentTerms} {so?.paymentTerms ? "," : ""} {so?.freightTerms}
          </td>
          <td>{so?.actualShipDate ? formatTimestampToDate(so?.actualShipDate) : ""}</td>
          <td>{so?.carrier}</td>
          <td></td>
        </tr>
      </table>

      <table className="line-items-table">
        <tr>
          <th>Description</th>
          <th style={{ width: "1cm" }}>Qty</th>
          <th style={{ width: "1cm" }}>U/M</th>
          <th style={{ width: "1cm" }}>Rate</th>
          <th style={{ width: "2cm" }}>S.O. No.</th>
          <th>Amount</th>
        </tr>
        {resizeArray(data?.result || [], 18).map((i) => (
          <tr key={getId(i)}>
            <td>{i.ItemId?.description}</td>
            <td style={{ width: "1cm" }}>{i.qty}</td>
            <td style={{ width: "1cm" }}>{i.ItemId?.uom}</td>
            <td style={{ width: "1cm" }}>{i.ItemId ? formatCurrency(i.ItemId?.price || 0) : ""}</td>
            <td style={{ width: "2cm" }}>
              {i.SOId ? <FetchValue url={`/so/${getId(i.SOId)}`} getField={(o) => o?.number} /> : <></>}
            </td>
            <td>{i.total === undefined ? <div style={{ height: "0.5cm" }} /> : formatCurrency(i.total || 0)}</td>
          </tr>
        ))}
      </table>

      <div className="totals">
        <p style={{ textAlign: "left" }}>
          <strong>Please Note:</strong>
          This Invoice amount is Based on Bank Draft or ACH. Any Other Payment Method, there will be a 3.5% Handling Fee
          applied.
        </p>
        <table>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: 11, width: "150px", textAlign: "left" }}>Subtotal</td>
            <td style={{ width: "70px" }}>{formatCurrency(invoice.orderTotal || 0)}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: 11, width: "150px", textAlign: "left" }}>
              Sales Tax ({invoice.salesTaxPercent || 0}%)
            </td>
            <td style={{ width: "70px" }}>{formatCurrency(invoice.taxable || 0)}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: 11, width: "150px", textAlign: "left" }}>Total</td>
            <td style={{ width: "70px" }}>{formatCurrency(invoice.totalAmount || 0)}</td>
          </tr>
          <tr>
            <td style={{ fontWeight: "bold", fontSize: 11, width: "150px", textAlign: "left" }}>Balance Due</td>
            <td style={{ width: "70px" }}>$0.00</td>
          </tr>
        </table>
      </div>

      <div className="footer">
        <p>Phone #: 909-930-3523</p>
        <p>Fax #:</p>
        <p>E-mail: ballard@dspmmanufacturing.com</p>
      </div>
    </Wrapper>
  );
}

export default forwardRef(InvoiceOld);
