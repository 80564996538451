import React, { ChangeEvent, useState } from "react";
import { Tabs, Tab, useMediaQuery, Box, Button, Typography } from "@mui/material";
import FileUploader from "app/UploadButton";
import { useLock } from "common/Lock";
import { PostType } from "api/magazine";
import { patch, post } from "api";
import { toast } from "react-toastify";
import PostDocumnets from "./PostDocuments";
import TextEditor from "components/TextEditor";
import { host } from "host";
import PostImages from "./PostImages";

export default function BlogDataGridTabs({
  selectedPost,
  handleSubmit,
  values,
  setFieldValue,
}: {
  selectedPost: PostType;
  handleSubmit: any;
  values: any;
  setFieldValue: any;
}) {
  const sPhone = useMediaQuery("(max-width:850px)");
  const [activeTab, setActiveTab] = useState(0);
  const { lock, setLock } = useLock();
  const [htmlContent, setHtmlContent] = useState(values?.content || "<h1>Hello world!</h1>");
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editorInstance, setEditorInstance] = useState<any>(null);
  const [contentSubmitting, setContentSubmitting] = useState(false);

  const handleSubmitContent = () => {
    if (!htmlContent) return;
    setContentSubmitting(true);
    patch(`/post/${selectedPost?.id}`, { content: htmlContent }).then(() => {
      toast.success("post updates successfully");
      setContentSubmitting(false);
    });
  };

  const handleSubmitContentImage = () => {
    if (!file || !editorInstance) return;
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append("photo", file);
    post(`photo/post/${selectedPost?.id}`, formData)
      .then((response) => {
        toast.success("post updates successfully");
        if (response && response?.path) {
          const currentContent = editorInstance.getContent();
          const imgHtml = `<img src="${host + response.path}" alt="" style="width:50%; height:50%"/>`;
          // editorInstance.execCommand('mceInsertContent', false, imgHtml);
          editorInstance.setContent(currentContent + imgHtml);
          setHtmlContent(editorInstance.getContent());
        }
      })
      .finally(() => {
        setIsSubmitting(false);
        setFile(null);
      });
  };

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <>
      <Box>
        <Box display="flex" mb={1} alignItems="center">
          <Tabs
            value={activeTab}
            onChange={(e, v) => {
              setActiveTab(v);
              setLock(true);
            }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ maxWidth: sPhone ? "calc(100vw - 60px)" : "calc(100vw - 700px)" }}
          >
            <Tab label="Main Content" />
            <Tab label="Documents" />
            <Tab label="Images" />
          </Tabs>
        </Box>
        {activeTab === 0 && (
          <Box>
            <TextEditor
              setHtmlContent={setHtmlContent}
              initialValue={htmlContent}
              onEditorInit={(editor) => setEditorInstance(editor)}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                borderRadius: "1rem",
                border: "1px solid gray",
                p: "1.5rem",
                mt: "2rem",
              }}
            >
              <Typography
                sx={{
                  mt: "-2.4rem",
                  fontSize: "15px",
                  fontWeight: 700,
                  bgcolor: "#FFF",
                  width: "9rem",
                  mb: "1rem",
                  pl: "0.4rem",
                }}
              >
                Content Image
              </Typography>

              <Box sx={{ display: "flex", width: "20rem" }}>
                <FileUploader
                  onChange={handleFileUpload}
                  onError={(err) => toast.error(err)}
                  allowedTypes={["image"]}
                  maxSize={5}
                  title={"choose Image"}
                />
              </Box>
              <Button
                disabled={!file || isSubmitting}
                onClick={handleSubmitContentImage}
                sx={{ width: "20rem", mt: "2rem" }}
                variant="contained"
              >
                {isSubmitting ? "Uploading..." : "Upload Image"}
              </Button>
            </Box>

            <Button disabled={!htmlContent || contentSubmitting} onClick={handleSubmitContent} variant="contained" color="success" sx={{ mt: "2rem" }}>
             {contentSubmitting ? "Updating..." : "Submit Content"}
            </Button>
          </Box>
        )}
        {activeTab === 1 && <PostDocumnets selectedPost={selectedPost} />}
        {activeTab === 2 && <PostImages selectedPost={selectedPost} />}
      </Box>
    </>
  );
}
