import React from "react";
import { IconButton } from "@mui/material";

import { useResponsiveNavigate } from "logic/hooks";
import icon from "assets/icons/view_more_2.svg";

export default function GoToButton({
  href,
  onClick,
  disabled,
}: {
  href?: string;
  onClick?: () => void;
  disabled?: boolean;
}) {
  const navigate = useResponsiveNavigate();

  return (
    <IconButton onClick={onClick || (() => href && navigate(href))} disabled={disabled} title="Show Detail">
      <img src={icon} alt="go to" />
    </IconButton>
  );
}
