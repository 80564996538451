import React from "react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";

import sales from "assets/footer/sales.svg";
import eng from "assets/footer/eng.svg";
import oEng from "assets/footer/oEng.svg";
import inventory from "assets/footer/inventory.svg";
import purchase from "assets/footer/purchase.svg";
import home from "assets/footer/home.svg";
import oSales from "assets/footer/oSales.svg";
import oHome from "assets/footer/oHome.svg";
import oPurchase from "assets/footer/oPurchasing.svg";
import oInventory from "assets/footer/oInventory.svg";

export default function BottomNav({ value, handleChange }: { value: string; handleChange: any }) {
  const [click1, setClick1] = React.useState(false);
  const [click2, setClick2] = React.useState(false);
  const [click3, setClick3] = React.useState(false);
  const [click4, setClick4] = React.useState(false);
  const [click5, setClick5] = React.useState(false);

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      sx={{
        width: "100vw",
        position: "fixed",
        bottom: 0,
        zIndex: 6,
        boxShadow: "rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px",
        background: "rgb(30,40,50)",
        color: "#9e9e9e",
        "& .Mui-selected": {
          color: "rgb(230,128,49)",
        },
        "& .icon": {
          color: "#9e9e9e",
        },
        "& .selected": {
          color: "rgb(230,128,49)",
        },
      }}
    >
      <BottomNavigationAction
        label="Sales"
        value="/panel/sales"
        onClick={() => {
          setClick5(false);
          setClick1(true);
          setClick2(false);
          setClick3(false);
          setClick4(false);
        }}
        icon={
          click1 ? (
            <img style={{ width: "25px" }} src={oSales} alt="sales" />
          ) : (
            <img style={{ width: "25px" }} src={sales} alt="sales" />
          )
        }
        style={{ marginRight: "15px" }}
      />
      <BottomNavigationAction
        label="Purchasing"
        value="/panel/purchase"
        onClick={() => {
          setClick5(false);
          setClick1(false);
          setClick2(true);
          setClick3(false);
          setClick4(false);
        }}
        icon={
          click2 ? (
            <img style={{ width: "25px" }} src={oPurchase} alt="purchase" />
          ) : (
            <img style={{ width: "25px" }} src={purchase} alt="purchase" />
          )
        }
      />
      <BottomNavigationAction
        label="Home"
        value="/panel/home"
        onClick={() => {
          setClick5(false);
          setClick1(false);
          setClick2(false);
          setClick3(true);
          setClick4(false);
        }}
        icon={
          click3 ? (
            <img style={{ width: "25px" }} src={oHome} alt="purchase" />
          ) : (
            <img style={{ width: "20px" }} src={home} alt="home" />
          )
        }
      />

      <BottomNavigationAction
        label="Inventory"
        value="/panel/inventory"
        onClick={() => {
          setClick5(false);
          setClick1(false);
          setClick2(false);
          setClick3(false);
          setClick4(true);
        }}
        icon={
          click4 ? (
            <img style={{ width: "25px" }} src={oInventory} alt="purchase" />
          ) : (
            <img style={{ width: "20px" }} src={inventory} alt="inventory" />
          )
        }
      />
      <BottomNavigationAction
        onClick={() => {
          setClick5(true);
          setClick1(false);
          setClick2(false);
          setClick3(false);
          setClick4(false);
        }}
        label="Engineering"
        value="/panel/engineering"
        icon={
          click5 ? (
            <img style={{ width: "25px" }} src={oEng} alt="eng" />
          ) : (
            <img style={{ width: "20px" }} src={eng} alt="eng" />
          )
        }
      />
    </BottomNavigation>
  );
}
