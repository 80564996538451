import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import useSWR from "swr";

import { createAModelDocument } from "api/document";
import { formatTimestampToDate } from "logic/date";
import { addElementToPdf } from "logic/pdf";
import Toast from "app/Toast";
import MyDialog from "app/Dialog";
import Shipping from "PDFTemplates/Shipping";
import { shippingType } from "api/shipping";

export default function ShippingPdfModal({
  open,
  shippingId,
  onClose,
  onDone,
}: {
  shippingId: string;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data } = useSWR<shippingType>(`/shipping/${shippingId}`);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const [loading, setLoading] = useState(false);

  const handleExport = useCallback(async () => {
    try {
      setLoading(true);
      if (headerRef.current) {
        let doc = new jsPDF();

        doc = await addElementToPdf({
          pdf: doc,
          element: headerRef.current,
          x: 0,
          y: 0,
        });

        doc.save(
          data?.number
            ? `Shipping-${data?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`
            : `Shipping-${formatTimestampToDate(Number(new Date()))}.pdf`
        );
        const generatedPdf = doc.output("blob");
        await createAModelDocument({
          model: "shipping",
          id: shippingId,
          file: generatedPdf,
          description: `${new Date().toJSON().slice(0, 19)} - ${data?.number}`,
          name: `Shipping_${data?.number}.pdf`,
          fileName: `Shipping_${data?.number}.pdf`,
        });
        Toast("Document saved", "success");
        onClose();
        onDone && onDone();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [data?.number, onClose, onDone, shippingId]);

  useEffect(() => {
    const t = setTimeout(() => {
      handleExport();
    }, 3000);

    return () => clearTimeout(t);
  }, [handleExport]);

  return (
    <MyDialog title="Shipping Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box width="21cm" m={2} mx="auto">
        <Button variant="contained" onClick={handleExport} sx={{ mb: 1 }} disabled={loading}>
          Save
        </Button>
        <Shipping shippingId={shippingId} ref={headerRef} />
      </Box>
    </MyDialog>
  );
}
