import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import useSWR from "swr";

import { get } from "api";
import { IPO, POLineItem, uploadPODocument } from "api/po";
import { IVendor } from "api/vendor";
import { formatTimestampToDate } from "logic/date";
import { PDF } from "logic/pdf/PDF";
import MyDialog from "app/Dialog";
import Toast from "app/Toast";

import PoHeader from "PDFTemplates/SalesPo/header";
import PoLineItems from "PDFTemplates/SalesPo/lineitems";
import PoTotal from "PDFTemplates/SalesPo/Total";

export default function PurchasePOPdf({
  open,
  POId,
  onClose,
  onDone,
}: {
  open: boolean;
  POId: string;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data: po } = useSWR<IPO>(POId ? `/po/${POId}` : null);
  const { data: poLineItems } = useSWR<{ result: POLineItem[]; total: number }>(
    POId ? `/polineitem?POId=${POId}` : null
  );

  const { data: vendor } = useSWR<IVendor>(po?.VendorId ? `/vendor/${po?.VendorId}` : null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLTableElement | null>(null);
  const totalRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = useCallback(
    async (download?: boolean) => {
      try {
        if (!po || !poLineItems || !vendor || uploading) {
          return;
        }
        const dspmAddress = await get("/address/dspm");

        const pdf = new PDF({
          phone: "(909) 930-3353",
          title: "Purchase Order",
          summaryData: {
            "Customer PO#": po?.number || "",
            Date: po?.date ? formatTimestampToDate(po?.date) : "",
            Terms: po?.terms || "",
            "Date Acknowledged": po?.ackDate ? formatTimestampToDate(po?.ackDate) : "",
            "Ship Via": po?.shippingCompany || "",
          },
          headerCardData: {
            col1: ["Vendor", vendor ? vendor?.name + vendor?.address : ""],
            col2: [
              "Bill to",
              dspmAddress
                ? `${dspmAddress.company || ""}, ${dspmAddress.city || ""}, ${dspmAddress.address || ""}, ${
                    dspmAddress.email || ""
                  }, ${dspmAddress.phone || ""}`
                : "",
            ],
            col3: [
              "Ship to",
              dspmAddress
                ? `${dspmAddress.company || ""}, ${dspmAddress.city || ""}, ${dspmAddress.address || ""}, ${
                    dspmAddress.email || ""
                  }, ${dspmAddress.phone || ""}`
                : "",
            ],
          },
        });

        pdf.addTable({
          y: pdf.cursor.y + 10,
          head: [
            {
              line: "Line",
              itemNo: "Item NO.",
              itemName: "Item Name",
              qty: "Qty",
              cost: "Cost",
              um: "U/M",
              amount: "Total",
            },
          ],
          body: poLineItems?.result.map((pl, i) => ({
            line: i + 1,
            itemNo: pl.itemNo || pl.ItemId?.no || "",
            itemName: pl.itemName || pl.ItemId?.name || "",
            qty: pl.qty,
            cost: pl.cost,
            um: (pl.ItemId as any)?.uom || "",
            amount: pl.cost * pl.qty || 0,
          })),
          total: poLineItems?.result.reduce((prev, curr) => prev + curr.cost * curr.qty, 0) || 0,
          note:
            "Any product that requires a data sheet, drawings or specs, needs to be made to the DSPM specifications provided on documents or it will not be accepted. " +
              po.publicNote || "",
        });

        if (download) {
          pdf.save(`PO_${po.number}.pdf`);
        }
        return pdf.output();
      } catch (error) {
        console.log(error);
      }
    },
    [po, poLineItems, uploading, vendor]
  );

  useEffect(() => {
    const upload = async () => {
      try {
        setUploading(true);
        const pdf = await handleDownload(true);
        if (!pdf) {
          return;
        }

        await uploadPODocument({
          blob: pdf,
          POId,
          poNumber: po?.number,
        });

        Toast("PO document uploaded", "success");

        onDone && onDone();
        onClose();
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    };

    let t = setTimeout(() => {
      if (!uploaded && open && !uploading) {
        upload();
      }
    }, 1000);

    return () => clearTimeout(t);
  }, [POId, handleDownload, onClose, onDone, open, po, uploaded, uploading, vendor]);

  return (
    <MyDialog title="PO pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth disabled={uploading}>
      <Box sx={{ height: "80vh", overflowY: "auto" }}>
        <Button disabled={uploading} sx={{ ml: 3, mb: 2 }} variant="contained">
          {uploading ? "Uploading" : "Download"}
        </Button>
        {po ? (
          <div style={{ width: "21cm", height: "29cm", border: "1px solid", margin: "auto" }}>
            <PoHeader ref={headerRef} po={po} vendor={vendor} />
            <PoLineItems ref={lineItemsRef} lines={poLineItems?.result || []} />
            <PoTotal ref={totalRef} sum={po?.total} />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Box>
    </MyDialog>
  );
}
