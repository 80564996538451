// import SectionSvg from "../../../assets/svg/section.svg?react";
import { ReactComponent as SectionSvg } from "../../../assets/svg/section.svg";
export default function SectionHeader({ title, number }) {
  return ( 
    <div className="relative w-full">
      <SectionSvg className='w-full h-auto'/>
        <div className="absolute left-[10%] bottom-[25%] flex items-center">
          {/* <span className="font-bold text-white mr-1">Page</span> */}
          <span className="font-bold text-white text-[16px]">{number}.</span>
        </div>
        <div className="absolute left-[13%] bottom-[25%] flex items-center">
          {/* <span className="font-bold text-white mr-1">Page</span> */}
          <span className=" text-white font-bold text-[16px]">{title}</span>
        </div>
    </div>
   );

}