import {
  addQuarters,
  eachQuarterOfInterval,
  endOfYear,
  format,
  startOfYear,
  set,
  startOfQuarter,
  setQuarter,
  endOfQuarter,
} from "date-fns";

export function formatSeconds(seconds: number): string {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;
}

export const formatTimestampToDate = (timestamp?: number | string | null | Date) => {
  if (!timestamp || String(timestamp).includes("P")) {
    return "";
  }
  if (typeof timestamp === "string" || typeof timestamp === "number") {
    return format(new Date(timestamp), "MM-dd-yyyy");
  }
  return format(timestamp, "MM-dd-yyyy");
};

export const formatTimesToHour = (timestamp?: number) => {
  return timestamp ? format(timestamp, "MM-dd-yyyy, hh:mm aaa") : "";
};

export function formatDateValue(v: any) {
  if (v) {
    return new Date(v).toISOString().slice(0, 10);
  }
  return null;
}

export function getYearQuarters(data = Number(new Date())) {
  const start = startOfYear(data);
  const end = endOfYear(data);

  const quarters = eachQuarterOfInterval({
    start,
    end,
  });
  const nextQuarter = addQuarters(quarters[quarters.length - 1], 1);

  return [...quarters, nextQuarter];
}

export function setYear(date?: number, year?: number) {
  if (!date) {
    return undefined;
  }
  if (!year) {
    return date;
  }
  return Number(set(date, { year }));
}

export function getYearInterval(year: number) {
  const d = Number(set(new Date(), { year }));

  return { start: Number(startOfYear(d)), end: Number(endOfYear(d)) };
}

export function getYearQuarterRange({
  year,
  quarter,
}: {
  year: number;
  quarter: "all" | "1st Quarter" | "2nd Quarter" | "3rd Quarter" | "4th Quarter";
}) {
  let start = set(new Date(), { year });
  let end = set(new Date(), { year });

  if (quarter === "all") {
    start = startOfYear(start);
    end = endOfYear(start);
  }
  if (quarter === "1st Quarter") {
    start = startOfQuarter(setQuarter(start, 1));
    end = endOfQuarter(setQuarter(start, 1));
  }
  if (quarter === "2nd Quarter") {
    start = startOfQuarter(setQuarter(start, 2));
    end = endOfQuarter(setQuarter(start, 2));
  }
  if (quarter === "3rd Quarter") {
    start = startOfQuarter(setQuarter(start, 3));
    end = endOfQuarter(setQuarter(start, 3));
  }
  if (quarter === "4th Quarter") {
    start = startOfQuarter(setQuarter(start, 4));
    end = endOfQuarter(setQuarter(start, 4));
  }

  return { start, end };
}

export function hasRecent(logs: Date[], offset = 1): boolean {
  const oneMinuteAgo = new Date(Date.now() - offset * 60 * 1000); // 60000 milliseconds = 1 minute

  return logs.some((log) => log >= oneMinuteAgo);
}
