import { ReactComponent as SectionSvg } from "assets/svg/triangle.svg";

export default function SectionHeader({ title }) {
  return ( 
    <div className="flex flex-row w-full items-center">
      <SectionSvg className='w-auto h-full'/>
      <span className="text-xl font-bold text-primaryOrange ml-4">{title}</span>
    </div>
   );

}