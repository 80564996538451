// import Header from "./header/header.svg?react";
import { ReactComponent as Header } from "./header/header.svg";
import { ReactComponent as Logo } from "../../../assets/svg/LogoBG.svg";

// import Logo from "../../../assets/svg/LogoBG.svg?react";
export default function POpage1() {

  return (
    <div className="flex flex-col items-center h-full mx-auto">
      <div className="flex w-full">
        <Header className="w-full h-auto" />
      </div>

      <div className="flex justify-center  w-full mt-[10%]">
        <Logo className="w-1/3 h-auto" />
      </div>
      <div className="flex flex-1 flex-col w-full ">
        <Content />
      </div>
    </div>
  );
}

function Content() {
  const contactData = {
    address: "1921 So. Quaker Ridge PlaceOntario, CA 91761",
    voice: "(714) 970-2304",
    fax: "(909) 930-3335",
    email: "customersupport@dspmanufacturing.com",
  };
  const text1 =
    "All attached service forms are to be filled out completely, and  returned via fax or email within 24 hours of the completion of  the service call.";
  const text2 =
    "Vendor invoices will not be processed until the receipt of the  service forms completely filled out with customer signature  and the return of all parts unused and used.";
  const text3 =
    "Vendors wishing to use there own forms may do so as an  addition to the forms attached.";

  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };

  return (
    <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
      <div className="flex justify-center w-full mt-[5%]">
        <span className=" text-[30px] text-primaryBlue font-bold">
          CUSTOMER SERVICE REPORT
        </span>
      </div>
      <div className="flex justify-center w-full mt-[2%] ">
        <span className=" text-[16px] text-black">
          Digital Signal Power Manufacturer, Inc.- PowerClone
        </span>
      </div>
      <div className="flex flex-col w-full mt-[15%]">
        <span
          className="text-[16px]  text-black"
          // style={{ lineHeight: "12px" }}
        >
          {text1}
        </span>
      </div>
      <div className="flex flex-col  w-full mt-[7%]">
        <span className=" text-black text-[16px]">
          {text2}
        </span>
      </div>
      <div className="flex flex-col w-full mt-[7%] ">
        <span className="text-[16px]  text-black font-normal">
         {text3}
        </span>
      </div>
      <div className="flex flex-col mt-auto mb-[10%]">
        <div className="flex flex-col w-full">
          <div className="flex-row flex">
            <span className="text-[16px]">{contactData.address}</span>
          </div>
          <div className="flex-row flex  mt-[1%] w-full">
            <div className="flex flex-row">
              <div>
                <span className="text-[16px] me-[1%]">Voice:</span>
              </div>
              <div>
                <span className="text-[16px]">{contactData.voice}</span>
              </div>
            </div>
            <div className="flex flex-row ml-7">
              <div>
                <span className="text-[16px] me-[1%]">Fax:</span>
              </div>
              <div>
                <span className="text-[16px]">{contactData.fax}</span>
              </div>
            </div>
          </div>
          <div className="flex-row flex mt-[1%]">
            <span className=" me-[1%] text-[16px]">Email: </span>
            <span className="text-[16px]">{contactData.email} </span>
          </div>
        </div>
      </div>
    </div>
  );
}
