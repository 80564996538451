import React, { useCallback, useEffect, useRef, useState } from "react";
import { AppBar, Box, Button, LinearProgress, Toolbar } from "@mui/material";
import useSWR from "swr";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { get } from "api";
import { IPO, POLineItem, uploadPODocument } from "api/po";
import { IVendor } from "api/vendor";
import { getAllModelDocuments } from "api/document";
import { formatTimestampToDate } from "logic/date";
import { PDF } from "logic/pdf/PDF";
import MyDialog from "app/Dialog";
import Toast from "app/Toast";
import PoHeader from "PDFTemplates/SalesPo/header";
import PoLineItems from "PDFTemplates/SalesPo/lineitems";
import PoTotal from "PDFTemplates/SalesPo/Total";
import POpage1 from "PDFTemplates/New/PO/POpage1";
import POpage2 from "PDFTemplates/New/PO/POpage2";
import POpage3 from "PDFTemplates/New/PO/POpage3";
import POpage4 from "PDFTemplates/New/PO/POpage4";
import POpage5 from "PDFTemplates/New/PO/POpage5";
import POpage6 from "PDFTemplates/New/PO/POpage6";
import POpage7 from "PDFTemplates/New/PO/POpage7";
import POpage8 from "PDFTemplates/New/PO/POpage8";
import POpage9 from "PDFTemplates/New/PO/POpage9";

export default function POPdf({
  open,
  POId,
  onClose,
  onDone,
}: {
  open: boolean;
  POId: string;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data: po } = useSWR<IPO>(POId ? `/po/${POId}` : null);
  const { data: poLineItems } = useSWR<{ result: POLineItem[]; total: number }>(
    POId ? `/polineitem?POId=${POId}` : null
  );

  const { data: vendor } = useSWR<IVendor>(po?.VendorId ? `/vendor/${po?.VendorId}` : null);
  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLTableElement | null>(null);
  const totalRef = useRef<HTMLDivElement | null>(null);
  const POPagesRef = useRef<HTMLDivElement | null>(null);

  const handleDownload = useCallback(
    async (download: boolean) => {
      try {
        if (!po || !POPagesRef.current) {
          return;
        }

        const pdf = new jsPDF("p", "pt", "a4");
        const pages = POPagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i] as HTMLElement;
          const canvas = await html2canvas(page, { scale: 2 }); // Higher scale for better quality
          const imgData = canvas.toDataURL("image/jpeg", 0.8); // Using JPEG format with quality set to 0.8

          const imgWidth = 595.28; // A4 width in points
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (i > 0) pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "MEDIUM");
        }

        if (download) {
          pdf.save(`PO_${po.number}.pdf`);
        }

        return pdf.output("blob");
      } catch (error) {
        console.log(error);
      }
    },
    [po, uploading]
  );
  // const handleDownload = useCallback(
  //   async (download?: boolean) => {
  //     try {
  //       if (!po || !poLineItems || !vendor || uploading) {
  //         return;
  //       }
  //       const dspmAddress = await get("/address/dspm");

  //       const pdf = new PDF({
  //         phone: "(909) 930-3353",
  //         title: "Purchase Order",
  //         summaryData: {
  //           "Customer PO#": po?.number || "",
  //           Date: po?.date ? formatTimestampToDate(po?.date) : "",
  //           Terms: po?.terms || "",
  //           "Date Acknowledged": po?.ackDate ? formatTimestampToDate(po?.ackDate) : "",
  //           "Ship Via": po?.shippingCompany || "",
  //         },
  //         headerCardData: {
  //           col1: ["Vendor", vendor ? vendor?.name + vendor?.address : ""],
  //           col2: [
  //             "Bill to",
  //             dspmAddress
  //               ? `${dspmAddress.company || ""}, ${dspmAddress.city || ""}, ${dspmAddress.address || ""}, ${
  //                   dspmAddress.email || ""
  //                 }, ${dspmAddress.phone || ""}`
  //               : "",
  //           ],
  //           col3: [
  //             "Ship to",
  //             dspmAddress
  //               ? `${dspmAddress.company || ""}, ${dspmAddress.city || ""}, ${dspmAddress.address || ""}, ${
  //                   dspmAddress.email || ""
  //                 }, ${dspmAddress.phone || ""}`
  //               : "",
  //           ],
  //         },
  //       });

  //       pdf.addTable({
  //         y: pdf.cursor.y + 10,
  //         head: [
  //           {
  //             line: "Line",
  //             itemNo: "Item NO.",
  //             itemName: "Item Name",
  //             qty: "Qty",
  //             cost: "Cost",
  //             um: "U/M",
  //             amount: "Total",
  //           },
  //         ],
  //         body: poLineItems?.result.map((pl, i) => ({
  //           line: i + 1,
  //           itemNo: pl.itemNo || pl.ItemId?.no || "",
  //           itemName: pl.itemName || pl.ItemId?.name || "",
  //           qty: pl.qty,
  //           cost: pl.cost,
  //           um: (pl.ItemId as any)?.uom || "",
  //           amount: pl.cost * pl.qty || 0,
  //         })),
  //         total: poLineItems?.result.reduce((prev, curr) => prev + curr.cost * curr.qty, 0) || 0,
  //         note:
  //           "Any product that requires a data sheet, drawings or specs, needs to be made to the DSPM specifications provided on documents or it will not be accepted. " +
  //             po.publicNote || "",
  //       });

  //       if (download) {
  //         pdf.save(`PO_${po.number}.pdf`);
  //       }
  //       return pdf.output();
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   },
  //   [po, poLineItems, uploading, vendor]
  // );

  useEffect(() => {
    const upload = async () => {
      try {
        setUploading(true);
        const pdf = await handleDownload(true);
        if (!pdf) {
          return;
        }

        await uploadPODocument({
          blob: pdf,
          POId,
          poNumber: po?.number,
        });

        Toast("PO document uploaded", "success");

        onDone && onDone();
        onClose();
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
      }
    };

    let t = setTimeout(() => {
      if (!uploaded && open && !uploading) {
        upload();
      }
    }, 1000);

    return () => clearTimeout(t);
  }, [POId, handleDownload, onClose, onDone, open, po, uploaded, vendor]);

  const POdocs = [
    { component: <POpage1 />, key: "page1" },
    { component: <POpage2 data={po} />, key: "page2" },
    { component: <POpage3 data={po} />, key: "page3" },
    { component: <POpage4 data={po} />, key: "page4" },
    { component: <POpage5 data={po} />, key: "page5" },
    { component: <POpage6 data={po} />, key: "page6" },
    { component: <POpage7 data={po} />, key: "page7" },
    { component: <POpage8 data={po} itemData={poLineItems?.result} />, key: "page8" },
    { component: <POpage9 data={po} />, key: "page9" },
  ];

  return (
    <MyDialog title="PO pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth disabled={uploading}>
      <Button disabled={uploading} sx={{ ml: 3, mb: 2 }} variant="contained" onClick={() => handleDownload(true)}>
        {uploading ? "Uploading" : "Download"}
      </Button>
      <div className="flex-1 overflow-y-auto bg-gray-100" ref={POPagesRef}>
        {POdocs.map(({ component, key }) => (
          <div
            className="w-[21cm] h-[29.7cm] bg-white border border-gray-300 mx-auto my-4 shadow-lg pdf-page"
            key={key}
          >
            {component} {/* Render the corresponding page component here */}
          </div>
        ))}
      </div>
      {/* <Box sx={{ height: "80vh", overflowY: "auto" }}>
        <Button disabled={uploading} sx={{ ml: 3, mb: 2 }} variant="contained">
          {uploading ? "Uploading" : "Download"}
        </Button>
        {po ? (
          <div style={{ width: "21cm", height: "29cm", border: "1px solid", margin: "auto" }}>
            <PoHeader ref={headerRef} po={po} vendor={vendor} />
            <PoLineItems ref={lineItemsRef} lines={poLineItems?.result || []} />
            <PoTotal ref={totalRef} sum={po?.total} />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Box> */}
    </MyDialog>
  );
}
