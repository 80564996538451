import React, { useEffect, useRef, useState } from "react";
import { Box, LinearProgress, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

import Toast from "app/Toast";
import MyDialog from "app/Dialog";

import { formatTimestampToDate } from "logic/date";
import { createAModelDocument } from "api/document";
import { instructionType } from "api/instruction";

import ProcedureInfo from "PDFTemplates/Procedure/info";
import ProcedureSteps from "PDFTemplates/Procedure/Steps";
import ProcedureHeader from "PDFTemplates/Procedure/Header";

import Footer from "assets/icons/Footer.png";

const handler = (ev: any) => {
  Toast("Please close the result dialog first to upload and save generated document", "info");
  ev.preventDefault();
  return (ev.returnValue = "Are you sure you want to close?");
};

export default function ResultDialog({
  open,
  steps,

  status,
  unitId,
  variant,
  onClose,
  serial,
  model,
  description,
  name,
}: {
  variant: string;
  status: "failed" | "success";
  steps: instructionType[] | any;
  open: boolean;
  unitId: string;
  onClose: () => void;
  serial: any;
  model: any;
  description: any;
  name: string;
}) {
  const [uploading, setUploading] = useState(false);

  const headerRef = useRef<HTMLDivElement>(null);
  const infoRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  const generatePdf = async (upload?: boolean) => {
    try {
      setUploading(true);
      const scale = 0.265;
      const pageHeight = 290;
      const pageMarginY = 2;

      if (headerRef.current && infoRef.current && listRef.current) {
        const doc = new jsPDF();
        const docWidth = doc.internal.pageSize.getWidth();
        const docHeight = doc.internal.pageSize.getHeight();

        await doc.html(headerRef.current, {
          x: 0,
          y: 0,
          html2canvas: { scale },
        });
        await doc.html(infoRef.current, {
          x: 0,
          y: 60,
          html2canvas: { scale },
        });

        let positionY = 170;
        let positionX = 10;
        let currentPage = 1;

        const writeFooter = () => {
          doc.setFontSize(11);

          doc.text("www.dspmanufacturing.com", 10, docHeight - 10);
          doc.text("Page " + currentPage, docWidth - 20, docHeight - 10);
        };

        const addNewPage = () => {
          writeFooter();
          doc.addPage();
          positionY = pageMarginY + 20;
          currentPage++;
        };

        const renderContent = (child: HTMLElement) => {
          return new Promise((resolve) => {
            html2canvas(child, {
              allowTaint: true,
              useCORS: true,
            }).then((canvas) => {
              const imgData = canvas.toDataURL("image/jpeg", 1.0);
              const imgHeight = (canvas.height * docWidth) / canvas.width;

              if (positionY + imgHeight > pageHeight - 10) {
                addNewPage();
              }

              doc.addImage(imgData, "JPEG", positionX, positionY, docWidth, imgHeight);
              positionY += imgHeight + 15;

              resolve([]);
            });
          });
        };

        const children = Array.from(listRef.current.children);

        for (let i = 0; i < children.length; i++) {
          const child = children[i];

          await renderContent(child as HTMLElement);
        }
        writeFooter();

        if (upload) {
          const blob = doc.output("blob");
          if (blob) {
            const file = new File([blob], "document.pdf", {
              type: "application/pdf",
            });
            await createAModelDocument({
              model: `unit-${variant}`,
              id: unitId,
              file,
              fileName: `unit-${variant}-${status}-${formatTimestampToDate(Number(new Date()))}.pdf`,
              name: `unit-${
                variant === "testingstep"
                  ? `Test Report`
                  : variant === "manufacturingstep"
                  ? `Manufacturing Report`
                  : variant === "startupstep"
                  ? `Startup Report`
                  : variant === "qualitycontrolstep"
                  ? `Quality Control Report`
                  : ""
              }-${status}-${formatTimestampToDate(Number(new Date()))}.pdf`,
              description: `unit-${variant}-document, ${formatTimestampToDate(Number(new Date()))}`,
            });
            onClose();
            Toast("Document Saved Successfully", "success");
          }
        } else {
          doc.save();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  return (
    <MyDialog title="Procedure Result" open={open} onClose={() => generatePdf(true)} fullScreen disabled={uploading}>
      {uploading && <LinearProgress />}
      <Box width="21cm" m="auto">
        <Button variant="contained" onClick={() => generatePdf(false)}>
          Download
        </Button>
      </Box>
      <div className="print-only" style={{ width: "21cm", height: "29.7cm", margin: "0 auto" }}>
        <ProcedureHeader status={status} variant={variant} ref={headerRef} />
        <div style={{ margin: "40px 0" }} />
        <div style={{ height: 320 }}>
          <ProcedureInfo ref={infoRef} serial={serial} model={model} modelName={name} description={description} />
        </div>
        <div style={{ margin: "50px 0" }} />
        <ProcedureSteps ref={listRef} steps={steps} />
        <Box>
          <img alt="footer" src={Footer} width="100%" />
        </Box>
      </div>
    </MyDialog>
  );
}
