import React, { useCallback, useMemo, useState } from "react";
import { Box, useMediaQuery, Tooltip } from "@mui/material";
import queryString from "query-string";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import "@inovua/reactdatagrid-community/index.css";
import NumberFilter from "@inovua/reactdatagrid-community/NumberFilter";
import DateFilter from "@inovua/reactdatagrid-community/DateFilter";
import { TypeComputedProps, TypeOnSelectionChangeArg } from "@inovua/reactdatagrid-community/types/TypeDataGridProps";
import { CellProps, TypeEditInfo, TypeFilterValue, TypeRowProps } from "@inovua/reactdatagrid-community/types";
import { get } from "../api";
import { formatTimestampToDate } from "../logic/date";
import { ParameterType, capitalizeFirstLetter, formatCurrency } from "../logic/utils";
import MoreButton from "./DataGrid/columnList";
import BooleanFilter from "components/Datagrid/BooleanFilter";
import { ReactComponent as Checked } from "assets/icons/tableIcons/checked.svg";
import { ReactComponent as UnChecked } from "assets/icons/tableIcons/unchecked.svg";

export const getOperator = (op: string) => {
  switch (op) {
    case "contains":
      return "contain";
    case "startsWith":
      return "startsWith";
    case "endsWith":
      return "endsWith";
    case "lt":
      return "max";
    case "gt":
      return "min";
    case "before":
      return "max";
    case "after":
      return "min";
    default:
      return "";
  }
};

const gridStyle = {
  minHeight: "calc(100vh - 200px)",
  marginBottom: "4px",
  // "& .InovuaReactDataGrid__portal-host": {
  //   position: "absolute",
  //   top: 0,
  //   /* left: 0; */
  //   right: 0,
  // },
};
const mobileGridStyle = { minHeight: "calc(100vh - 250px)" };
const green = {
  color: "#12AE25",
  width: "100%",
  display: "flex",
  justifyContent: "center ",
  alignItems: "center",
};
const red = { color: "#F53636", width: "100%", display: "flex", justifyContent: "center ", alignItems: "center" };
const filterTypes = {
  boolean: {
    type: "boolean",
    emptyValue: "",
    operators: [
      {
        name: "neq",
        fn: ({ value, filterValue }: any) => value !== filterValue,
      },
      {
        name: "eq",
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  
  },
  string: {
    type: "string",
    emptyValue: "",
    operators: [
      {
        name: "startsWith",
        fn: ({ value, filterValue }: any) => {
          return !filterValue ? true : Boolean(value.startsWith(filterValue));
        },
      },
      {
        name: "contains",
        fn: ({ value, filterValue }: any) => {
          return !filterValue ? true : value.indexOf(filterValue) !== -1;
        },
      },
      {
        name: "eq",
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
    ],
  },
  number: {
    type: "number",
    emptyValue: null,
    operators: [
      {
        name: "eq",
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: "lt",
        fn: ({ value, filterValue }: any) => value < filterValue,
      },
      {
        name: "gt",
        fn: ({ value, filterValue }: any) => value > filterValue,
      },
    ],
  },
  date: {
    type: "date",
    emptyValue: "",
    operators: [
      {
        name: "eq",
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: "inrange",
        fn: ({ minFilterValue, value, maxFilterValue }: any) => value > minFilterValue && value < maxFilterValue,
      },
      {
        name: "before",
        fn: ({ value, filterValue }: any) => value < filterValue,
      },
      {
        name: "after",
        fn: ({ value, filterValue }: any) => value > filterValue,
      },
    ],
  },
  currency: {
    type: "currency",
    emptyValue: "$",
    operators: [
      {
        name: "eq",
        fn: ({ value, filterValue }: any) => value === filterValue,
      },
      {
        name: "before",
        fn: ({ value, filterValue }: any) => value < filterValue,
      },
      {
        name: "after",
        fn: ({ value, filterValue }: any) => value > filterValue,
      },
    ],
  },
};

function NewDataGrid({
  columns,
  style,
  url,
  initParams,
  refresh,
  checkboxColumn,
  onRowSelected,
  onSelectionChange,
  onDataFetched,
  onEditComplete,
  editable,
  rowHeight,
  setUrlFilters,
  selected,
  notFilterable,
  sort,
  rowClassName,
  pageSize,
  rows,
  filterValue,
  loading,
  onFilterMade,
  overrideFilterParams,
  renderRowContextMenu,
  initialState,
  bgColor,
  onReady,
  pagination,
  checkboxOnlyRowSelect,
  getItemsList,
  cellSelection,
  onCellSelectionChange,
  csvQuery,
  setTotal,
  valueFilter,
  defaultSort,
}: {
  checkboxOnlyRowSelect?: boolean;
  loading?: boolean;
  sort?: (
    d: any[],
    sortInfo: {
      dir: 1 | -1;
      id: string;
      name: string;
      columnName: string;
    }
  ) => any[];
  onRowSelected: (row: any) => void;
  columns: any[];
  style?: any;
  url: string;
  initParams?: ParameterType;
  refresh?: number | boolean;
  checkboxColumn?: boolean;
  editable?: boolean;
  rowHeight?: number;
  onSelectionChange?: (config: TypeOnSelectionChangeArg) => void;
  onDataFetched?: (data: any) => void;
  onEditComplete?: (data: TypeEditInfo) => void;
  setUrlFilters?: boolean;
  selected?: any;
  notFilterable?: boolean;
  rowClassName?: string | (({ className, data, props }: { className: string; data: any; props: any }) => string);
  pageSize?: number;
  rows?: any[];
  filterValue?: TypeFilterValue;
  onFilterMade?: (params: any, filterValue: TypeFilterValue) => void;
  overrideFilterParams?: (params: any, filterValue: TypeFilterValue) => any;
  renderRowContextMenu?: (
    menuProps: any,
    details: {
      rowProps: TypeRowProps;
      cellProps?: CellProps;
      grid: any;
      computedProps: TypeComputedProps;
    }
  ) => any;
  initialState?: any;
  bgColor?: string;
  pagination?: boolean;
  onReady?: (computedPropsRef: React.MutableRefObject<TypeComputedProps | null>) => void;
  getItemsList?: (response: any) => any[];
  cellSelection?: any;
  onCellSelectionChange?: any;
  csvQuery?: boolean;
  setTotal?: (p?: any) => void;
  valueFilter?: boolean;
  defaultSort?: {
    sort: string;
    order: "ASC" | "DESC";
  };
}) {
  const phone = useMediaQuery("(max-width:900px)");
  const [columnsState, setColumnsState] = useState<any[]>(columns.map((c) => ({ ...c, visible: true })));
  const [columnData, setColumnData] = useState<any>(
    columnsState
      .map((column) => column?.header)
      .filter(Boolean)
      .join(",")
  );
  const [gridRef, setGridRef] = useState<React.MutableRefObject<TypeComputedProps | null> | null>(null);

  const cols = useMemo(() => {
    let res: any[] = columnsState;
    if (res) {
      res = res.map((r) => {
        if (r.type === "boolean") {
          r = {
            ...r,
            minWidth: 50,
            filterEditor: (p: any) => (
              <div style={{ height: 50 }}>
                <BooleanFilter {...p} />
              </div>
            ),
            render: ({ data }: any) =>
              data[r.name] ? (
                <div style={green}>
                  <Checked />
                </div>
              ) : (
                <div style={red}>
                  <UnChecked />
                </div>
              ),
          };
        }
        if (r.type === "number") {
          r = {
            ...r,
            filterEditor: NumberFilter,
            render: ({ value = "" }: { value: any }) => (
              <Tooltip title={value ? value : value === 0 ? "0" : ""}>
                <div>{value ? value : value === 0 ? "0" : ""}</div>
              </Tooltip>
            ),
          };
        }
        if (r.type === "date") {
          r = {
            ...r,
            dateFormat: "MM-DD-YYYY",
            sort: () => {},
            filterEditor: (p: any) => {
              const value = p.filterValue?.value;

              let hint = typeof value === "string" ? value : "";
              if (value?.start && value?.end) {
                hint = `${value?.start} - ${value?.end}`;
              }
              if (value?.start && !value?.end) {
                hint = `${value?.start}`;
              }
              if (!value?.start && value?.end) {
                hint = `to ${value?.end}`;
              }

              if (!value || !hint) {
                return <DateFilter {...p} />;
              }

              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    "& .InovuaReactDataGrid__column-header__filter-wrapper": {
                      minHeight: "30px !important",
                      height: "35px !important",
                    },
                  }}
                >
                  <DateFilter {...p} />
                  <span style={{ margin: "0 4px", fontWeight: "bold" }}>{hint}</span>
                </Box>
              );
            },
            render:
              r.render ||
              (({ value, cellProps: { dateFormat } }: { value: any; cellProps: any }) => (
                <Tooltip title={value && formatTimestampToDate(value)}>
                  <div>{value && formatTimestampToDate(value)}</div>
                </Tooltip>
              )),
          };
        }
        if ((r.type === "string" || !r.type) && !r.render) {
          r = {
            ...r,
            render: ({ value = "" }: { value: any }) => (
              <Tooltip title={value || ""}>
                <div>{value || ""}</div>
              </Tooltip>
            ),
          };
        }
        if (r.type === "currency") {
          r = {
            sort: () => {},
            render: ({ value }: any) => {
              return formatCurrency(value);
            },
            ...r,
          };
        }
        return { ...r, editable: r.editable || false };
      });
    }

    if (editable) {
      return res.map((c: any) => ({ ...c, header: capitalizeFirstLetter((c.header || "")?.trim()) }));
    }

    return res.map((c: any) => ({ ...c, header: capitalizeFirstLetter((c.header || "")?.trim()) }));
  }, [columnsState, editable]);

  const defaultFilterValue = useMemo(() => {
    const parsedQuery = queryString.parse(window.location.search);
    let queryKeys = Object.keys(parsedQuery).filter(
      (i) => !i.toLocaleLowerCase().includes("date") && !i.toLocaleLowerCase().includes("createdAt")
    );
    const filters: any = {};

    for (let i = 0; i < queryKeys.length; i++) {
      let key = queryKeys[i];
      let whereKey = key;
      let operator = "eq";
      if (key.includes("contain")) {
        operator = "contains";
        whereKey = key.substring(7);
      } else if (key.includes("startsWith")) {
        operator = "startsWith";
        whereKey = key.substring(10);
      } else if (key.includes("max")) {
        operator = "lt";
        whereKey = key.substring(3);
      } else if (key.includes("min")) {
        operator = "gt";
        whereKey = key.substring(3);
      }
      filters[whereKey] = operator;
    }

    let res = columns
      .filter((c) => !c.name?.includes("action"))
      .map(({ name, type, defaultOperator, filterValue }) => {
        if (setUrlFilters) {
          return {
            name,
            operator: defaultOperator || (type === "date" ? "inrange" : "contains"),
            type: type || "string",
            value:
              filterValue !== null && filterValue !== undefined && filterValue !== ""
                ? filterValue
                : filters[name]
                ? parsedQuery[getOperator(filters[name]) + name]
                : type === "date"
                ? ""
                : "",
          };
        }
        return {
          name,
          operator: defaultOperator || (type === "date" ? "inrange" : "contains"),
          type: type || "string",
          value: valueFilter ? filterValue : "",
        };
      });

    return res;
  }, [columns, setUrlFilters, valueFilter]);

  const fetchData = useCallback(
    async ({
      filterValue,
      limit,
      sortInfo,
      skip,
    }): Promise<{
      data: any[];
      count: number;
    }> => {
      if (rows) {
        if (sort) {
          return { data: sort(rows, sortInfo), count: rows.length };
        }
        return { data: rows, count: rows.length };
      }

      let params: any = JSON.parse(JSON.stringify(initParams || {}));
      if (params["date"]) {
        delete params["date"];
      }

      console.log({ filterValue });

      for (const fv of filterValue) {
        if (fv.value !== null && fv.value !== undefined && fv.value !== "" && fv.value !== "[object Object]") {
          if (typeof fv.value == "object" && !Boolean(fv.value?.start) && !Boolean(fv.value?.end)) {
            continue;
          }
          if (fv.operator === "inrange" && typeof fv.value === "string") {
            continue;
          }
          if (fv.operator === "inrange" && fv.value?.start && fv.value?.end) {
            params[getOperator("before") + fv.name] = Number(new Date(fv.value?.end));
            params[getOperator("after") + fv.name] = Number(new Date(fv.value?.start));
            //
          } else if (fv.operator === "inrange" && !fv.value?.start && fv.value?.end) {
            params[getOperator("before") + fv.name] = Number(new Date(fv.value?.end));
            //
          } else if (fv.operator === "inrange" && fv.value?.start && !fv.value?.end) {
            params[getOperator("after") + fv.name] = Number(new Date(fv.value?.start));
            //
          } else if (fv.operator === "after" && fv.type === "date") {
            params[getOperator("after") + fv.name] = Number(new Date(fv.value));
            //
          } else if (fv.operator === "before" && fv.type === "date") {
            params[getOperator("before") + fv.name] = Number(new Date(fv.value));
            //
          } else if (fv.operator === "eq" && fv.type === "date") {
            params[getOperator("eq") + fv.name] = Number(new Date(fv.value));
            //
          } else {
            params[getOperator(fv.operator) + fv.name] = fv.value;
          }
        }
      }

      const tablePageSize = pageSize || limit || 50;
      params.pageSize = tablePageSize;
      if (csvQuery) {
        params.list = columnData;
      }

      if (skip) {
        params.page = skip / tablePageSize + 1;
      }
      if (sortInfo) {
        params.sort = sortInfo.name;
        params.order = sortInfo.dir === 1 ? "ASC" : "DESC";
      } else {
        params.sort = defaultSort?.sort || "date";
        params.order = defaultSort?.order || "DESC";
      }

      try {
        if (window.history.pushState && setUrlFilters) {
          const query = queryString.stringify({
            ...params,
            list: undefined,
            // mindate: undefined,
            // maxdate: undefined,
            // date: undefined,
            // createdAt: undefined,
            // mincreatedAt: undefined,
            // maxcreatedAt: undefined,
          });

          var newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + query;

          window.history.pushState({ path: newurl }, "", newurl);
        }
        onFilterMade && onFilterMade(params, filterValue);
        if (overrideFilterParams) {
          params = overrideFilterParams(params, filterValue);
        }

        const d = await get(url, { params });
        onDataFetched && onDataFetched(d);
        if (!d?.result && !d.length) {
          return { data: [], count: 0 };
        }

        const data = d.length !== undefined && d.length !== null ? d : getItemsList ? getItemsList(d) : d.result;
        const count = d?.length ?? (d.total || 0);
        setTotal && setTotal(d.total);

        if (sort) {
          return { data: sort(data, sortInfo), count };
        }

        return { data, count };
      } catch (e) {
        console.log(e);
        onDataFetched && onDataFetched([]);
        return { data: [], count: 0 };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [...Object.entries(initParams || {}).map((i) => i[1]), url, refresh, columnData]
  );

  return (
    <Box
      width="100%"
      flex={1}
      position="relative"
      sx={{
        "& .InovuaReactDataGrid__column-header": {
          background: !bgColor ? "#202831" : bgColor,
          color: "#fff",
        },
        "& .InovuaReactDataGrid__cell": {
          userSelect: "all",
        },
        "& .inovua-react-scroll-container__wrapper": {
          marginBottom: "10px",
        },
      }}
    >
      <MoreButton
        columns={columns}
        setColumnData={setColumnData}
        setColumnsState={setColumnsState}
        columnsState={columnsState}
      />
      <ReactDataGrid
        filterRowHeight={50}
        checkboxOnlyRowSelect={checkboxOnlyRowSelect}
        onReady={(p) => {
          setGridRef(p);
          if (onReady) {
            onReady(p);
          }
        }}
        loading={loading}
        renderRowContextMenu={renderRowContextMenu}
        checkboxColumn={checkboxColumn}
        cellSelection={cellSelection}
        onCellSelectionChange={onCellSelectionChange}
        onSelectionChange={onSelectionChange}
        idProperty="id"
        rowHeight={rowHeight || 20}
        columns={cols}
        dataSource={fetchData}
        filterValue={filterValue}
        style={style ? style : phone ? mobileGridStyle : gridStyle}
        defaultFilterValue={defaultFilterValue}
        onRowClick={({ data }) => onRowSelected(data)}
        rowClassName={rowClassName}
        showColumnMenuTool={false}
        pagination={pagination ? false : true}
        defaultLimit={pageSize || 50}
        filterTypes={filterTypes}
        onEditComplete={onEditComplete}
        editStartEvent={editable ? "click" : undefined}
        editable={editable}
        selected={selected}
        enableFiltering={notFilterable ? false : true}
        initialState={initialState}
      />
    </Box>
  );
}

export default NewDataGrid;
