import React from "react";
import { Box, CircularProgress, MenuItem } from "@mui/material";
import { useFormik } from "formik";
import useSWR, { mutate } from "swr";
import { IConstant } from "api/constant";
import { addQuestion } from "api/question";
import MyDialog from "app/Dialog";
import Button from "app/Button";
import TextField from "app/TextField";
import AsyncCombo from "common/AsyncCombo";

const initialValues = { SOId: null, UnitId: null, ItemId: null, department: "", AssignedToId: null, subject: "" } as {
  SOId: string | null;
  UnitId: string | null;
  ItemId: string | null;
  department: string;
  AssignedToId: string | null;
  subject: string;
};

export default function QuestionModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const { data: departments } = useSWR<{ result: IConstant[]; total: number }>("/constant?key=department");

  const { values, isSubmitting, getFieldProps, setFieldValue, handleSubmit } = useFormik({
    initialValues,
    onSubmit: async (data, { setSubmitting, resetForm }) => {
      try {
        setSubmitting(true);
        await addQuestion({
          SOId: data.SOId || undefined,
          UnitId: data.UnitId || undefined,
          ItemId: data.ItemId || undefined,
          department: data.department || undefined,
          AssignedToId: data.AssignedToId || undefined,
          subject: data.subject || undefined,
        });

        mutate("/question");
        resetForm({
          values: initialValues,
        });
        onClose();
      } catch (error) {
        console.log(error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <MyDialog open={open} onClose={onClose} title={"Question"} maxWidth="md" fullWidth>
      <form onSubmit={handleSubmit}>
        <Box display="grid" gridTemplateColumns="1fr 1fr" gap={2} p={2}>
          <AsyncCombo
            filterBy="number"
            getOptionLabel={(o) => o?.number}
            getOptionSelected={(o, v) => o?.id === v?.id}
            url={`/so`}
            label="SO No"
            onChange={(e, nv) => setFieldValue("SOId", nv?.id)}
            style={{ width: "100%" }}
            value={values?.SOId}
          />
          <AsyncCombo
            filterBy="serial"
            getOptionLabel={(o) => o?.serial}
            getOptionSelected={(o, v) => o?.id === v?.id}
            url={`/unit?SOId=${values.SOId}`}
            label="Unit No"
            onChange={(e, nv) => setFieldValue("UnitId", nv?.id)}
            style={{ width: "100%" }}
            value={values?.UnitId}
            disabled={!values.SOId}
          />
          <AsyncCombo
            filterBy="itemNo"
            getOptionLabel={(o) => o?.itemNo || "No-Name"}
            getOptionSelected={(o, v) => o?.id === v?.id}
            url={`/lineItem?SOId=${values.SOId}`}
            label="Item No"
            onChange={(e, nv) => setFieldValue("ItemId", nv?.id)}
            style={{ width: "100%" }}
            value={values?.ItemId}
            disabled={!values.SOId}
          />
          <TextField label="Department" select {...getFieldProps("department")}>
            {departments?.result?.[0]?.values.map((d) => (
              <MenuItem key={d} value={d}>
                {d}
              </MenuItem>
            ))}
          </TextField>
          <AsyncCombo
            filterBy="username"
            getOptionLabel={(o) => o?.username || "No-Name"}
            getOptionSelected={(o, v) => o?.id === v?.id}
            url="/employee"
            defaultParams={{ department: values.department }}
            label="Assignee To"
            onChange={(e, nv) => setFieldValue("AssignedToId", nv?.id)}
            style={{ gridColumn: "span 2" }}
            value={values?.AssignedToId}
            disabled={!values.department}
          />
          <TextField
            label="Subject"
            {...getFieldProps("subject")}
            style={{ gridColumn: "span 2" }}
            InputLabelProps={{ shrink: true }}
            multiline
            rows={3}
          />
        </Box>
        <Box display="flex" justifyContent="center" my={1}>
          {isSubmitting ? (
            <CircularProgress />
          ) : (
            <Button
              variant="contained"
              style={{ width: 300, marginTop: 1, marginBottom: 1, background: "#E68031" }}
              type="submit"
            >
              Submit
            </Button>
          )}
        </Box>
      </form>
    </MyDialog>
  );
}
