// import HeaderPic from '../../../assets/svg/1.svg?react'
import { ReactComponent as HeaderPic } from '../../../../assets/svg/1.svg'

export default function QuoteHeaderPage1() {
  return ( 
    <div className="w-full">
      <HeaderPic className="w-full h-auto" /> 
    </div>
   );
}
