import Footer from "./footer/Footer";
import Header from "./header/Header";
export default function POpage2({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={2} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content({ data }) {
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div>
          <table className="min-w-full border-collapse" style={{ border: "0", cellspacing: "1", cellpadding: "1" }}>
            <tbody>
              <tr className="border border-table">
                <td className="p-[1%] w-1/2 align-top border-seperate">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Bill To:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.billingCompany}</span>
                  </div>
                </td>
                <td className="border-x border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service At:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                    <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
                  </div>
                </td>
              </tr>
              <tr className="border border-y-0 border-table">
                <td className="p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">City State:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.billingState}</span>
                  </div>
                </td>
                <td className="border-x border-y-0 border-table p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">City State:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
              </tr>
              <tr className="border border-table">
                <td className=" p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Zip Code:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.billingZipcode}</span>
                  </div>
                </td>
                <td className="border-x border-y-0 border-table p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Zip Code:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.shippingZipcode}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full border-spacing-0 p-0 align-top" cellSpacing={0}>
            <tbody className="border-spacing-0 p-0 align-top">
              <tr className="border-collapse">
                <td className=" p-[1%] w-1/2 border border-table border-collapse">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Billing Contact:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.billingAttn}</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Contact:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.shippingAttn}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table border-collapse p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Billing Contact Title:</span>
                    <span className="text-[14px] font-light ml-[1%]">CONTROLLER</span>
                  </div>
                </td>
                <td className="border border-table border-collapse p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Phone:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.shippingPhone}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table border-collapse p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Billing Phone:</span>
                    <span className="text-[14px] font-light ml-[1%]">{data?.billingPhone}</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                      Product Type (I.e. UPS Regulator):
                    </span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Customer P/O No.:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Model No.:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Customer Acc. No.:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Serial No.:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Company:</span>
                    <span className="text-[14px] font-light ml-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table border-spacing-0 p-[1%] align-top">
                  <div className="flex flex-col">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Code:</span>
                      <span className="text-[14px] font-light ml-[1%]">-</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full">
            <tbody className="">
              <tr>
                <td className="border border-table p-[1%] align-top">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                      Customer Description of Problem/Request:
                    </span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full">
            <tbody className="">
              <tr>
                <td className="border border-table  p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                      Description of Work Performed:
                    </span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                    <span className="text-[14px] font-light">-</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
