import styled from "@emotion/styled";
import { ListResponse } from "api";
import { shiplineType, shippingType } from "api/shipping";
import { ISO } from "api/so";
import FetchValue from "components/Datagrid/FetchValue";
import { formatTimestampToDate } from "logic/date";
import { formatCurrency, getId } from "logic/utils";
import React, { forwardRef } from "react";
import useSWR from "swr";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  .container {
    margin: 0 auto;
    background-color: white;
    padding: 20px;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 20px;
  }
  .logo {
    width: 60px;
    height: 60px;
    background-color: #ddd;
    margin-right: 10px;
  }
  .company-info {
    font-size: 0.9em;
  }
  .title {
    font-size: 1.5em;
    color: #666;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }
  .box {
    border: 1px solid #ccc;
    padding: 10px;
  }
  .box-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  .label {
    width: 200px;
    font-weight: bold;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
  }
  th {
    background-color: #f0f0f0;
  }
  .footer {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    gap: 10px;
    margin-top: 20px;
  }
  .footer-box {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: center;
  }
  .footer-title {
    font-weight: bold;
    margin-bottom: 5px;
  }
  @media (max-width: 600px) {
    .grid,
    .footer {
      grid-template-columns: 1fr;
    }
  }
`;

function Shipping({ shippingId }: { shippingId: string }, ref: any) {
  const { data: shipping } = useSWR<shippingType>(`/shipping/${shippingId}`);
  const { data: so } = useSWR<ISO>(shipping ? `/so/${getId(shipping.SOId)}` : null);
  const { data: lineitems } = useSWR<ListResponse<shiplineType>>(`/shiplineitem?ShippingId=${shippingId}`);

  return (
    <Wrapper ref={ref}>
      <div className="container">
        <div className="header">
          <div style={{ display: "flex" }}>
            <div className="logo"></div>
            <div className="company-info">
              <div>
                <strong>DSPM INC.</strong>
              </div>
              <div>DIGITAL SIGNAL POWER MANUFACTURER</div>
              <div>www.DSPManufacturing.com</div>
              <div>1.877.DSPM.POWER</div>
            </div>
          </div>
          <div className="title">Packing Slip</div>
        </div>

        <div className="grid">
          <div className="box">
            <div className="box-title">Client Info:</div>
            <div>
              <span className="label">Client:</span> {so?.client}
            </div>
            <div>
              <span className="label">Contact:</span> {so?.clientContact}
            </div>
            <div>
              <span className="label">Email:</span> {so?.clientContactEmail}
            </div>
            <div>
              <span className="label">Phone:</span> {so?.clientContactPhone}{" "}
              <span style={{ marginLeft: 20 }}>Ext:</span>
            </div>
            <div>
              <span className="label">Status:</span> {so?.status} <span style={{ marginLeft: 20 }}>Limit:</span>
            </div>
            <div>
              <span className="label">Freight Terms:</span> {so?.freightTerms}
            </div>
            <div>
              <span className="label">Payment Terms:</span> {so?.paymentTerms}
            </div>
          </div>
          <div className="box" style={{ display: "grid", gridTemplateColumns: "1fr 1fr", gap: 8 }}>
            <div className="box-title" style={{ gridColumn: "span 2" }}>
              Sales Order Info:
            </div>
            <div>
              <span className="label">SO No:</span> {so?.number}
            </div>
            <div>
              <span className="label">Shipment No:</span>
              {shipping?.number}
            </div>
            <div>
              <span className="label">Inv. Status:</span>
            </div>
            <div>
              <span className="label">Item Fees:</span>
            </div>
            <div>
              <span className="label">Target Ship Date:</span>{" "}
              {so?.estimatedShipDate ? formatTimestampToDate(so?.estimatedShipDate) : ""}
            </div>
            <div>
              <span className="label">Shipping Fees:</span> {formatCurrency(so?.freightCost || 0)}
            </div>
            <div>
              <span className="label">Actual Ship Date:</span>{" "}
              {so?.actualShipDate ? formatTimestampToDate(so?.actualShipDate) : ""}
            </div>
            <div>
              <span className="label">Handling Fees:</span> {formatCurrency(so?.shippingAndHandling || 0)}
            </div>
            <div>
              <span className="label">PM/Rep:</span> {so?.rep}
            </div>
            <div>
              <span className="label">Total Shipping:</span>{" "}
              {formatCurrency(so?.freightCost || 0 + (so?.shippingAndHandling || 0))}
            </div>
            <div>
              <span className="label">Customer PO #</span> {so?.customerPONumber}
            </div>
          </div>
          <div className="box">
            <div className="box-title">Carrier:</div>
            <div>
              <span className="label">Company:</span> {shipping?.carrier}
            </div>
            <div>
              <span className="label">Method:</span> {shipping?.freightMethod}
            </div>
            <div>
              <span className="label">Tracking No:</span> {shipping?.trackingNo}
            </div>
            <div>
              <span className="label">Client Acct No:</span> {shipping?.clientAcct}
            </div>
          </div>
          <div className="box">
            <div className="box-title">Ship To:</div>
            <div>
              <span className="label">Company:</span> {so?.shippingCompany}
            </div>
            <div>
              <span className="label">Address:</span> {so?.shippingAddress}
            </div>
            <div>
              <span className="label">City/St/Zip:</span> {so?.shippingCity}, {so?.shippingState}, {so?.shippingZip}
            </div>
            <div>
              <span className="label">Country:</span> {so?.shippingCountry}
            </div>
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <th>SO No.</th>
              <th>Item No.</th>
              <th>Item Name</th>
              <th>Ordered</th>
              <th>Shipped</th>
            </tr>
          </thead>
          <tbody>
            {lineitems?.result.map((i) => (
              <tr key={getId(i)}>
                <td>
                  {i.SOId ? <FetchValue url={`/so/${getId(i.SOId)}`} getField={(o) => o?.number || ""} /> : so?.number}
                </td>
                <td>
                  {i.ItemId ? <FetchValue url={`/item/${getId(i.ItemId)}`} getField={(o) => o?.no || ""} /> : i.itemNo}
                </td>
                <td>
                  {i.ItemId ? (
                    <FetchValue url={`/item/${getId(i.ItemId)}`} getField={(o) => o?.name || ""} />
                  ) : (
                    i.itemName
                  )}
                </td>
                <td>{i.orderedQty}</td>
                <td>{i.shippedQty}</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="footer">
          <div className="footer-box">
            <div className="footer-title">Number of Items</div>
            <div style={{ fontSize: "1.5rem" }}>{lineitems?.total}</div>
          </div>
          <div className="footer-box">
            <div className="footer-title">Shipping Instructions</div>
            <div>{shipping?.specialInstruction}</div>
          </div>
          <div className="footer-box">
            <div className="footer-title">Shipping Weight</div>
            <div>
              lb
              <br />
              oz
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(Shipping);
