import { createAsyncThunk } from "@reduxjs/toolkit";
import { duplicateBom } from "api/bom";
import { pasteInstructions, pasteInstructionToSteps, pasteSteps } from "api/instruction";
import { RootState } from "store";

export const pasteThunk = createAsyncThunk(
  "clipboard/paste",
  async (
    { name, destination, metadata }: { name: string; destination: string; metadata?: any },
    { rejectWithValue, getState }
  ) => {
    try {
      const clipboard = (getState() as RootState).clipboard.values?.find((i) =>
        name === "steps-unit"
          ? ["steps-unit", "instruction-device", "instruction-cluster"].includes(name)
          : i.name === name
      );

      if (!clipboard) {
        return rejectWithValue("not found");
      }
      if (name === "bom") {
        await duplicateBom({
          originBom: clipboard.origin,
          destinationItem: destination,
          ...(metadata || {}),
        });
        return { name };
      }
      if (name === "instruction-cluster") {
        await pasteInstructions({
          sourceClusterId: clipboard.origin,
          destinationClusterId: destination,
          InstructionIds: clipboard.value || [],
          ...(metadata || {}),
        });
        return { name };
      }
      if (name === "instruction-device") {
        await pasteInstructions({
          sourceDeviceId: clipboard.origin,
          destinationDeviceId: destination,
          InstructionIds: clipboard.value || [],
          ...(metadata || {}),
        });
        return { name };
      }
      if (name === "steps-unit") {
        if (clipboard.name === "instruction-device") {
          await pasteInstructionToSteps({
            unitId: destination,
            InstructionIds: clipboard.value || undefined,
            ...(metadata || {}),
          });
        } else {
          await pasteSteps({
            destinationUnitId: destination,
            sourceUnitId: clipboard.origin,
            StepIds: clipboard.value || undefined,
            ...(metadata || {}),
          });
          return { name };
        }
      }
      return rejectWithValue("unknown name");
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
