
function SignatureComponent() {
  return ( 
    <table className="min-w-full border-collapse">
    <tbody>
      <tr className="h-full">
        <td className="border border-table p-[1%] w-1/2 align-top">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col basis-3/4">
              <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                Service Persons Signature:
              </span>
              <span className="text-[12px] font-bold ml-[1%]">. </span>
            </div>
            <div className="flex flex-col basis-1/4 ">
              <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                Date:
              </span>
              <span className="text-[12px] font-bold ml-[1%]">-</span>
              <span className="text-[12px] font-bold ml-[1%]"></span>

            </div>
          </div>
        </td>
        <td className="border border-table p-[1%] w-1/2 align-top">
          <div className="flex flex-row justify-between">
            <div className="flex flex-col basis-3/4">
              <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                Customers Signature:
              </span>
              <span className="text-[12px] font-bold ml-[1%]">-</span>

            </div>
            <div className="flex flex-col basis-1/4">
              <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                Date:
              </span>
              <span className="text-[12px] font-bold ml-[1%]">-</span>
              <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
              <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>


              
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
   );
}

export default SignatureComponent;