import jsPDF from "jspdf";
import * as pdfjsLib from "pdfjs-dist";

pdfjsLib.GlobalWorkerOptions.workerSrc = "/pdf.worker.mjs";

async function appendPdf({ pdfInstance, scale = 2.3, url }: { pdfInstance: jsPDF; url: string; scale?: number }) {
  try {
    const loadingTask = pdfjsLib.getDocument(url);
    const loadedPdf = await loadingTask.promise;

    for (let pageIndex = 1; pageIndex <= loadedPdf.numPages; pageIndex++) {
      const page = await loadedPdf.getPage(pageIndex);

      const viewport = page.getViewport({ scale });
      // Support HiDPI-screens.
      const outputScale = window.devicePixelRatio || 1;

      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");

      canvas.width = Math.floor(viewport.width * outputScale);
      canvas.height = Math.floor(viewport.height * outputScale);
      canvas.style.width = Math.floor(viewport.width) + "px";
      canvas.style.height = Math.floor(viewport.height) + "px";

      const transform = outputScale !== 1 ? [outputScale, 0, 0, outputScale, 0, 0] : null;

      if (!context || !canvas || !transform) {
        return;
      }

      const renderContext = {
        canvasContext: context,
        transform: transform,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      pdfInstance.addPage();
      pdfInstance.addImage(canvas, "JPEG", 0, 0, 210, 290);
    }
    return loadedPdf.numPages;
  } catch (error) {
    console.log(error);
  }
}

export default appendPdf;
