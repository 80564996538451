import React, { useRef, useState } from "react";
import { Button, Typography, SxProps, Theme } from "@mui/material";
import { UploadRounded } from "@mui/icons-material";
import useSWR from "swr";
import { host } from "host";

export default function UploadImage({
  sx,
  accept,
  multiple,
  disabled,
  onChange,
  id,
  variant,
}: {
  accept?: string;
  sx?: SxProps<Theme>;
  multiple?: boolean;
  disabled?: boolean;
  onChange?: (f: File) => void;
  id?: any;
  variant?: string;
}) {
  const fileUploader = useRef<HTMLInputElement | null>(null);
  const [preview, setPreview] = useState<string>();
  const { data } = useSWR<any>(id ? `/photo/${variant}/${id}` : null);

  return (
    <>
      <input
        id="custom-file-input"
        hidden
        type="file"
        ref={(e) => (fileUploader.current = e)}
        onChange={(e) => {
          if (e.target.files && e.target.files?.length > 0) {
            const file = e.target.files[0];
            onChange && onChange(file);
            setPreview(URL.createObjectURL(file));
          }
        }}
        multiple={multiple}
        accept={accept}
      />
      <Button
        variant="outlined"
        sx={{ width: 200, height: 150, borderStyle: "dashed", ...sx }}
        onClick={() => fileUploader.current?.click()}
        disabled={disabled}
      >
        {(data && data?.length > 0) || preview ? (
          <img
            src={preview ? preview : host + data[0]?.path}
            alt=""
            style={{
              width: "80%",
              height: "auto",
              objectFit: "cover",
            }}
          />
        ) : (
          <>
            <UploadRounded />
            <Typography>Click to upload</Typography>
          </>
        )}
      </Button>
    </>
  );
}
