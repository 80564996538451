import React from "react";
import { Box, Dialog, IconButton, Typography } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

export default function CheckedInModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <Box sx={{ p: 2 }}>
        <Box textAlign="right" mb={2}>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </Box>
        <Typography>You have successfully checked in.</Typography>
      </Box>
    </Dialog>
  );
}
