import React, { forwardRef } from "react";

import { POLineItem } from "api/po";
import { formatTimestampToDate } from "logic/date";
import { formatCurrency } from "logic/utils";

const columns = [
  { header: "Line", get: (r: POLineItem, idx: number) => idx + 1, color: "#e70202" },
  {
    header: "Item NO.",
    get: (r: any, idx: number) => r._itemNo || r.itemNo || r.ItemObject?.no || r.ItemId?.no,
    color: "#6a6a6a",
  },
  {
    header: "Name",
    get: (r: any, idx: number) => r?.ItemObject?.name || r?.ItemObject?.itemName || r?.ItemId?.name || "",
    color: "#6a6a6a",
  },
  { header: "Date Required", get: (r: any, idx: number) => formatTimestampToDate(r?.requiredBy), color: "#6a6a6a" },
  {
    header: "SKU",
    get: (r: any, idx: number) => r?.vendorSKU,
    color: "#6a6a6a",
  },
  {
    header: "Qty",
    get: (r: any, idx: number) => r?.qty,
    color: "#6a6a6a",
  },
  {
    header: "Cost",
    get: (r: any, idx: number) => formatCurrency(r?.cost || 0),
    color: "#6a6a6a",
  },
  {
    header: "U / M",
    get: (r: any, idx: number) => r?.ItemObject?.uom || r?.ItemId?.uom || "",
    color: "#6a6a6a",
  },
  {
    header: "Amount",
    get: (r: any, idx: number) => formatCurrency((r?.qty || 0) * (r?.cost || 0) || 0),
    color: "#6a6a6a",
  },
];

const LineItems = forwardRef<HTMLTableElement, { lines: any; blanket?: boolean }>(({ lines, blanket }, ref) => {
  if (blanket) {
    return (
      <div ref={ref}>
        {lines.map((po: any, i: number) => (
          <table
            key={i}
            style={{
              width: "100%",
              marginTop: "40px",
              marginBottom: "30px",
            }}
          >
            <thead>
              <tr
                style={{
                  width: "100%",
                  height: "40px",
                }}
              >
                {columns.map((c) => (
                  <th
                    key={c.header}
                    style={{
                      textAlign: "start",
                      paddingLeft: "10px",
                    }}
                  >
                    <div
                      style={{ borderBottom: "2px solid", borderColor: c.color, color: c.color, textAlign: "center" }}
                    >
                      {c.header}
                    </div>
                  </th>
                ))}
                <th></th>
              </tr>
            </thead>
            <tbody>
              {po?.map((l: any, index: number) => (
                <tr key={index} style={{ borderBottom: "2px solid #E1E1E1" }}>
                  {columns.map((c, idx) => (
                    <td
                      key={c.header}
                      style={{
                        paddingTop: "5px",
                        paddingBottom: "5px",
                        paddingLeft: "10px",
                        verticalAlign: "middle",
                        fontWeight: "bold",
                        textAlign: "start",
                      }}
                    >
                      {c.get(l, idx)}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        ))}
      </div>
    );
  }

  return (
    <table
      ref={ref}
      style={{
        width: "100%",
        marginTop: "40px",
        marginBottom: "30px",
      }}
    >
      <thead>
        <tr
          style={{
            width: "100%",
            height: "40px",
          }}
        >
          {columns.map((c) => (
            <th
              key={c.header}
              style={{
                textAlign: "start",
                paddingLeft: "10px",
              }}
            >
              <div style={{ borderBottom: "2px solid", borderColor: c.color, color: c.color, textAlign: "center" }}>
                {c.header}
              </div>
            </th>
          ))}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {lines?.map((l: any, index: number) => (
          <tr key={index} style={{ borderBottom: "2px solid #E1E1E1" }}>
            {columns.map((c, idx) => (
              <td
                key={c.header}
                style={{
                  paddingTop: "5px",
                  paddingBottom: "5px",
                  paddingLeft: "10px",
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {c.get(l, idx)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
});

export default LineItems;
