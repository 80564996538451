import React, { forwardRef } from "react";

const headers = [
  { title: "Line", flex: 1, color: "#e70202" },
  { title: "Name", flex: 3, color: "#6a6a6a" },
  { title: "QTY", flex: 1, color: "#6a6a6a" },
  { title: "U/M", flex: 1, color: "#6a6a6a" },
  { title: "Price", flex: 1, color: "#6a6a6a" },
  { title: "Amount", flex: 1, color: "#6a6a6a" },
];

const IssueTableHeader = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <div ref={ref} style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 8 }}>
      {headers.map((h) => (
        <div
          style={{
            padding: "4px 0",
            borderBottom: "2px solid",
            flex: h.flex,
            textAlign: "center",
            color: h.color,
            borderColor: h.color,
            fontWeight: "bold",
          }}
        >
          {h.title}
        </div>
      ))}
    </div>
  );
});

export default IssueTableHeader;
