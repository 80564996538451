import Footer from "./footer/Footer";
import Header from "./header/Header";
import SignatureComponent from "./SignatureComponent";
export default function POpage3({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full ">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={3} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content() {
  // ?DATA  SECTION ******************************************
  const titles = [
    "Service Time @   /   Hr.",
    "Travel Time @   /   Hr.",
    "Parts",
    "Tax",
    "Per Diem/Expenses",
    "Airfare",
    "Car Rental",
    "Miles",
    "Other",
  ];

  // Step 2: Create an array of chargeRows
  const chargeRows = titles?.map((title, index) => ({
    id: index + 1, // Generate IDs (item-1, item-2, ...)
    title: title, // Assign title from the array
    value: "__________", // Set value as specified
  }));
  // Sample data for 9 rows
  const rows = Array.from({ length: 7 }, (_, index) => {
    return {
      id: index + 1,
      qty: "-", // Random value for the first row, "-" for others
      partNumberOrDesc: "",
      priceEa: "",
      extPrice: "",
    };
  });

  // ?DATA  SECTION ******************************************

  return (
    <div className="flex flex-col items-center h-[88%] w-full">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
        <div>
          <table className="min-w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table p-[1%] w-1/2">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%} text-primaryOrange">Travel Time:</span>
                    <span className="text-[12px] ml-[1%}">-</span>
                    <span className="text-[12px] ml-[1%}">-</span>
                    <span className="text-[12px] ml-[1%}">-</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%} text-primaryOrange">Service Time</span>
                    <span className="text-[12px] ml-[1%}">-</span>
                  </div>
                </td>
              </tr>
              <tr className="bg-primaryBlue">
                <td className="border border-table p-[1%]">
                  <div className="flex flex-row">
                    <span className="text-[12px] font-bold text-white">Total Travel Time:</span>
                    <span className="text-[12px] text-white ms-[1%]"></span>
                  </div>
                </td>
                <td className="border border-table p-[1%]">
                  <div className="flex flex-row ">
                    <span className="text-[12px] font-bold text-white">Total Service Time:</span>
                    <span className="text-[12px] text-white ms-[1%]"></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-table text-start p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Qty</span>
                  </div>
                </th>

                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Part Number or Desc.</span>
                  </div>
                </th>
                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Price ea.</span>
                  </div>
                </th>
                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Ext Price</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className=" border-collapse">
              {rows?.map((row) => (
                <tr key={row.id}>
                  <td className="border border-table p-[1%] border-spacing-0 w-1/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.qty}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-7/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.partNumberOrDesc}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-2/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.priceEa}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-2/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.extPrice}</span>
                    </div>
                  </td>
                </tr>
              ))}

              <tr className="bg-primaryBlue">
                <td colSpan="2" className="border border-table p-[1%]" style={{ width: "50%" }}>
                  <div className="flex flex-row">
                    <span className="text-[12px] text-white font-bold">Total Parts From Page 3:</span>
                    <span className="text-[12px] text-white ms-[1%]">SUM</span>
                  </div>
                </td>
                <td colSpan="2" className="border border-table p-[1%]" style={{ width: "50%" }}>
                  <div className="flex flex-row">
                    <span className="text-[12px] text-white font-bold">Total Parts Cost:</span>
                    <span className="text-[12px] text-white ms-[1%]">SUM</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <tbody>
              {chargeRows?.map((row) => (
                <tr key={row.id} className={`${row.id % 2 == 0 ? "" : "bg-[#f6f6f6]"}`}>
                  <td className="border border-table border-r-0 p-[1%] border-spacing-0 w-1/3">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold text-primaryOrange">{row.title}</span>
                    </div>
                  </td>
                  <td className="border border-table border-l-0 p-[1%] border-spacing-0">
                    <div className="flex flex-col">
                      <span className="text-[12px] ">{row.value}</span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="bg-primaryBlue">
                <td colSpan="1" className="border border-table border-r-0 p-[1%] w-1/3">
                  <div className="flex flex-col">
                    <span className="text-[12px] text-white font-bold">Estimated Total Charges</span>
                  </div>
                </td>
                <td colSpan="1" className="border border-table border-l-0 p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] text-white font-bold">__________</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%] h-1/2">
          <SignatureComponent />
        </div>
      </div>
    </div>
  );
}
