import Footer from "./footer/Footer";
import Header from "./header/Header";
import SignatureComponent from "./SignatureComponent";
export default function POpage4({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={4} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content() {
  // ?DATA  SECTION ******************************************

  const partsRows = Array.from({ length: 12 }, (_, index) => {
    return {
      id: index + 1,
      qty: "-", // Random value for the first row, "-" for others
      partNumberOrDesc: "",
      priceEa: "",
      extPrice: "",
    };
  });

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
        <div>
          <table className="min-w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table p-[1%] w-1/2">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Model No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">&#8203;</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Serial No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">&#8203;</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table p-[1%] w-1/2">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%} text-primaryOrange">
                      Description of Work Performed (Continued):
                    </span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th className="border border-table text-start p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Qty</span>
                  </div>
                </th>

                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Part Number or Desc.</span>
                  </div>
                </th>
                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Price ea.</span>
                  </div>
                </th>
                <th className="border border-table text-start">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Ext Price</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className=" border-collapse">
              {partsRows?.map((row) => (
                <tr key={row.id}>
                  <td className="border border-table p-[1%] border-spacing-0 w-1/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.qty}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-7/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.partNumberOrDesc}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-2/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.priceEa}</span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] border-spacing-0 w-2/12">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-light">{row.extPrice}</span>
                    </div>
                  </td>
                </tr>
              ))}

              <tr className="bg-primaryBlue">
                <td colSpan="4" className="border border-table p-[1%]" style={{ width: "50%" }}>
                  <div className="flex flex-row">
                    <span className="text-[12px] text-white font-bold">Total Parts From this Page:</span>
                    <span className="text-[12px] text-white ms-[1%]">SUM</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <SignatureComponent />
        </div>
      </div>
    </div>
  );
}
