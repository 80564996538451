import { delete_, patch, post } from "api";

export interface IQuestion {
  id: string;
  date: number;
  number: string;
  department: string;
  SOId: string;
  soNumber: string;
  UnitId: string;
  unitSerialNumber: string;
  ItemId: string;
  subject: string;
  description: string;
  response: string;
  status: string;
  CreatedById: string;
  createdByName: string;
  AssignedToId: string;
  assignedToName: string;
}

export const addQuestion = (data: Partial<IQuestion>) => {
  return post(`/question`, data);
};

export const updateQuestion = (data: IQuestion, id: string) => {
  return patch(`/question/${id}`, data);
};

export const deleteQuestion = (id: string) => {
  return delete_(`/question/${id}`);
};
