import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import useSWR from "swr";
import jsPDF from "jspdf";
import ItemTable from "PDFTemplates/New/Quote/ItemTable";
import QRCode from "app/QRCode";
import MyDialog from "app/Dialog";
import AccountingHeader from "PDFTemplates/Accounting/header";
import AccountingLineItems from "PDFTemplates/Accounting/lineTable";

import { get } from "api";
import { getSoFinancialSummary, ISO } from "api/so";
import { ILineItem } from "api/lineItem";
import { createAModelDocument } from "api/document";

import { PDF } from "logic/pdf/PDF";
import { getId } from "logic/utils";
import { addElementToPdf, renderList } from "logic/pdf";
import { formatTimestampToDate } from "logic/date";

import numberBg from "assets/pdf/quote/noBg.png";
import TotalFooter from "PDFTemplates/TotalFooter";
import Toast from "app/Toast";
import { QR } from "logic/QR";
import SOPage1 from "PDFTemplates/New/SO/SOpage1";
import SOpagex from "PDFTemplates/New/SO/SOpagex";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

export default function SOPdfDialog({
  soId,
  open,
  onClose,
  onDone,
}: {
  soId: string;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data } = useSWR<ISO>(`/so/${soId}`);
  const { data: lines } = useSWR(`/lineitem?SOId=${soId}`);
  const createdSo = data;

  const [loading, setLoading] = useState(false);

  const headerRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLDivElement | null>(null);
  const qrRef = useRef<HTMLDivElement | null>(null);
  const totalRef = useRef<HTMLDivElement | null>(null);
  const SOpagesRef = useRef<HTMLDivElement | null>(null);
  const [totalPagesContent,setTotalPagesContent] = useState<ILineItem[][]>([]);
  const MAX_CONTENT_HEIGHT = 720; // Adjust this based on the A4 page content height
  const [heights, setHeights] = useState<number[]>([]);

  // const handleDownload = useCallback(
  //   async (upload: boolean) => {
  //     try {
  //       if (!createdSo) {
  //         return;
  //       }
  //       setLoading(true);
  //       if (headerRef.current && lineItemsRef.current && qrRef.current && totalRef.current) {
  //         const scale = 0.26;
  //         const doc = new jsPDF();
  //         const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
  //         const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  //         let page = 1;

  //         const qrCanvas = qrRef.current.getElementsByTagName("canvas")[0];

  //         const renderFooter = () => {
  //           doc.setFillColor(28, 37, 50);
  //           doc.rect(0, pageHeight - 25, pageWidth, 10, "F");

  //           doc.setFillColor(28, 117, 188);
  //           doc.circle(-5, pageHeight - 22, 15, "F");

  //           doc.setTextColor("#fff");
  //           doc.setFontSize(13);
  //           doc.text(String(page), 5, pageHeight - 20);
  //           doc.setFontSize(11);
  //           doc.text("1.877.377.6769", 10, pageHeight - 20);
  //           doc.text("Quote@dspmanufacturing.com", 80, pageHeight - 20);

  //           doc.addImage(numberBg, "png", pageWidth - 60, pageHeight - 27, 60, 15);
  //           doc.setTextColor("#000");
  //           doc.text("SO NO:", pageWidth - 55, pageHeight - 20);
  //           doc.text(String(createdSo?.number || ""), pageWidth - 30, pageHeight - 20);
  //           page += 1;
  //         };

  //         await doc.html(headerRef.current, {
  //           x: 0,
  //           y: 0,
  //           html2canvas: { scale, letterRendering: true, useCORS: true, taintTest: true },
  //         });

  //         doc.addImage(qrCanvas, "image/png", 100, 5, 20, 20);

  //         const y = await renderList({
  //           pdf: doc,
  //           list: lineItemsRef.current.children,
  //           x: 10,
  //           y: 160,
  //           marginBottom: 40,
  //           marginBetweenItems: 0,
  //           beforeAddNewPage() {
  //             renderFooter();
  //           },
  //         });

  //         if (y > 220) {
  //           doc.addPage();
  //           renderFooter();
  //         }

  //         await addElementToPdf({
  //           pdf: doc,
  //           element: totalRef.current,
  //           x: 5,
  //           y: pageHeight - 80,
  //           width: pageWidth - 10,
  //         });

  //         doc.save(
  //           createdSo
  //             ? `${createdSo?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`
  //             : `SalesOrder-${formatTimestampToDate(Number(new Date()))}.pdf`
  //         );

  //         if (upload) {
  //           const blob = doc.output("blob");
  //           await createAModelDocument({
  //             id: getId(createdSo),
  //             model: "so",
  //             file: blob,
  //             description: `${createdSo?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`,
  //             fileName: `${createdSo?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`,
  //             name: `${createdSo?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`,
  //           });
  //           await Promise.all([
  //             generateCustomerAckPdf("Customer", createdSo),
  //             generateCustomerAckPdf("Representative", createdSo),
  //           ]);
  //         }

  //         onDone && onDone();
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   },
  //   [createdSo, onDone]
  // );
  const handleDownload = useCallback(
    async (download: boolean) => {
      setLoading(true);
      try {
        if (!data || !lines || !SOpagesRef.current) {
          return;
        }
        const pdf = new jsPDF("p", "pt", "a4");
        const pages = SOpagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i] as HTMLElement;
          const canvas = await html2canvas(page, { scale: 2 }); // Higher scale for better quality
          const imgData = canvas.toDataURL("image/jpeg", 0.8); // Using JPEG format with quality set to 0.8

          const imgWidth = 595.28; // A4 width in points
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (i > 0) pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "MEDIUM");
        }

        if (download) {
          pdf.save(`SO_${data.number}.pdf`);
        }

        const generatedPdf = pdf.output("blob");
        await createAModelDocument({
          model: "SO",
          id: getId(data),
          file: generatedPdf,
          description: `${new Date().toJSON().slice(0, 19)} - ${data.number}`,
          name: `SO_${data.number}.pdf`,
          fileName: `SO_${data.number}.pdf`,
        });
        // setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [data, lines]
  );
  const generateCustomerAckPdf = async (title: string, so: ISO) => {
    if (!so) {
      return;
    }
    const lines: { result: ILineItem[] } = await get(`/lineitem?SOId=${getId(so)}`);

    const pdf = new PDF({
      headerCardData: {
        col1: ["Bill to", [so?.billingCity, so?.billingAddress, so?.billingCompany].filter(Boolean).join(",")],
        col2: ["Ship to", [so?.shippingCity, so?.shippingAddress, so?.shippingCompany].filter(Boolean).join(",")],
        col3: ["Date", formatTimestampToDate(new Date())],
      },
      summaryData: {
        "SO NO.": so?.number || "",
        "Entry Date": formatTimestampToDate(so?.date || new Date()),
        "Processed By": (so as any)?.issuedByName || "",
        "Estimated Ship Date": so?.estimatedShipDate ? formatTimestampToDate(so?.estimatedShipDate) : "",
      },
      title: title + " Order Acknowledgement",
    });

    pdf.addTable({
      y: pdf.cursor.y + 10,
      head: [
        {
          line: "Line",
          itemNo: "Item NO.",
          description: "Description",
          dateRequired: "Date Required",
          qty: "Qty",
          rate: "Rate",
          um: "U/M",
          amount: "Amount",
        },
      ],
      body: lines?.result.map((pl, i) => ({
        line: i + 1,
        itemNo: pl.ItemId?.no || pl.itemNo || "",
        description: pl.ItemId?.description || pl.description || "",
        dateRequired: "",
        qty: pl.qty,
        price: pl.price,
        um: pl.ItemId?.uom || "",
        amount: pl.price * pl.qty || 0,
      })),
      total: lines?.result.reduce((prev, curr) => prev + curr.price * curr.qty, 0),
      note: "",
    });

    const blob = pdf.output();
    await createAModelDocument({
      id: getId(so),
      model: "so",
      file: blob,
      description: `${title}-${so.number}`,
      fileName: `${title}-${so.number}.pdf`,
      name: `${title}-${so.number}.pdf`,
      number: so.number || "SO",
    });

    pdf.save(`${title}-${so.number}.pdf`);
  };
  useEffect(() => {
    if (lines?.result) {
      // setOnoverflow(lineItems.result);
      CalcPages(MAX_CONTENT_HEIGHT, lines.result);
    }
  }, [heights,lines]);

  useEffect(() => {
    const fetchDataAndCalculate = async () => {
      if (lines?.result) {
        // Run your async function here
        var heights = [];
        for (const item of lines.result) {
          const size = await measureDiv(item);
          heights.push(size.height)
        }
        setHeights(heights)
      }
    };    
    fetchDataAndCalculate(); // Call the async function

  }, [lines]);
  // useEffect(() => {
  //   if (open) {
  //     setTimeout(() => {
  //       handleDownload(true).then(() => {
  //         Toast("Documents uploaded", "success");
  //       });
  //     }, 1000);
  //   }
  // }, [handleDownload, open]);

  const getQrUrl = () => {
    const qr = new QR("so");
    return qr.generateSoUrl({ soId });
  };

  
  function CalcPages(maxHeight: number, totalItems: ILineItem[]) {
    const pages: ILineItem[][] = [];
    let currentPage: ILineItem[] = [];
    let cumulativeHeight = 0;

    // Define the height for all pages except the last page
    const standardPageMaxHeight = maxHeight;
    // const lastPageMaxHeight = maxHeight -300; // 60% of maxHeight for the last page
    console.log('====================================');
    console.log(heights);
    console.log('====================================');
    let count = 0; 

    for (let i = 0; i < totalItems.length; i++) {
        const item = totalItems[i];
        const itemHeight = heights[count] - 28; // Adjust the item height
        count += 1;

        // Determine if adding the next item would exceed the height limit of the current page
        if (cumulativeHeight + itemHeight > standardPageMaxHeight) {
            // Push the current page to pages and reset for the next page
            pages.push(currentPage);
            currentPage = [];
            cumulativeHeight = 0;
        }

        // Add the item and update the cumulative height
        currentPage.push(item);
        cumulativeHeight += itemHeight;
    }

    // Handle the last page case
    if (currentPage.length > 0) {
        // Check the cumulative height of the current page
        const remainingSpace = maxHeight - cumulativeHeight;

        // If there is not enough space for the div (e.g., 300)
        if (remainingSpace < 300) {
            pages.push(currentPage); // Push the current page
            pages.push([]); // Push an empty page to indicate that the next page is needed
        } else {
            // If there is enough space, just push the current page
            pages.push(currentPage);
        }
    } else {
        // If no items were added at all, you can also decide to push an empty page here
        pages.push([]); // Optional: push an empty page if needed
    }
    // console.log('============pages ================');
    // console.log(pages);
    // console.log('====================================');
    setTotalPagesContent(pages);
}

  const SOComponents = [
    { component: <SOPage1 data={data} pageNumber={1}  QrCode={<QRCode width={69} height={69} value={getQrUrl()} />}/>, key: "page1" },

    // Dynamically add SOPageX components
    ...Array.from({ length: totalPagesContent.length }, (_, index) => ({
      component: (
        <SOpagex
          data={data}
          page={index}
          lastPage ={totalPagesContent.length-1}
          pageNumber={index + 3} // Start from page 3
          Items={totalPagesContent[index]}
        />
      ),
      key: `pagex${index + 1}`, // Adjust key accordingly
    })),
  ];
  const SO = SOComponents.map((item, index) => {
    const pageNumber = index+1;
    return {
      component: React.cloneElement(item.component, { pageNumber }), // Pass pageNumber as a prop
      key: item.key, // Keep the original key for identification
    };
  });
  if (!createdSo || !lines?.result) {
    return (
      <MyDialog title="SO Pdf" open={open} onClose={onClose}>
        <Box m={2}>
          <CircularProgress />
        </Box>
      </MyDialog>
    );
  }

  return (
    <MyDialog title="SO Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box>
        <Button variant="contained" onClick={() => handleDownload(true)} disabled={loading}>
          Download
        </Button>
        <div className="flex-1 overflow-y-auto bg-gray-100" ref={SOpagesRef}>
          {SO.map(({ component, key }) => (
            <div
              className="w-[21cm] h-[29.7cm] bg-white border border-gray-300 mx-auto my-4 shadow-lg pdf-page"
              key={key}
            >
              {component}
            </div>
          ))}
        </div>
        {/* <div style={{ position: "relative", width: "21cm", margin: "auto" }}>
          {getId(createdSo) && (
            <div ref={qrRef} style={{ position: "absolute", right: 350 }}>
              <QRCode width={100} height={100} value={getQrUrl()} />
            </div>
          )}
          <AccountingHeader
            ref={headerRef}
            leftCardFields={[
              { title: "PO Received By", value: "" },
              { title: "SO Processed By", value: "" },
              { title: "SO Issued Date", value: formatTimestampToDate(new Date()) },
              { title: "SO Issued By", value: createdSo?.issuedByName || "" },
              { title: "Sent to Shipping", value: "" },
              { title: "Approval-Sales", value: "" },
              { title: "Approval-Accounting", value: "" },
              { title: "Approval-Manufacturing", value: "" },
              { title: "Approval-Engineering", value: "" },
            ]}
            rightCardFields={[
              { title: "Purchase Order Total", value: "" },
              {
                title: "Sales Tax",
                value: String(createdSo?.salesTaxPercent !== undefined ? createdSo?.salesTaxPercent : ""),
              },
              {
                title: "Shipping & Handling",
                value: String(createdSo?.shippingAndHandling !== undefined ? createdSo?.shippingAndHandling : ""),
              },
              {
                title: "Product Cost",
                value: String(createdSo?.totalCostUnits !== undefined ? createdSo?.totalCostUnits : ""),
              },
              { title: "Reg. Commission Rate %", value: "" },
              { title: "Total Commission Amt", value: "" },
              { title: "Commission Name", value: "" },
              { title: "Warranty", value: "" },
              { title: "Net Total", value: "" },
            ]}
            so={{ ...createdSo, number: createdSo?.number } as any}
          />
          <AccountingLineItems ref={lineItemsRef} groups={[lines.result]} />
          <TotalFooter ref={totalRef} data={getSoFinancialSummary(createdSo)} notes="" />
        </div> */}
      </Box>
    </MyDialog>
  );
}

function measureDiv(item: ILineItem): Promise<{ width: number; height: number }> {
  return new Promise((resolve) => {
    // Create a temporary container
    const tempDiv = document.createElement('div');

    // Apply styles for offscreen positioning and sizing
    tempDiv.style.position = 'absolute';
    tempDiv.style.top = '-9999px';
    tempDiv.style.left = '-9999px';
    tempDiv.style.width = '672.938px';
    tempDiv.style.height = 'auto';

    // Append the tempDiv to the document body
    document.body.appendChild(tempDiv);

    // Render the ItemTable component into tempDiv
    ReactDOM.render(
      <div className="w-full h-auto">
        <ItemTable
            groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
            description={item.description ? extractDescriptionParts(item.description) : null}
            qty={item.qty}
            unitPrice={item.price}
            totalPrice={item.total}
            descriptionElement={null}
        />
      </div>,
      tempDiv
    );

    // Use requestAnimationFrame to measure after rendering is complete
    requestAnimationFrame(() => {
      const dimensions = {
        width: tempDiv.offsetWidth,
        height: tempDiv.offsetHeight
      };

      // Clean up
      ReactDOM.unmountComponentAtNode(tempDiv);
      document.body.removeChild(tempDiv);

      // Resolve with the measured dimensions
      resolve(dimensions);
    });
  });
}

function extractDescriptionParts(description: string) {
  // Check if the description contains any of the delimiters
  if (
    !description.includes("*") &&
    !description.includes(";") &&
    !description.includes(",") &&
    !description.includes("⚭")
  ) {
    return [description.trim()];
  }

  // Split the description into lines based on the delimiters: *, ;, or ,
  // const lines = description.split(/[\*\;\,]\s*/);
  const lines = description.split(/[\*\;\,\⚭]\s*/);

  // Trim each line and filter out any empty lines
  return lines.map((line) => line.trim()).filter((line) => line.length > 0);
}
