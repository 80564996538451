import { get, ListResponse } from "api";
import { connectDB } from "./Schema";

export async function getAndSaveItems() {
  const db = await connectDB();

  await db.delete("last-updated-entities", "items");
  const items: ListResponse<{
    id: string;
    no: string;
    name: string;
    description: string;
  }> = await get("/item/partassy", { params: { pageSize: 50 * 1000 } });

  await db.add("last-updated-entities", new Date(), "items");

  const itemsTx = db.transaction("items", "readwrite");
  let cnt = 0;
  for (const item of items.result) {
    await itemsTx.store.put({
      ...item,
      cluster: item.name?.split(",")[0] || "unknown",
    });
    cnt += 1;
  }
  console.log(`Saved ${cnt} items.`);
}

export async function fetchClusters() {
  const db = await connectDB();

  const set = new Set<string>();
  const items = await db.getAll("items");
  for await (const item of items) {
    set.add(item.cluster);
  }
  return Array.from(set);
}

export async function fetchItems() {
  const db = await connectDB();
  return db.getAll("items");
}

export async function refreshItems() {
  const db = await connectDB();
  await db.delete("last-updated-entities", "items");
  await db.clear("items");
  await getAndSaveItems();
}
