import React from "react";
import { FormControlLabel, Checkbox, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Button from "app/Button";
import { Gradients } from "theme";
import { loginThunk } from "./sessionsSlice";
import Toast from "app/Toast";

const schema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required().min(4),
});

export default function LoginForm() {
  const dispatch = useDispatch();

  const handleSubmit = async (data: any) => {
    try {
      dispatch(loginThunk(data));
      Toast("Login SuccessFully", "success");
    } catch (error) {
      console.log(error);
      Toast("Username or password is incorrect", "error");
    }
  };

  return (
    <>
      <h1>Login</h1>
      {
        <Formik initialValues={{ username: "", password: "" }} validationSchema={schema} onSubmit={handleSubmit}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form style={{ maxWidth: 300, margin: "0 auto" }}>
              <TextField
                fullWidth
                placeholder="username"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.username && touched.username)}
                style={{ width: "100%", background: "#FFF", margin: "0.5em 0" }}
              />
              <TextField
                fullWidth
                placeholder="password"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.password && touched.password)}
                style={{ width: "100%", background: "#FFF", margin: "0.5em 0" }}
              />
              {/* <Typography
                display="flex"
                justifyContent="flex-end"
                color="primary"
                style={{ cursor: "pointer", marginTop: "10px" }}
                onClick={() => {
                  setStep(1);
                }}
              >
                forgot password ?
              </Typography> */}
              <FormControlLabel
                sx={{ color: "#FFFFFF", mt: 2 }}
                label="Keep me Loged in"
                control={<Checkbox sx={{ color: "#E68031" }} />}
              />
              <Button
                fullWidth
                disabled={isSubmitting}
                dialogMode
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: "2em 0", background: Gradients.success }}
              >
                login
              </Button>
            </Form>
          )}
        </Formik>
      }
      {/* {step === 1 && !email && (
        <Formik initialValues={{ email: "" }} onSubmit={sendReq}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form style={{ maxWidth: 300, margin: "0 auto" }}>
              <TextField
                fullWidth
                style={{ width: "100%", margin: "0.5em 0", background: "#FFF" }}
                placeholder="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.email && touched.email)}
              />
              <Button
                fullWidth
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: "2em 0", background: Gradients.success }}
              >
                Send Code
              </Button>
            </Form>
          )}
        </Formik>
      )}
      {step === 1 && email && (
        <Formik initialValues={{ code: "", password: "", repPassword: "" }} onSubmit={sendCode}>
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form style={{ maxWidth: 300, margin: "0 auto" }}>
              <TextField
                fullWidth
                style={{ margin: "0.5em 0", background: "#FFF" }}
                placeholder="code"
                name="code"
                value={values.code}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.code && touched.code)}
              />
              <TextField
                label="New Password"
                placeholder="password"
                type="password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={Boolean(errors.password && touched.password)}
                helperText={touched.repPassword && errors.password}
                style={{ margin: "0.5em 0", background: "#FFF" }}
                fullWidth
              />
              <Button
                fullWidth
                disabled={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                style={{ margin: "2em 0", background: Gradients.success }}
              >
                Submit Code
              </Button>
            </Form>
          )}
        </Formik>
      )} */}
    </>
  );
}
