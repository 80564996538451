import React, { CSSProperties } from "react";

import cautionIcon from "assets/icons/instruction_types/CAUTION.svg";
import dangerIcon from "assets/icons/instruction_types/DANGER.svg";
import instructionIcon from "assets/icons/instruction_types/Instruction.svg";
import noteIcon from "assets/icons/instruction_types/NOTE.svg";
import warningIcon from "assets/icons/instruction_types/WARNING.svg";
import cameraIcon from "assets/icons/instruction_types/camera.svg";

export default function TypeIcon({
  type,
  style,
}: {
  type: "caution" | "danger" | "instruction" | "note" | "warning" | "camera";
  style?: CSSProperties;
}) {
  switch (type) {
    case "caution":
      return <img src={cautionIcon} alt="caution" style={style} />;
    case "danger":
      return <img src={dangerIcon} alt="danger" style={style} />;
    case "instruction":
      return <img src={instructionIcon} alt="instruction" style={style} />;
    case "note":
      return <img src={noteIcon} alt="note" style={style} />;
    case "warning":
      return <img src={warningIcon} alt="warning" style={style} />;
    case "camera":
      return <img src={cameraIcon} alt="camera" style={style} />;
    default:
      return <></>;
  }
}
