import React, { useRef, useState } from "react";
import { Box, Button, Divider, LinearProgress, Typography } from "@mui/material";
import useSWR from "swr";
import { jsPDF } from "jspdf";
import autotable from "jspdf-autotable";
import { renderList } from "logic/pdf";
import { formatTimestampToDate } from "logic/date";
import Toast from "app/Toast";
import Dialog from "app/Dialog";
import MyQRCode from "app/QRCode";
import { generateQuery, getId } from "logic/utils";
import { createAModelDocument, getAllModelDocuments } from "api/document";
import UnitInfo from "PDFTemplates/UnitInfo";
import ProcedureInfo from "PDFTemplates/Procedure/info";
import ProcedureSteps from "PDFTemplates/Procedure/Steps";
import ProcedureHeader from "PDFTemplates/Procedure/Header";

import jobHeaderImage from "assets/icons/new_header.png";
import headerPng from "assets/icons/new_header.png";
import crossCheckCover from "assets/pdf/cross_check_cover.png";

import { QR } from "logic/QR";
import { host } from "host";
import appendPdf from "logic/pdf/appendPdf";

export default function TravelerPdfModal({
  open,
  unitId,
  onDone,
  onClose,
}: {
  open: boolean;
  unitId: string;
  onDone?: () => void;
  onClose: () => void;
}) {
  const { data: unit } = useSWR<any>(unitId ? `/unit/${unitId}` : null);
  const { data: photos } = useSWR<any>(unit ? `/photo/item/${getId(unit.ItemId)}` : null);
  const { data: jobrecords } = useSWR<{ result: any[]; total: number; totalPartCost: number }>(
    `/unit/${unitId}/jobrecords`
  );
  const { data: batteryrecords } = useSWR<{ result: any[]; total: number; totalPartCost: number }>(
    `/unit/${unit.id}/jobrecords?batteryPack=${true}`
  );

  const { data: options } = useSWR<any>(`/unit/${unitId}/option`);

  const stepsQuery = generateQuery({
    context: "Test",
    UnitId: getId(unit),
    pageSize: 500,
  });
  const { data: testingsteps } = useSWR<any>(unit ? `/step?${stepsQuery}` : null);
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);

  const unitInfoRef = useRef<HTMLDivElement | null>(null);
  const unitJobRecordsRef = useRef<HTMLDivElement | null>(null);
  const jobRecordsTableRef = useRef<HTMLTableElement | null>(null);
  const batteryRecordsTableRef = useRef<HTMLTableElement | null>(null);
  const qrCodeRef = useRef<HTMLDivElement | null>(null);
  const procedureHeader = useRef<HTMLDivElement | null>(null);
  const procedureInfo = useRef<HTMLDivElement | null>(null);
  const procedureSteps = useRef<HTMLDivElement | null>(null);

  const generatePdf = async (upload?: boolean) => {
    try {
      setLoading(true);
      if (!unit) {
        return;
      }
      let crossCheckDocuments: string[] = [];
      try {
        const crossCheckResponse = await getAllModelDocuments("cross-check", getId(unit?.ItemId));
        crossCheckDocuments = crossCheckResponse?.result?.map((i: any) => host + i.path);
      } catch {}

      if (
        unitInfoRef.current &&
        jobRecordsTableRef.current &&
        batteryRecordsTableRef.current &&
        procedureHeader.current &&
        procedureInfo.current &&
        procedureSteps.current &&
        qrCodeRef.current
      ) {
        const doc = new jsPDF();
        let pages = 0;
        const scale = 0.265;
        const pageHeight = doc.internal.pageSize.height;
        const pageWidth = doc.internal.pageSize.width;

        await doc.html(unitInfoRef.current, {
          x: 5,
          y: 0,
          html2canvas: {
            scale: 0.24,
            letterRendering: true,
          },
        });

        const qrCanvas = qrCodeRef.current.getElementsByTagName("canvas")[0];
        doc.addImage(qrCanvas, "image/png", 50, 10, 20, 20);

        doc.setFontSize(11);
        doc.text("DSPManufacturing.com", 5, pageHeight - 10);
        doc.text("Page 1", pageWidth - 20, pageHeight - 10);
        pages++;
        doc.addPage();
        autotable(doc, {
          startY: 70,
          margin: { top: 70 },
          html: jobRecordsTableRef.current,
          showHead: "everyPage",
          rowPageBreak: "avoid",
          didDrawPage(data) {
            doc.addImage(jobHeaderImage, "png", 0, 0, pageWidth, 60);

            doc.setFontSize(11);
            doc.text("DSPManufacturing.com", 5, pageHeight - 10);
            doc.text(`Page ${Number(data.pageNumber) + 1}`, pageWidth - 20, pageHeight - 10);
            pages++;
          },
        });
        doc.addPage();
        autotable(doc, {
          startY: 70,
          margin: { top: 70 },
          html: batteryRecordsTableRef.current,
          showHead: "everyPage",
          rowPageBreak: "avoid",
          didDrawPage(data) {
            doc.addImage(jobHeaderImage, "png", 0, 0, pageWidth, 60);

            doc.setFontSize(11);
            doc.text("DSPManufacturing.com", 5, pageHeight - 10);
            doc.text(`Page ${Number(data.pageNumber) + 1}`, pageWidth - 20, pageHeight - 10);
            pages++;
          },
        });

        setProgress(35);

        doc.addPage();
        doc.addImage(crossCheckCover, "JPEG", 0, 0, 210, 290);
        pages += 1;

        let start = performance.now();
        for await (const crossCheck of crossCheckDocuments) {
          console.log("Appending cross check");

          const newPages = await appendPdf({
            pdfInstance: doc,
            url: crossCheck,
          });
          pages += newPages || 0;
        }
        doc.addPage();
        let end = performance.now();
        console.log(`cross check took ${end - start} ms`);

        setProgress(50);

        await doc.html(procedureHeader.current, {
          x: 0,
          y: pages * pageHeight,
          html2canvas: { scale },
        });
        await doc.html(procedureInfo.current, {
          x: 0,
          y: pages * pageHeight + 60,
          html2canvas: { scale },
        });

        setProgress(75);

        start = performance.now();
        await renderList({
          x: 10,
          y: 170,
          pdf: doc,
          list: procedureSteps.current.children,
          pageMarginY: 2,
          marginBottom: 10,
        });
        end = performance.now();
        console.log(`procedureSteps took ${end - start} ms`);

        setProgress(100);

        if (upload) {
          const blob = doc.output("blob");
          await createAModelDocument({
            file: blob,
            model: "unit",
            id: unitId,
            description: `unit-traveler-${unit?.serial}`,
            fileName: `unit-traveler-${unit?.serial}.pdf`,
            name: `unit-traveler-${unit?.serial}`,
          });

          onDone && onDone();
          onClose();
        } else {
          doc.save(`traveler-${formatTimestampToDate(Number(new Date()))}.pdf`);
        }
      }
    } catch (error) {
      console.log(error);
      Toast("An error occurred, please try again", "error");
    } finally {
      setLoading(false);
    }
  };

  if (!unit) {
    return <></>;
  }

  const getUrl = () => {
    const qr = new QR("unit");
    return qr.generateUnitUrl({
      unitId: getId(unit),
      serialNumber: unit?.serial,
      soNumber: unit?.so,
      no: unit?.ItemId?.no,
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setProgress(0);
      }}
      fullScreen
      title="PDF"
    >
      <Box mx="auto" my={2} maxWidth="21cm" display="flex" alignItems="center" justifyContent="space-between">
        <Button variant="contained" onClick={() => generatePdf(true)} disabled={loading}>
          Upload as document
        </Button>
        <Button variant="contained" onClick={() => generatePdf(false)} disabled={loading}>
          Download
        </Button>
      </Box>
      {progress ? (
        <LinearProgress variant="determinate" value={progress} sx={{ maxWidth: "21cm", margin: "auto" }} />
      ) : (
        <></>
      )}
      <Box
        className="print-only"
        sx={{
          display: "flex column",
          overflowY: "hidden",
          width: "auto",
          height: "auto",
        }}
      >
        <div style={{ width: "21cm", position: "relative", margin: "auto" }}>
          <Box sx={{ height: 0, overflow: "hidden" }}>
            <Box ref={qrCodeRef} sx={{ width: 130 }}>
              <MyQRCode value={getUrl()} width={100} height={100} />
            </Box>
          </Box>
          <div ref={unitInfoRef}>
            <UnitInfo photo={photos?.[0]} unit={unit} options={options} />
          </div>
        </div>
        <div style={{ margin: "30px 0" }} />
        <Box ref={unitJobRecordsRef} width="21cm" height="auto" m="auto">
          <Box
            sx={{
              fontWeight: "bold",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                width: "80%",
                color: "white",
                fontSize: "40px",
                left: "50px",
                top: "20px",
                position: "absolute",
                overflow: "visible",
              }}
            >
              JOB
            </Typography>
            <img style={{ width: "100%", height: "auto" }} src={headerPng} alt="header"></img>
          </Box>

          <Divider sx={{ borderWidth: 8, borderColor: "#f9c149", marginTop: "10px" }} />
          <Divider sx={{ borderWidth: 10, borderColor: "#0b5284", marginTop: "10px" }} />

          <Box
            sx={{
              "& table th": {
                height: "35px",
                width: "35px",
                margin: "5px",
                backgroundColor: "#f9c149",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              },

              "& table td": {
                height: "auto",
                width: "auto",
                minHeight: "35px",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              },
            }}
          >
            <table ref={jobRecordsTableRef} style={{ width: "100%", margin: "10px 0" }}>
              <thead>
                <tr>
                  <th>Line</th>
                  <th>Item NO.</th>
                  <th>Item Name</th>
                  <th>Item Location</th>
                  <th>UOM</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {jobrecords?.result?.map((value: any, index: number) => (
                  <tr key={index}>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{index + 1}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemNo}</td>
                    <td
                      style={{
                        backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5",
                        textAlign: "left",
                        padding: "4px",
                      }}
                    >
                      {value.ItemId?.name || value.item?.name}
                    </td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemLocation}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemUOM}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.usage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          <Box
            sx={{
              "& table th": {
                height: "35px",
                width: "35px",
                margin: "5px",
                backgroundColor: "#f9c149",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
              },

              "& table td": {
                height: "auto",
                width: "auto",
                minHeight: "35px",
                borderRadius: "10px",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
              },
            }}
          >
            <table ref={batteryRecordsTableRef} style={{ width: "100%", margin: "10px 0" }}>
              <thead>
                <tr>
                  <th>Line</th>
                  <th>Item NO.</th>
                  <th>Item Name</th>
                  <th>Item Location</th>
                  <th>UOM</th>
                  <th>QTY</th>
                </tr>
              </thead>
              <tbody>
                {batteryrecords?.result?.map((value: any, index: number) => (
                  <tr key={index}>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{index + 1}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemNo}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.ItemId?.name}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemLocation}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.itemUOM}</td>
                    <td style={{ backgroundColor: index % 2 === 0 ? "#eaeaea" : "#f5f5f5" }}>{value.usage}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Box>

          <footer
            style={{
              justifyContent: "space-between",
              height: "auto",
              display: "flex",
              width: "100%",
              backgroundColor: "#6291b2",
            }}
          >
            <p style={{ color: "white", fontSize: "16px", marginLeft: "15px" }}>DSPManufacturing.com</p>
            <p style={{ color: "white", fontSize: "16px", marginRight: "15px" }}>Page | 02</p>
          </footer>
        </Box>

        <ProcedureHeader ref={procedureHeader} status="" variant="Test Report" />
        <div style={{ margin: "40px 0" }} />
        <ProcedureInfo
          ref={procedureInfo}
          serial={unit.serial}
          model={unit.unitModel}
          modelName={unit.model}
          description={unit.description}
        />
        <div style={{ margin: "70px 0" }} />
        <ProcedureSteps ref={procedureSteps} steps={testingsteps?.result || []} />
      </Box>
    </Dialog>
  );
}
