import React, { forwardRef } from "react";
import { IPO } from "api/po";
import { formatTimestampToDate } from "logic/date";
import { IVendor } from "api/vendor";
import dspm from "assets/icons/dspm.png";

const PoHeader = forwardRef<
  HTMLDivElement,
  { receiveNumber?: string; receivedByName?: string; vendor?: IVendor; po?: IPO }
>(({ receiveNumber, receivedByName, po, vendor }, ref) => {
  return (
    <div
      ref={ref}
      style={{
        width: "100%",
        marginBottom: "10px",
        position: "relative",
      }}
    >
      <img alt="dspm" src={dspm} style={{ width: "120px", height: "auto", marginTop: "20px" }} />
      <p
        style={{
          fontSize: "22px",
          fontWeight: "bold",
          margin: "20px",
        }}
      >
        RECEIVING LIST
      </p>
      <div
        style={{
          background: "#f1f1f1",
          width: "75%",
          padding: "20px",
          paddingRight: 60,
          borderTopRightRadius: 50,
          borderBottomRightRadius: 50,
          display: "flex",
          gap: 2,
        }}
      >
        <div>
          <h3 style={{ flex: 1, fontSize: 16, margin: "8px 0" }}>Vendor:</h3>
          <div>{vendor?.name}</div>
          <div>{vendor?.city}</div>
          <div>{vendor?.address}</div>
        </div>
        <div style={{ flex: 1, borderLeft: "2px solid white", paddingLeft: 10 }}>
          <h3 style={{ fontSize: 16, margin: "8px 0" }}>Bill to:</h3>
          <div>{po?.billingCompany}</div>
          <div>{po?.billingCity}</div>
          <div>{po?.billingAddress}</div>
          <div>{po?.billingEmail}</div>
          <div>{po?.billingPhone}</div>
        </div>
        <div style={{ flex: 1, borderLeft: "2px solid white", paddingLeft: 10 }}>
          <h3 style={{ fontSize: 16, margin: "8px 0" }}>Ship to:</h3>
          <div>{po?.shippingCompany}</div>
          <div>{po?.shippingCity}</div>
          <div>{po?.shippingAddress}</div>
          <div>{po?.shippingEmail}</div>
          <div>{po?.shippingPhone}</div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 300,
          height: 250,
          display: "flex",
          justifyContent: "center",
          background: "#ccc",
          borderBottomLeftRadius: 32,
          border: "4px solid white",
        }}
      >
        {/* <img
          src={receiveCircle}
          alt=""
          style={{ position: "absolute", top: 0, right: 0, width: "100%", height: "auto", objectFit: "contain" }}
        /> */}
        <div
          style={{
            marginTop: 40,
            position: "relative",
            // zIndex: 5,
            color: "#000",
            width: 200,
          }}
        >
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Receive Number:</span>
            <span style={{ fontSize: 12 }}>{receiveNumber}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>PO Number:</span>
            <span style={{ fontSize: 12 }}>{po?.number}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Date:</span>
            <span style={{ fontSize: 12 }}>{formatTimestampToDate(po?.createdAt)}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Expected Date:</span>
            <span style={{ fontSize: 12 }}></span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Date Acknowledged:</span>
            <span style={{ fontSize: 12 }}>{formatTimestampToDate(po?.ackDate)}</span>
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "5px 0" }}>
            <span style={{ fontSize: 12, fontWeight: "bold" }}>Received By:</span>
            <span style={{ fontSize: 12 }}>{receivedByName}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoHeader;
