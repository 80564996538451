import { instructionArgType } from "api/instruction";

export const sortSteps = (rows: any[]): any[] => {
  return rows.sort((a, b) => {
    if (!a.step?.includes(".") || !b.step?.includes(".")) {
      return 0;
    }
    const a1 = Number(a.step?.split(".")?.[0]);
    const a2 = Number(a.step?.split(".")?.[1]);
    const b1 = Number(b.step?.split(".")?.[0]);
    const b2 = Number(b.step?.split(".")?.[1]);

    if (a1 < b1 || (a1 === b1 && a2 < b2)) {
      return -1;
    }

    if (a1 > b1 || (a1 === b1 && a2 > b2)) {
      return 1;
    }

    return 0;
  });
};

export function isInvalid(arg: instructionArgType, value: string) {
  if (!arg.min && !arg.max) {
    return false;
  }
  if (!arg.min) {
    return Number(value) > Number(arg.max);
  }
  if (!arg.max) {
    return Number(value) < Number(arg.min);
  }
  return Number(value) < Number(arg.min) || Number(value) > Number(arg.max);
}

export function getHelperText(arg: instructionArgType) {
  if (arg.max && arg.min) {
    return `${arg.key} should be between ${arg.min} and ${arg.max}`;
  }
  if (arg.min) {
    return `${arg.key} should be greater than ${arg.min}`;
  }

  return `${arg.key} should be less than ${arg.max}`;
}
