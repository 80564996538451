import React from "react";
import { styled } from "@mui/material";
import { SearchRounded } from "@mui/icons-material";
import InputBase, { InputBaseProps } from "@mui/material/InputBase";
import MuiTextField, { StandardTextFieldProps } from "@mui/material/TextField";
import styles from "./TextField.module.css";

export const SearchBar = () => {
  return (
    <div className={styles.searchBarRoot}>
      <InputBase className={styles.searchInput} placeholder="type the biller you want to pay here" />
      <SearchRounded htmlColor="gray" />
    </div>
  );
};

export interface IBaseTextField extends InputBaseProps {
  label?: string;
  helperText?: React.ReactNode;
  children?: React.ReactNode;
}

export default function TextField(props: StandardTextFieldProps) {
  return (
    <MuiTextField
      inputProps={{
        style: { ...props.inputProps?.style, fontSize: "0.8rem", color: "black" },
      }}
      InputLabelProps={{ shrink: true, ...props.InputLabelProps }}
      variant="outlined"
      size="small"
      sx={{
        "& input, textarea": {
          "-webkit-text-fill-color": "#000 !important",
        },
      }}
      {...props}
    />
  );
}

export const ValidationTextField = styled(MuiTextField)({
  "& input:valid + fieldset": {
    borderColor: "green",
    borderWidth: 2,
  },
  "& input:invalid + fieldset": {
    borderColor: "red",
    borderWidth: 2,
  },
  "& input:valid:focus + fieldset": {
    borderLeftWidth: 6,
    padding: "4px !important",
  },
});
