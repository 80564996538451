import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { IEmployee, updateEmployeePassword } from "api/employee";
import Toast from "app/Toast";
import Button from "app/Button";
import TextField from "app/TextField";

const passSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  newPassword: Yup.string().required(),
  repPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("newPassword")], "Passwords must match"),
});

export default function PasswordForm({
  onClose,
  setTab,
  initialVals,
}: {
  onClose: () => void;
  setTab?: any;
  initialVals?: IEmployee;
}) {
  const handleChangePass = async (data: any, { setSubmitting }: { setSubmitting: (v: boolean) => void }) => {
    try {
      setSubmitting(true);
      if (initialVals && initialVals.id) {
        const resp = await updateEmployeePassword(data);
        if (resp) {
          Toast("password changed", "success");
          setSubmitting(false);
          onClose();
          setTab(0);
        }
      } else {
        Toast("employee is undefined", "error");
      }
    } catch (error) {
      console.log(data);
    }
  };

  return (
    <Formik
      initialValues={{ oldPassword: "", newPassword: "", repPassword: "" }}
      validationSchema={passSchema}
      onSubmit={handleChangePass}
    >
      {({ values, touched, errors, handleChange, handleBlur, isSubmitting, handleSubmit }) => (
        <Form>
          <Box display="grid" gridTemplateColumns="1fr" gap={2}>
            <TextField
              label="Current Password"
              type="text"
              name="oldPassword"
              value={values.oldPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.oldPassword && touched.oldPassword)}
              helperText={errors.oldPassword}
            />
            <TextField
              label="New Password"
              type="text"
              name="newPassword"
              value={values.newPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.newPassword && touched.newPassword)}
              helperText={touched.repPassword && errors.repPassword}
            />
            <TextField
              label="Confirm Password"
              type="text"
              name="repPassword"
              value={values.repPassword}
              onChange={handleChange}
              onBlur={handleBlur}
              error={Boolean(errors.repPassword && touched.repPassword)}
              helperText={touched.repPassword && errors.repPassword}
            />
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {isSubmitting ? (
                <CircularProgress />
              ) : (
                <Button
                  fullWidth
                  disabled={isSubmitting}
                  dialogMode
                  type="button"
                  onClick={() => handleSubmit()}
                  kind="edit"
                >
                  Change
                </Button>
              )}
            </Box>
          </Box>
        </Form>
      )}
    </Formik>
  );
}
