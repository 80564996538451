import { getToken } from "api";
import SocketAgent, { listenerType } from ".";

export type messageType = {
  _id: string;
  hasFile: boolean;
  fileAddress?: string | null;
  content: string;
  from: string;
  to: string;
  fromSelf?: boolean;
};

export type userType = {
  messages: messageType[];
  sockets: string[];
  status: "online" | "offline";
  userID: string;
  username: string;
  hasNewMessages?: boolean;
};

export default class ChatAdapter {
  private socket = SocketAgent.getInstance();
  private token = getToken();

  public getSocketId() {
    return this.socket ? this.socket.socketio?.id : null;
  }

  public getSocketAuth() {
    return this.token;
  }

  public get isConnected(): boolean {
    return Boolean(this.socket.socketio?.connected);
  }

  public connect() {
    if (this.socket) {
      this.socket.send("auth");
    }
  }

  public sendPrivateMessage({ content, from, to, file }: { content: string; to: string; from: string; file?: string }) {
    if (this.socket) {
      this.socket.send(
        "private message",
        JSON.stringify({
          to,
          from,
          content,
          fileAddress: file ? file : null,
          hasFile: Boolean(file),
        })
      );
    }
  }

  public disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  public onUsers(onUsers: listenerType) {
    this.socket.subscribe("users", onUsers);
  }

  public onConnected(listener: listenerType) {
    this.socket.subscribe("socket connected", listener);
  }

  public onUserConnected(onUserConnected: listenerType) {
    this.socket.subscribe("user connected", onUserConnected);
  }

  public onUserDisconnected(onUserDisconnected: listenerType) {
    this.socket.subscribe("user disconnected", onUserDisconnected);
  }

  public onPrivateMessage(onPrivateMessage: listenerType) {
    this.socket.subscribe("private message", onPrivateMessage);
  }
}
