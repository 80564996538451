import React, { Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import MyBackdrop from "app/Backdrop";
import PanelLayout from "layouts/Panel";
import PurchaseHelp from "./purchaseHelp";
import WebsiteSettings from "./WebsiteSettings";
import HomePage from "./WebsiteSettings/HomePage";
import Magazine from "./WebsiteSettings/Magazine";
import Management from "./WebsiteSettings/Management";
const Production = React.lazy(() => import("./Production"));
const Purchase = React.lazy(() => import("./Purchasing"));
const Sales = React.lazy(() => import("./Sales"));
const Inventory = React.lazy(() => import("./Inventory"));
const FieldService = React.lazy(() => import("./Servicing"));
const Engineering = React.lazy(() => import("./Engineering"));
const PhocusMonitor = React.lazy(() => import("./phocusMonitor"));
const Accounting = React.lazy(() => import("./accounting"));
const Shipping = React.lazy(() => import("./ShippingAndReceiving"));
const Setting = React.lazy(() => import("./SettingPanel"));
const AddType = React.lazy(() => import("../pages/Setting/AddType"));
const Home = React.lazy(() => import("../pages/home"));
const Dashboard = React.lazy(() => import("../pages/HomeDashboard"));
const Roles = React.lazy(() => import("../pages/Roles"));
const Projects = React.lazy(() => import("../pages/Project"));
const Activity = React.lazy(() => import("../pages/Activity"));
const Notification = React.lazy(() => import("../pages/Notification"));
const Page404 = React.lazy(() => import("../pages/404"));
const QuestionDetail = React.lazy(() => import("pages/questions/[id]"));
const QuoteDetails = React.lazy(() => import("../pages/QuoteDetails"));
const SODetails = React.lazy(() => import("../pages/SODetails"));
const CustomerDetails = React.lazy(() => import("../pages/CustomerDetails"));
const BomParts = React.lazy(() => import("../pages/BomParts"));
const JobParts = React.lazy(() => import("../pages/JobParts"));
const ProcessInstructions = React.lazy(() => import("../pages/ProcessInstructions"));
const Voucher = React.lazy(() => import("../Router/Voucher"));
const ActionPlan = React.lazy(() => import("../Router/ActionPlanDetail"));

export const chatDrawerWidth = 340;
export const phonechatDrawerWidth = 240;

export default function PanelRouter() {
  return (
    <PanelLayout>
      <Suspense fallback={<MyBackdrop />}>
        <Switch>
          <Route exact path="/panel/home" component={() => <Home />} />
          <Route exact path="/panel/notification" component={Notification} />
          <Route exact path="/panel/dashboard" component={Dashboard} />

          <Route exact path="/panel/projects" component={Projects} />
          <Route exact path="/panel/activity" component={Activity} />
          <Route exact path="/panel/roles" component={Roles} />
          <Route exact path="/panel/questions/:id" component={QuestionDetail} />

          <Route path="/panel/shipping" component={Shipping} />
          <Route path="/panel/setting" component={Setting} />
          <Route path="/panel/website-settings" component={WebsiteSettings} />
          <Route path="/panel/website-settings/management" component={Management} />
          <Route path="/panel/website-settings/magazine" component={Magazine} />

          <Route path="/panel/add-type" component={AddType} />

          <Route path="/panel/engineering" component={Engineering} />
          <Route path="/panel/fieldservice" component={FieldService} />
          <Route path="/panel/phocusMonitor" component={PhocusMonitor} />
          <Route path="/panel/accounting" component={Accounting} />

          <Route path="/panel/inventory" component={Inventory} />
          <Route path="/panel/sales" component={Sales} />
          <Route path="/panel/purchase" component={Purchase} />
          <Route path="/panel/production" component={Production} />
          <Route path="/panel/voucher/:voucherId" component={Voucher} />
          <Route path="/panel/actionPlan/:actionId" component={ActionPlan} />

          <Route exact path="/panel/quote/:quoteNumber" component={QuoteDetails} />
          <Route exact path="/panel/so/:soNumber" component={SODetails} />
          <Route exact path="/panel/customer/:cusNumber" component={CustomerDetails} />

          <Route exact path="/panel/bom/:bomId/parts" component={BomParts} />
          <Route exact path="/panel/job/:unitId" component={JobParts} />
          <Route exact path="/panel/process/:processId" component={ProcessInstructions} />
          <Route path="/panel/purchaseHelp">
            <PurchaseHelp url="https://phocuss.com/purchasing-help/" />
          </Route>
          <Route path="/panel/salesHelp">
            <PurchaseHelp url="https://phocuss.com/sales-management-help/" />
          </Route>
          <Route path="/panel/engHelp">
            <PurchaseHelp url="https://phocuss.com/engineering-help/" />
          </Route>
          <Route path="/panel/accountingHelp">
            <PurchaseHelp url="https://phocuss.com/accounting-help/" />
          </Route>

          <Route exact path="*" component={Page404} />
        </Switch>
      </Suspense>
    </PanelLayout>
  );
}
