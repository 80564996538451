import React, { Suspense, useState } from "react";
import { Box, ListItem, Tabs, Tab, useMediaQuery, Button } from "@mui/material";
import { FindInPageRounded, ListAltRounded } from "@mui/icons-material";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { BasePaper } from "app/Paper";
import MyBackdrop from "app/Backdrop";
import BlogDetails from "pages/magazine/BlogDetails";
import BlogTable from "pages/magazine/BlogTable";
import AddPost from "pages/magazine/modals/AddPost";

const Magazine = () => {
  const phone = useMediaQuery("(max-width:600px)");
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(location.pathname.split("/").length === 5 ? 1 : 0);
  const { data } = useSWR<any>(`/post`);
  const [addPostModal, setAddPostModal] = useState(false);
  const [refresh, setRefresh] = useState(0);

  return (
    <>
      {addPostModal && <AddPost setRefresh={setRefresh} open={addPostModal} onClose={() => setAddPostModal(false)} />}
      <BasePaper style={{ height: "100%" }}>
        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
          <Tabs
            value={activeTab}
            textColor="primary"
            onChange={(e, nv) => {
              setActiveTab(nv);
              history.push({
                pathname: "/panel/website-settings/magazine",
                search: window.location.search,
              });
            }}
            sx={{ width: "90%" }}
          >
            <Tab
              icon={
                <span style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <ListAltRounded style={{ marginRight: "5px" }} /> Posts
                </span>
              }
              wrapped
            />
            <Tab
              disabled={activeTab !== 1}
              icon={
                <span style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                  <FindInPageRounded style={{ marginRight: "5px" }} /> Details
                </span>
              }
            />
          </Tabs>
          <div style={{ flexGrow: 1 }} />
          {!phone && activeTab === 0 && (
            <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button variant="outlined" onClick={() => setAddPostModal(true)}>
                Create Post
              </Button>
            </ListItem>
          )}
        </Box>
        {phone && activeTab === 0 && (
          <ListItem sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button variant="outlined" onClick={() => {}}>
              Create Post
            </Button>
          </ListItem>
        )}
        <Box display="flex" flex={1}>
          <Suspense fallback={<MyBackdrop />}>
            <Switch>
              <Route exact path="/panel/website-settings/magazine">
                <BlogTable
                  refresh={refresh}
                  onRowSelected={(r: any) => {
                    setActiveTab(1);
                    history.push(`/panel/website-settings/magazine/${r.id}`);
                  }}
                />
              </Route>
              <Route exact path={`/panel/website-settings/magazine/:postId`}>
                <BlogDetails />
              </Route>
            </Switch>
          </Suspense>
        </Box>
      </BasePaper>
    </>
  );
};

export default Magazine;
