import React, { useState } from "react";
import { Chip } from "@mui/material";
import useSWR, { mutate } from "swr";

import { IRole } from "api/role";
import { deleteRoleFromEmployee } from "api/employee";

export default function RoleChip({
  employeeId,
  roleId,
  onRemoveDone,
}: {
  employeeId: string;
  roleId: string;
  onRemoveDone?: () => void;
}) {
  const [disabled, setDisabled] = useState(false);
  const { data } = useSWR<IRole>(`/role/${roleId}`);

  const handleDeleteRole = async () => {
    try {
      setDisabled(true);
      await deleteRoleFromEmployee(employeeId, roleId);
      mutate(`/employee/${employeeId}`);
      onRemoveDone && onRemoveDone();
    } catch (error) {
      console.log(error);
    } finally {
      setDisabled(false);
    }
  };

  if (!data) {
    return <></>;
  }

  return <Chip disabled={disabled} label={data?.name} onDelete={() => handleDeleteRole()} />;
}
