import { post } from "api";
import { IEmployee } from "./employee";

export interface IEntrance {
  id: string;
  EmployeeId: IEmployee;
  checkedInTime: number;
  checkedOutTime: number;
  duration: number;
  active: number;
  error: number;
}

export const checkedIn = (id: string) => {
  return post(`/employee/${id}/entrance/checkedin`);
};

export const checkedOut = (id: string) => {
  return post(`/employee/${id}/entrance/checkedout`);
};
