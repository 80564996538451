import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage10({data,pageNumber}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    ProductDiscontinuance:
      "Seller will give as much notification as possible in the event of discontinuing product, however, Seller reserves the right to discontinue production of any product at any time without notice except for that quantity of product for which Seller has received and acknowledged a Purchase Order from Buyer and has scheduled such product for shipment within six (6) months of the date of such acknowledgement.",
    CancellationBuyer:
      "Orders for standard product may be cancelled on sixty (60) days prior to the scheduled ship date by written notice to Seller. Orders for products which are not listed in Seller’s current catalogue (including but not limited to, semi-custom application specific products, or other semi-custom product(s) which have special markings, or which have received special testing or which are specially programmed for Buyer) may not be cancelled or returned except under the provisions of a prior written agreement between Seller and Buyer which sets forth the cancellation charges to be paid by Buyer in the vent of such cancellation. If such an agreement is not in place, the charges shall be 100%. Any money paid in advance is refundable at DSPM discretion.",
    PropertyRights:
      "The design, development or manufacture by Seller of a product for Buyer shall not be deemed to produce a work made for hire and shall not give to Buyer any copyright interest in the product or any interest in all or any portion of the mask works relating to the product. All such rights remain with the property of the Seller including models, drawings, composites, patterns, dies molds, masks and any other tools made for or obtained for furnishing the products hereunder.",
    Special:
      "INDEPENDENTLY OF ANY OTHER LIMITATION HEREOF AND REGARDLESS OF WHETHER THE PURPOSE OF SUCH LIMITATION IS SERVED, IT IS AGREED THAT IN NO EVENT SHALL SELLER BE LIABLE FOR SPECIAL, INCIDENTAL, CONSEQUENTIAL OR INDIRECT DAMAGES.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px]">Product Discontinuance:</span>
            <span className="font-light text-[16px]">{texts.ProductDiscontinuance}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px]">Cancellation of Buyer:</span>
            <span className="font-light text-[16px]">{texts.CancellationBuyer}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px]">Property Rights:</span>
            <span className="font-light text-[16px]">{texts.PropertyRights}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px]">
              Special, Incidental, Consequential, or Indirect Damages:
            </span>
            <span className="font-light text-[16px]">{texts.Special}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
