import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import Webcam from "react-webcam";

import CheckedOutModal from "./checkOutModal";
import CheckedInModal from "./checkinModal";

import Toast from "app/Toast";
import Button from "app/Button";
import QRScanner from "app/QRScanner";
import { Gradients } from "theme";
import Confirm from "common/Confirm";

import { postAttendance, postCheckout } from "api/attendance";

export default function AttendanceForm() {
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);

  const [userId, setUserId] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [scanDisabled, setScanDisabled] = useState(false);

  const webcamRef = useRef<Webcam>(null);

  const handleSubmit = useCallback(
    async (image: Blob, overrideUserId?: string) => {
      try {
        setIsSubmitting(true);
        await postAttendance({ userId: overrideUserId || userId, photo: image });

        setOpenCheckIn(true);
      } catch (error: any) {
        console.log(error);
        if (error?.response?.data?.error === "entrance already checked in") {
          Confirm({
            text: "Do you want to checkout?",
            confirmText: "Yes",
            async onConfirm() {
              try {
                await postCheckout({ userId: overrideUserId || userId, photo: image });

                setOpenCheckOut(true);
              } catch (error) {
                console.log(error);
              }
            },
          });
        } else if (error?.response?.data?.error === "employee not found") {
          Toast("Employee not found, Please try again.", "error");
        }
      } finally {
        setIsSubmitting(false);
      }
    },
    [userId]
  );

  const capture = React.useCallback(
    async (overrideUserId?: string) => {
      try {
        const imageSrc = webcamRef.current?.getScreenshot();
        if (imageSrc) {
          const response = await fetch(imageSrc);
          const imageBlob = await response.blob();

          handleSubmit(imageBlob, overrideUserId);
        }
      } catch (error) {
        Toast("An error on capturing image happened, Please try again.", "error");
      }
    },
    [handleSubmit]
  );

  useEffect(() => {
    let t: ReturnType<typeof setTimeout>;

    if (scanDisabled) {
      t = setTimeout(() => {
        setScanDisabled(false);
      }, 15000);
    }

    return () => clearTimeout(t);
  }, [scanDisabled]);

  return (
    <>
      <CheckedInModal open={openCheckIn} onClose={() => setOpenCheckIn(false)} />
      <CheckedOutModal open={openCheckOut} onClose={() => setOpenCheckOut(false)} />
      <Typography sx={{ color: "#FFFFFF", mb: 2 }}>Personal QR Code:</Typography>
      <Box position="relative">
        <div style={{ position: "absolute", zIndex: -1 }}>
          <Webcam
            audio={false}
            width="100%"
            height={480}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              width: 1280,
              height: 720,
              facingMode: "user",
            }}
          />
        </div>
        {scanDisabled ? (
          <CircularProgress />
        ) : (
          <QRScanner
            width="100%"
            height="100%"
            onResult={(r) => {
              try {
                if (!r) {
                  return;
                }
                const splited = r.split("/");
                const userId = splited[splited.length - 1];
                setScanDisabled(true);
                setUserId(userId);
                capture(userId);
              } catch (error) {
                console.log(error);
              }
            }}
          />
        )}
      </Box>
      <Divider sx={{ color: "#FFFFFF", my: 3 }}>OR</Divider>
      <TextField
        fullWidth
        placeholder="Personal Code"
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        style={{ width: "100%", background: "#FFF", margin: "0.5em 0" }}
      />
      <Button
        fullWidth
        disabled={isSubmitting}
        dialogMode
        variant="contained"
        color="primary"
        style={{ margin: "2em 0", background: Gradients.success }}
        onClick={() => {
          if (!userId) {
            Toast("Please enter your User ID", "error");
            return;
          }

          capture();
        }}
      >
        Register
      </Button>
    </>
  );
}
