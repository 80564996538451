import React, { useState } from "react";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";

import { ReactComponent as NarrowIcon } from "assets/icons/tableIcons/narrowDown.svg";

import ChatAdapter, { userType } from "logic/Socket/ChatAdapter";

const colors = {
  textColor: "#484848",
  main: "#ffff",
  secondary: "#f9fafc",
  light: "#afbbc7",
  highlight: "#0b97d4",
};

export default function EmployeeList({
  value,
  onChange,
  users,
  socket,
}: {
  socket: ChatAdapter;
  users: userType[];
  value?: string;
  onChange: (nu: userType) => void;
}) {
  const { username } = (socket.getSocketAuth() as any) || "";
  const [search, setSearch] = useState<string>();

  const usersList = search ? users.filter((u) => u.username.toLowerCase().includes(search.toLocaleLowerCase())) : users;

  return (
    <Box m={1} height={660}>
      <Box
        display="flex"
        alignItems="center"
        style={{ backgroundColor: colors.secondary, padding: "4px 8px" }}
        border="2px solid #eaedf1"
        borderRadius={4}
      >
        <InputBase
          placeholder="Search here..."
          style={{ flex: 1 }}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <IconButton>
          <NarrowIcon />
        </IconButton>
      </Box>
      <List>
        {usersList.map(
          (user) =>
            user.username !== username && (
              <ListItem
                key={user.userID}
                onClick={() => onChange(user)}
                sx={{
                  backgroundColor: colors.secondary + " !important",
                  border: "2px solid #eaedf1",
                  borderRadius: 4,
                  my: 1,
                }}
              >
                <ListItemAvatar>
                  <Badge color="primary" variant="dot" invisible={!Boolean(user.hasNewMessages)}>
                    <Avatar>{user.username[0]}</Avatar>
                  </Badge>
                </ListItemAvatar>
                <ListItemText>
                  <Typography>{user.username}</Typography>
                </ListItemText>
              </ListItem>
            )
        )}
      </List>
    </Box>
  );
}
