import React from "react";
import { Box, Button, CircularProgress } from "@mui/material";
import useSWR from "swr";
import Swal from "sweetalert2";

import { delete_ } from "api";
import { photoType } from "api/photo";
import { getDocumentUrl } from "api/document";
import { getId } from "logic/utils";

export default function Photos({
  imageBaseUrl,
  url,
  disabled,
}: {
  imageBaseUrl?: string;
  url: string;
  disabled?: boolean;
}) {
  const { data, mutate, isLoading } = useSWR<photoType[]>(url);

  return (
    <Box display="flex" flexWrap="wrap" alignItems="center" gap={2} justifyContent={"center"}>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          {data?.map((i) => (
            <Button
              key={getId(i)}
              onClick={() => {
                Swal.fire({
                  title: "Warning",
                  text: "Are you sure you want to delete this image?",
                  confirmButtonText: "Delete",
                  showCancelButton: true,
                }).then(async (r) => {
                  if (r.isConfirmed) {
                    await delete_(`/photo/${getId(i)}`);
                    mutate();
                  }
                });
              }}
              disabled={disabled}
            >
              <img
                src={getDocumentUrl((imageBaseUrl || "") + i.path)}
                alt=""
                style={{ minWidth: 40, maxWidth: "140px", height: "auto" }}
              />
            </Button>
          ))}
        </>
      )}
    </Box>
  );
}
