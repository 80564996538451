import React from "react";
import ReactQRCode from "qrcode.react";

export default function QRCode({
  value,
  height,
  width,
}: {
  value: string;
  width?: string | number;
  height?: string | number;
}) {
  return <ReactQRCode width={width} height={height} value={value} style={{ width, height }} />;
}
