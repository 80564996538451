import React, { useEffect, useState } from "react";
import { Button, Badge, IconButton, useMediaQuery } from "@mui/material";
import { useSocket } from "logic/Socket/Provider";
import NotificationMenu from "./Menu";
import notif from "assets/icons/header/Notification.svg";
import useSWR from "swr";
import { useSession } from "features/Session/sessionsSlice";
import { get } from "api";
import { notificationType } from "api/notification";

export default function NotificationButton({ click, onClick }: { click?: boolean; onClick?: () => void }) {
  const phone = useMediaQuery("(max-width:600px)");
  const [showDot, setShowDot] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // const { notification } = useSocket();
  const user = useSession();
  const { data } = useSWR<{ result: notificationType[]; total: number; unread: number }>(
    user?.id ? `/employee/${user.id}/notification` : null,
    get,
    {
      errorRetryCount: 2,
      refreshInterval: 30000,
      revalidateOnReconnect: true,
    }
  );

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDot(false);
    onClick && onClick();
    setAnchorEl(e.currentTarget);
  };

  return (
    <>
      {phone ? (
        <Button
          sx={{ color: click ? "#E68031" : "#000", justifyContent: "space-between" }}
          fullWidth
          onClick={handleOpenMenu}
          endIcon={<img alt="notification" src={notif} style={{ width: "18px" }} />}
        >
          Notification
        </Button>
      ) : (
        <IconButton size="small" title="Notifications" color="inherit" onClick={handleOpenMenu}>
          <Badge variant="dot" color="error" invisible={!data?.unread}>
            <img alt="notification" src={notif} style={{ width: 28, height: 28 }} />
          </Badge>
        </IconButton>
      )}
      <NotificationMenu open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={() => setAnchorEl(null)} />
    </>
  );
}
