import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import FileUploader from "app/UploadButton";
import { post } from "api";
import { toast } from "react-toastify";
import useSWR from "swr";
import { host } from "host";

export default function HomePage() {
  const [file, setFile] = useState<File | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { data, isLoading } = useSWR(`/document/website/homepage-1`);

  const handleUploadVideo = () => {
    if (file) {
      setIsSubmitting(true);
      const data = new FormData();
      data.append("document", file);
      post(`/document/website/homepage-1`, data)
        .then(() => toast.success("video uploaded successfully"))
        .finally(() => setIsSubmitting(false));
    } else {
      console.error("No file selected");
    }
  };
  const handleVideoUpload = (event: ChangeEvent<HTMLInputElement>) => {
    console.log({ event });
    const file = event.target.files?.[0];
    if (file) {
      setFile(file);
    }
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: "3rem" }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              borderRadius: "1rem",
              border: "1px solid gray",
              p: "1.5rem",
              mt: "2rem",
              mx: "2rem",
            }}
          >
            <Typography
              sx={{
                mt: "-2.4rem",
                fontSize: "15px",
                fontWeight: 700,
                bgcolor: "#FFF",
                width: "11rem",
                mb: "1rem",
                pl: "0.4rem",
              }}
            >
              Home Page Video
            </Typography>

            <video controls>
              <source src={host + data?.result[0]?.path} type="video/mp4" />
            </video>

            <Box sx={{ display: "flex", mt: "2rem", width: "20rem" }}>
              <FileUploader
                onChange={handleVideoUpload}
                onError={(err) => toast.error(err)}
                allowedTypes={["video"]}
                maxSize={5}
                title={data?.result?.length !== 0 ? "choose new video" : "choose video"}
              />
            </Box>
            <Button
              disabled={!file || isSubmitting}
              onClick={handleUploadVideo}
              sx={{ width: "20rem", mt: "2rem" }}
              variant="contained"
            >
              {isSubmitting ? "Uploading..." : "Upload"}
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
}
