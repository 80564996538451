import Footer from "./footer/Footer";
import Header from "./header/Header";
export default function POpage9({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={9} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content() {
  const contactData = {
    address: "1921 So. Quaker Ridge PlaceOntario, CA 91761",
    voice: "(714) 970-2304",
    email: "customersupport@dspmanufacturing.com",
  };

  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };
  const noteBox =
    "All DSPM Inc. customer service forms must be returned within 48hrs of service. If customer service forms are not received, DSPM will hold all invoices and delay processing until all customer service reports have been received. ****Please Make sure to call DSPM once on-site and speak to a tech for instructions M-F 8am to 4pm PST. If a Notes:";
  const redNote =
    "Any product that requires a data sheet, drawings, or specs, needs to be made to the DSPM specifications provided on documents or it will not be accepted";
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
        <div className="border border-table p-[2%]">
          <div className="flex flex-col">
            <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Vendor:</span>
            <span className="text-[12px]  ml-[1%] text-black">
              *************PLEASE READ*********************** ****
            </span>
            <span className="text-[14px] ml-[1%] text-black">
              {noteBox}
              {/* {insertLineBreaksAfterWords(noteBox, 20)} */}
            </span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
            <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-row">
            <span className="text-[14px] font-bold ml-[1%] text-[#fb5959]">Note:</span>
            <span className="text-[14px]  ml-[1%] text-[#fb5959]">{redNote}</span>
          </div>
        </div>
        <div className="mt-auto">
          <div className="flex flex-col w-full">
            <div className="flex-row flex">
              <span className="text-[16px]">{contactData.address}</span>
            </div>
            <div className="flex-row flex  mt-[1%]">
              <span className="text-[16px] me-[1%]">Voice:</span>
              <span className="text-[16px]">{contactData.voice}</span>
            </div>
            <div className="flex-row flex mt-[1%]">
              <span className="text-[16px] me-[1%]">Email: </span>
              <span className="text-[16px]">{contactData.email} </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
