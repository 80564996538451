// import SectionSvg from "../../../assets/svg/triangle.svg?react";
import { ReactComponent as SectionSvg } from "../../../assets/svg/triangle.svg";

export default function SectionHeader2({ title }) {
  return ( 
    <div className="flex flex-row w-full items-center">
      <SectionSvg className='w-auto h-full'/>
      <span className="text-[20px] font-bold text-primaryBlue ml-4">{title}</span>
    </div>
   );

}