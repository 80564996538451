import React, { useState } from "react";

import useLock from "./hooks";
import { ErrorBoundary } from "react-error-boundary";
import lockIcon from "assets/lock.svg";
import Close from "assets/Close.svg";
import { Box, CircularProgress, IconButton } from "@mui/material";
import PasswordModal from "./PasswordModal";

export default function LockButton({
  onSubmit,
  disabled,
  sx,
  loading,
  mb,

  needUnlockPassword,
}: {
  onSubmit?: () => void;
  disabled?: boolean;
  sx?: any;
  loading?: boolean;
  mb?: boolean;

  needUnlockPassword?: boolean;
}) {
  const { lock, setLock } = useLock();
  const [open, setOpen] = useState(false);

  return (
    <>
      <PasswordModal
        open={open}
        onClose={() => setOpen(false)}
        onSubmit={() => {
          setLock((p) => !p);
        }}
      />
      <div className="lockButton">
        {lock ? (
          <Box
            mb={1}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "start",
            }}
          >
            <IconButton
              sx={{ p: 0, opacity: disabled ? 0.7 : 1, ...sx }}
              onClick={() => {
                if (needUnlockPassword) {
                  setOpen(true);
                } else {
                  setLock((p) => !p);
                }
              }}
              disabled={disabled}
            >
              <img alt="lock" src={Close} />
            </IconButton>
            {loading && <CircularProgress size="1.5rem" sx={{ ml: 1 }} />}
          </Box>
        ) : (
          <ErrorBoundary fallback={<div>Something went wrong</div>}>
            <Box sx={{ mb: mb ? 1 : "unset" }}>
              <IconButton
                sx={{ p: 0, opacity: disabled ? 0.7 : 1, ...sx }}
                onClick={() => {
                  setLock((p) => !p);
                  onSubmit && onSubmit();
                }}
                disabled={disabled}
              >
                <img alt="lock" src={lockIcon} />
              </IconButton>
            </Box>
          </ErrorBoundary>
        )}
      </div>
    </>
  );
}
