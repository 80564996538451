import React from "react";
import { Dialog, DialogTitle, Box, IconButton, DialogProps, DialogContent } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";

interface IDialog extends DialogProps {
  open: boolean;
  onClose: () => void;
  title: any;
  closeOnClickOut?: boolean;
  disabled?: boolean;
}

export default function MyDialog({ closeOnClickOut, open, title, onClose, ...props }: IDialog) {
  return (
    <Dialog
      open={open}
      onClose={closeOnClickOut === undefined || closeOnClickOut ? onClose : () => {}}
      {...props}
      scroll="paper"
    >
      <Box
        id="my-dialog-header"
        style={{
          boxShadow: "0px 1px 3px 0px #00000026",
        }}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1}
      >
        <DialogTitle sx={{ maxWidth: 400, fontSize: title && title?.length > 30 ? 10 : 12, fontWeight: 500 }}>
          {title}
        </DialogTitle>
        <div style={{ flexGrow: 1 }} />
        <IconButton
          title="Close"
          sx={{
            background: "#EEEFF2",
            borderRadius: 2,
            "& .MuiButtonBase-root ": {
              padding: "4px",
            },
          }}
          onClick={onClose}
          disabled={props.disabled}
        >
          <CloseRounded sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>

      <DialogContent dividers={true} sx={{ border: 0 }}>
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
