import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import ItemTable from "./ItemTable";
import SectionHeader from "./SectionHeader";
import { formatCurrency } from "logic/utils";
import { formatTimestampToDate } from "logic/date";
import { Fragment } from "react";

export default function QuotePageX({ data, items, pageNumber, QrCode, page, sectionNumbers }) {
  return (
    <div className="flex flex-col items-center h-full w-full mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} items={items} QrCode={QrCode} page={page} sectionNumbers={sectionNumbers} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

const Content = ({ data, items, QrCode, page, sectionNumbers }) => {
  const SectionTitle = (item) => {
    if (item.freight) return "Freight";
    if (item?.ItemId) return item.ItemId.no;
    else return "Service Program Options";
  };
  return (
    <div className="flex flex-col items-center h-[98%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        {page === 0 && (
          <>
            <div className="mt-[2%]">
              <div className="flex flex-row w-full justify-between">
                <div className="flex flex-col justify-center">{QrCode}</div>
                <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
                  <div>
                    <span className="text-[14px]">Agency:</span>
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.rep ? data.rep : "-"}</span>
                  </div>
                </div>
                <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
                  <div>
                    <span className="text-[14px]">Requested:</span>
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.requestedByName ? data.requestedByName : "-"}</span>
                  </div>
                  <div className="mt-[4%]">
                    <span className="text-[14px]">By:</span>
                  </div>
                  <div>
                    {data.requestedByEmail ? (
                      splitEmails(data.requestedByEmail).length === 0 ? ( // Check if there are no emails
                        <div>
                          <span className="text-[14px] font-light">-</span>
                        </div>
                      ) : (
                        splitEmails(data.requestedByEmail).map((email, index) => (
                          <div key={index}>
                            <span className="text-[14px] font-light">{email}</span>
                          </div>
                        ))
                      )
                    ) : (
                      "-"
                    )}
                  </div>
                  <div>
                    <span className="text-[14px] font-light">{data.requestedByPhone ? data.requestedByPhone : ""}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-[2%] bg-[#6a6a6a]">
              <div className="flex flex-row p-3">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-row h-full">
                    <div className="flex flex-col justify-around">
                      <div className="p-2">
                        <span className="text-white text-[12px] font-light">Quote # :</span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px] font-light">Prepared On:</span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px] font-light">Expires:</span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px] font-light">Lead Time:</span>
                      </div>
                    </div>
                    <div className="flex flex-col ml-[4%] justify-around">
                      <div className="p-2">
                        <span className="text-white text-[12px]">{data.number ? data.number : "-"}</span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px]">
                          {data.entryDate ? formatTimestampToDate(data.entryDate) : "-"}
                        </span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px]">
                          {data.expireDate ? formatTimestampToDate(data.expireDate) : "-"}
                        </span>
                      </div>
                      <div className="p-2">
                        <span className="text-white text-[12px]">{data.leadTime ? data.leadTime : "-"}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col flex-1">
                  <div className="flex flex-row">
                    <div className="flex flex-col">
                      <div>
                        <span className="text-white text-[12px] font-light">Ship Via:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Freight Terms:</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-light">Payment Terms:</span>
                      </div>
                    </div>
                    <div className="flex flex-col ml-[4%] ">
                      <div>
                        <span className="text-white text-[12px]">-</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px]">-</span>
                      </div>
                      <div>
                        <span className="text-white text-[12px] font-bold">
                          {data.paymentTerms ? insertLineBreaksAfterWords(data.paymentTerms, 6) : "-"}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {items.map((item, index) => (
          <Fragment key={index}>
            <div className="mt-[4%]">
              {item.sort === 1 ? (
                <SectionHeader
                  title={SectionTitle(item)}
                  number={page === 0 ? index + 1 : sectionNumbers[page - 1] + index + 1}
                />
              ) : (
                <></>
              )}
            </div>
            <div className="">
              <ItemTable
                groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
                description={item.description ? extractDescriptionParts(item.description) : null}
                qty={item.qty}
                unitPrice={formatCurrency(item.price)}
                totalPrice={formatCurrency(item.total)}
                descriptionElement={null}
              />
            </div>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

function extractDescriptionParts(description) {
  // Check if the description contains any of the delimiters
  if (
    !description.includes("*") &&
    !description.includes(";") &&
    !description.includes(",") &&
    !description.includes("⚭")
  ) {
    return [description.trim()];
  }

  // Split the description into lines based on the delimiters: *, ;, or ,
  // const lines = description.split(/[\*\;\,]\s*/);
  const lines = description.split(/[\*\;\,\⚭]\s*/);

  // Trim each line and filter out any empty lines
  return lines.map((line) => line.trim()).filter((line) => line.length > 0);
}

const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
  const words = text.split(" "); // Split the text into words
  const elements = []; // Array to hold React elements

  words.forEach((word, index) => {
    elements.push(<span key={index}>{word} </span>); // Push each word as a span

    // After every 'wordsPerBreak', add a line break
    if ((index + 1) % wordsPerBreak === 0) {
      elements.push(<br key={`break-${index}`} />); // Push a line break
    }
  });

  return elements; // Return the array of React elements
};

const splitEmails = (emails) => {
  if (!emails) return []; // Return an empty array if the input is empty or null

  // Use a regex to extract email patterns
  const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

  // Check if the email string contains semicolons
  if (emails.includes(";")) {
    return emails
      .split(";")
      .flatMap((email) => email.match(emailPattern) || []) // Use regex match to find emails
      .filter((email) => email.length > 0); // Filter out empty entries
  } else {
    // If semicolon is not present, split by whitespace or commas
    return emails.match(emailPattern) || []; // Use regex match to find emails
  }
};
