import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { IEmployee, updateEmployee, addRoleToEmployee, addEmployee, deleteEmployee } from "api/employee";
import { getId } from "logic/utils";
import LinkField from "app/Inputs/LinkFields";
import Button from "app/Button";
import Toast from "app/Toast";
import AsyncCombo from "common/AsyncCombo";
import TextField from "app/TextField";
import ConfirmModal from "../Confirm";

const schema = Yup.object().shape({
  username: Yup.string().required().min(3),
});

export default function GeneralForm({
  onClose,
  initialVals,
  setRefresh,
  setTab,
}: {
  initialVals?: IEmployee;
  setRefresh: any;
  setTab?: any;
  onClose: () => void;
}) {
  const [openDelete, setOpenDelete] = useState(false);
  const handleSubmit = async (data: any, { setSubmitting }: { setSubmitting: (v: boolean) => void }) => {
    try {
      setSubmitting(true);

      const DepartmentId = data.DepartmentId?.map((d: any) => (typeof d === "string" ? d : getId(d)));
      data.roles = [data.role];

      if (initialVals) {
        await updateEmployee(initialVals.id, {
          firstName: data.firstName,
          lastName: data.lastName,
          username: data.username,
          DepartmentId,
          email: data.email,
        });
        if (data.role) {
          await addRoleToEmployee(initialVals.id, data.role);
        }

        setRefresh((p: any) => p + 1);
        onClose();
      } else {
        await addEmployee({ ...data, DepartmentId });

        setRefresh((p: any) => p + 1);
        onClose();
      }
    } catch (error) {
      console.log(data);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      if (initialVals && initialVals.id) await deleteEmployee(initialVals?.id);
      Toast("Employee deleted", "success");
    } catch (error) {
      console.log(error);
    } finally {
      setRefresh((p: any) => p + 1);
      onClose();
    }
  };

  return (
    <>
      {openDelete && (
        <ConfirmModal open={openDelete} onClose={() => setOpenDelete(false)} onConfirm={handleDelete} text="" />
      )}
      <Formik
        initialValues={initialVals ? initialVals : ({ username: "" } as IEmployee)}
        validationSchema={schema}
        onSubmit={handleSubmit}
      >
        {({ values, touched, errors, isSubmitting, setFieldValue, getFieldProps, handleSubmit }) => (
          <Form>
            <Box display="grid" gridTemplateColumns="1fr" gap={2}>
              <TextField
                label="Username"
                placeholder="username"
                {...getFieldProps("username")}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
              {!initialVals && (
                <>
                  <TextField
                    label="Password"
                    type="password"
                    {...getFieldProps("password")}
                    error={Boolean(errors.password && touched.password)}
                    helperText={touched.username && errors.password}
                  />
                </>
              )}
              <TextField
                label="Email"
                type="email"
                {...getFieldProps("email")}
                error={Boolean(errors.email && touched.email)}
                helperText={errors.email}
              />
              <TextField
                label="First Name"
                {...getFieldProps("firstName")}
                error={Boolean(errors.firstName && touched.firstName)}
                helperText={errors.firstName}
              />
              <TextField
                label="Last Name"
                {...getFieldProps("lastName")}
                error={Boolean(errors.lastName && touched.lastName)}
                helperText={errors.lastName}
              />
              <AsyncCombo
                label="Department"
                url="/department"
                filterBy="name"
                getOptionLabel={(o) => o.name || "No_Name"}
                getOptionSelected={(o, v) => getId(o) === getId(v)}
                value={values.DepartmentId || []}
                onChange={(e, v) => setFieldValue("DepartmentId", v)}
                multiple
                error={Boolean(errors.DepartmentId && touched.DepartmentId)}
              />
              {!initialVals && (
                <LinkField
                  label="Role"
                  filterLabel="name"
                  getOptionLabel={(i) => i?.name || ""}
                  getOptionList={(r) => r?.result || []}
                  getOptionValue={(i) => i.id}
                  path="/role"
                  choseItem={values.role}
                  value={values.role}
                  onChange={(e, nv) => {
                    setFieldValue("role", nv.id);
                  }}
                />
              )}
              {initialVals && (
                <>
                  <Button variant="outlined" onClick={() => setTab(1)}>
                    manage roles
                  </Button>
                  <Button variant="outlined" onClick={() => setTab(2)}>
                    change password
                  </Button>
                </>
              )}
              {!initialVals && (
                <Box display={"flex"} justifyContent={"center"}>
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button dialogMode disabled={isSubmitting} type="button" onClick={() => handleSubmit()} kind="add">
                      Submit
                    </Button>
                  )}
                </Box>
              )}
              {initialVals && (
                <Box display={"flex"} alignItems="center" justifyContent={"space-evenly"} gap={2}>
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      fullWidth
                      dialogMode
                      disabled={isSubmitting}
                      type="button"
                      onClick={() => handleSubmit()}
                      kind="edit"
                    >
                      Save
                    </Button>
                  )}
                  <Button
                    fullWidth
                    dialogMode
                    disabled={isSubmitting}
                    onClick={() => setOpenDelete(true)}
                    kind="delete"
                  >
                    Delete
                  </Button>
                </Box>
              )}
            </Box>
          </Form>
        )}
      </Formik>
    </>
  );
}
