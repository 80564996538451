import { formatCurrency } from "logic/utils";
function ItemTable({
  // groupLine,
  // description,
  // qty,
  // unitPrice,
  // totalPrice,
  // descriptionElement,
  Items,
}) {
  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="text-left p-2">
            <div className="">
              <span className="text-[10px] text-primaryOrange">
                [Group]-Line
              </span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Item NO./Description</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Qty</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Unit Price</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Total Price</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {Items.map((item, index) => (
          <tr key={index}>
            <td className="text-left p-2 align-top">
              <div>
                <span className="text-[10px]">
                  {item.group && item.sort
                    ? `[${item.group}]-${item.sort}`
                    : ""}
                </span>
              </div>
            </td>
            <td className="text-left p-2 align-top w-[60%]">
              {item.description
                ? extractDescriptionParts(item.description)
                : null}
              {/* {descriptionElement != null
                ? descriptionElement
                : description.map((item, index) => {
                    return (
                      <div key={index}>
                        <span className="text-[11px] font-light">
                          . {item.description}
                        </span>
                      </div>
                    );
                  })} */}
            </td>
            <td className="text-left p-2 align-top">
              <div>
                <span className="text-[10px]">{item.qty ? item.qty : ""}</span>
              </div>
            </td>
            <td className="text-left p-2 align-top">
              <div>
                <span className="text-[10px] font-light">
                  {item.price ? formatCurrency(item.price) : ""}
                </span>
              </div>
            </td>
            <td className="text-left p-2 align-top">
              <div>
                <span className="text-[10px] font-light">
                  {item.total ?formatCurrency(item.total) : ""}
                </span>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default ItemTable;

function extractDescriptionParts(description) {
  // Check if the description contains any of the delimiters
  if (
    !description.includes("*") &&
    !description.includes(";") &&
    !description.includes(",") &&
    !description.includes("⚭")
  ) {
    return [description.trim()];
  }

  // Split the description into lines based on the delimiters: *, ;, or ,
  // const lines = description.split(/[\*\;\,]\s*/);
  const lines = description.split(/[\*\;\,\⚭]\s*/);

  // Trim each line and filter out any empty lines
  return lines.map((line) => line.trim()).filter((line) => line.length > 0);
}
