// import FooterLogo from '../../../assets/svg/footer1.svg?react';
import { ReactComponent as FooterLogo } from '../../../../assets/svg/footer1.svg'

function Footer({ pageNumber, DocNumber }) {
  return (
    <div className="relative w-full">
      <FooterLogo className='h-auto w-full' />
   
        <div className="absolute left-[2%] bottom-[25%] flex items-center">
          <span className="font-bold text-white text-[16px]">{pageNumber}</span>
        </div>
        <div className="absolute left-[12%] bottom-[20%] flex items-center">
          <span className=" text-white text-[16px]">1.877.377.6769</span>
        </div>
        <div className="absolute left-[32%] bottom-[25%] flex items-center">

          <span className=" text-white text-[16px]">Quote@dspmanufacturing.com</span>
        </div>
        {/* Document Info */}
        <div className="absolute right-5 bottom-[25%] flex items-center">
          <span className=" text-slate-500 text-[16px]">Quote NO:</span>
          <span className=" text-white ml-1 text-[16px]">{DocNumber}</span>
        </div>
      </div>
  );
}

export default Footer;
