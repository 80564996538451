import React, { forwardRef } from "react";
import useSWR from "swr";
import { ILineItem } from "api/lineItem";

const columns = [
  { header: "Line", get: (r: any, idx: number) => idx + 1, color: "#e70202", flex: 1 },
  { header: "Item No", get: (r: any, idx: number) => r?.ItemId?.no, color: "#6a6a6a", flex: 3 },
  { header: "Item Name", get: (r: any, idx: number) => r?.ItemId?.name, color: "#6a6a6a", flex: 3 },
  {
    header: "Qty",
    get: (r: any, idx: number) => r?.quantityDelivered || r.quantity,
    color: "#6a6a6a",
    flex: 1,
  },
  {
    header: "U / M",
    get: (r: any, idx: number) => r?.ItemId?.uom,
    color: "#6a6a6a",
    flex: 1,
  },
  {
    header: "Total",
    get: (r: any, idx: number) => r?.totalfifo,
    color: "#6a6a6a",
    flex: 1,
  },
  {
    header: "Amount",
    get: (r: any, idx: number) => r?.qty * r?.totalfifo,
    color: "#6a6a6a",
    flex: 1,
  },
];

const IssueLineItems = forwardRef<HTMLDivElement, { issueId?: string; partflowId?: string }>(
  ({ issueId, partflowId }, ref) => {
    const { data: lines } = useSWR<{ result: ILineItem[]; total: number }>(
      issueId ? `/goodslineitem?GoodsIssueId=${issueId}` : `/requestlineitem?PartRequestId=${partflowId}`
    );

    return (
      <div ref={ref} style={{ minHeight: 600 }}>
        {lines?.result?.map((l: any, index: number) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 8,
              borderBottom: "2px solid #E1E1E1",
              minHeight: "150px",
              width: "20.5cm",
            }}
          >
            {columns.map((c, idx) => (
              <div
                key={c.header}
                style={{
                  padding: "8px 0",
                  flex: c.flex,
                  verticalAlign: "middle",
                  fontWeight: "bold",
                  textAlign: c?.header === "Description" ? "justify" : "center",
                }}
                dangerouslySetInnerHTML={{ __html: String(c.get(l, idx) || "").replaceAll("\r", "<br />") }}
              />
            ))}
          </div>
        ))}
      </div>
    );
  }
);

export default IssueLineItems;
