import Swal from "sweetalert2";

export default function Confirm({
  text,
  html,
  confirmText,
  showCancelButton,
  onCancel,
  onConfirm,
}: {
  html?: string;
  text?: string;
  confirmText?: string;
  showCancelButton?: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
}) {
  Swal.fire({
    title: "Are You Sure?",
    text,
    html: html,
    icon: "warning",
    showCancelButton: showCancelButton ? false : true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: confirmText ? confirmText : "Yes, delete it!",
  })
    .then((result) => {
      if (result.isConfirmed) {
        onConfirm();
      } else if (result.isDismissed || result.isDenied) {
        onCancel && onCancel();
      }
    })
    .catch((e) => console.log("error from sweetalert2", e));
}
