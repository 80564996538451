import { patch, get, post, delete_ } from ".";

import { IItem } from "./items";
import { ISO } from "./so";
import { categoryType, typeType } from "./type";

export interface IUnit {
  id: string;
  recId: string;

  price: number;
  cost: number;

  SOId: ISO;
  so?: string;
  ItemId: IItem;
  JOBId: string;
  number: string;
  serial: string;
  CategoryId: categoryType;
  TypeId: typeType;
  name: string;
  note: string;
  deviceType: string;
  manufacturer: string;
  model: string;
  serviceTag: string;
  location: string;
  status: string;
  RepId: string;
  description: string;
  productFamily: string;
  unitModel: string;
  stage?: stageType;
  class: "option" | "device" | "fru";
  estimatedShipDate?: number;
  actualShipDate?: number;
  ClusterId: string;
}

export interface IUnitHistory {
  itemno: string;
  item: IItem;
  unit: IUnit;
  id: string;
  estimatedShipDate: string;
  actualShipDate: string;
  serialNumber: string;
  leadTime: string;
  actualLeadTime: string;
  status: string;
  warrantyStatus: string;
  warrantyEndDate: string;
  sonumber: string;
  soid: string;
  so: ISO;
  SODate: number;
  stage: stageType;
}

export interface stageType {
  UnitId: string;
  bomCheck: boolean;
  bomCheckedBy: { username: string };
  bomCheckNote: string;
  bomCheckDate: number;
  crossCheck: boolean;
  crossCheckedBy: { username: string };
  crossCheckNote: string;
  crossCheckDate: number;
  manufacturingCheck: boolean;
  manufacturingCheckedBy: { username: string };
  manufacturingCheckNote: string;
  manufacturingCheckDate: number;
  qcCheck: boolean;
  qcCheckedBy: { username: string };
  qcCheckNote: string;
  qcCheckDate: number;
  testCheck: boolean;
  testCheckedBy: { username: string };
  testCheckNote: string;
  testCheckDate: number;
  fieldStartupCheck: boolean;
  fieldStartupDate: number;
  fieldStartupStatus: "Not Started" | "Passed" | "Failed";
}

export type IOcbType = {
  timePeriod: {
    start: string;
    end: string;
  };
  id: string;
  name: string;
  schedule: "All Day" | "Time Period" | "NOF" | "NON" | "NOFH" | "NONH";
  status: boolean;
  serial: string;
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
  __v: number;
};

export type IOcbConfigType = {
  id: string;
  testMode: boolean;
  contactor: boolean;
  inverter: boolean;
  charger: boolean;
  systemMode: "online" | "standby";
  sbs: "off" | "utility" | "inverter";
  serial: string;
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
  __v: number;
};
export type configType = {
  testMode: boolean;
  contactor: boolean;
  inverter: boolean;
  charger: boolean;
  systemMode: "online" | "standby";
  sbs: "off" | "utility" | "inverter";
};

export type logType = {
  id: string;
  date: string;
  serial: string;
  result: string;
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
  __v: number;
};

export const updateConfig = (data: any) => {
  return patch(`/socb-config`, data);
};
export const editOcb = (data: Partial<IOcbType>, id: string) => {
  return patch(`/socb/${id}`, data);
};
export const addImage = (unitId: string, file: any) => {
  const data = new FormData();
  data.append("photo", file);
  return post(`/unit/${unitId}/photo`, data);
};
export const deleteImage = (unitId: string, data: any) => {
  return delete_(`/unit/${unitId}/photo`, null, data);
};

export const updateUnit = async (id: string, data: any) => {
  try {
    const formData = new FormData();
    if (data.dueDate) {
      let date = new Date(data.dueDate);
      data.dueDate = date.getTime();
    }
    if (data?.photos && data.photos.length > 0) {
      data.photos.forEach((p: any) => formData.append("photo", p));
    }

    await patch(`/unit/${id}`, { ...data, photos: undefined, id });

    if (data?.photos) {
      return await post(`/photo/unit/${id}`, formData);
    }
  } catch (error) {
    throw error;
  }
};

export const getAllUnits = () => {
  return get("/unit");
};

export const updateUnitBomCheck = (id: string, data: { check: boolean; note: string }, model: string) => {
  return patch(`/unit/${id}/${model}check`, data);
};

export const deleteUnitBomCheck = (id: string, model: string) => {
  return delete_(`/unit/${id}/${model}check`);
};

export type checkVariants = "manufacturing" | "qc" | "test" | "cross" | "bom" | "fieldStartup";

export const clearCheck = ({ id, model, variant }: { id: string; model: "unit"; variant: checkVariants }) => {
  return delete_(`/${model}/${id}/${variant}Check`);
};

export const updateCheck = (data: {
  id: string;
  model: "unit";
  variant: checkVariants;
  check: boolean;
  note: string;
}) => {
  return patch(`/${data.model}/${data.id}/${data.variant}Check`, { check: data.check, note: data.note });
};

export const duplicateDevice = (id: string, data: any) => {
  return post(`/device/${id}/duplicate`, data);
};
