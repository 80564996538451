import React, { useState } from "react";
import { MoreVertRounded } from "@mui/icons-material";
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Menu } from "@mui/material";

type ColumnType = {
  key: string;
  header: string;
  hide?: boolean;
};

export default function MoreButton({
  columns,
  onChange,
}: {
  columns: ColumnType[];
  onChange: (data: ColumnType[]) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const filteredColumns = columns?.filter((i) => !i.key.includes("action")) || [];
  console.log({ columns });
  const handleToggleVisible = (index: number, visible: boolean) => {
    const temp = columns.concat();
    if (temp[index + 1]) {
      temp[index + 1].hide = visible;
    }
    onChange(temp);
  };

  const handleToggleAll = (e: boolean): void => {
    const temp = columns.concat().map((i) => ({
      ...i,
      hide: e,
    }));

    onChange(temp);
  };

  return (
    <Box>
      <Button
        title="Columns"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 15,
          padding: "7px 0px",
          minWidth: "15px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgb(230,128,49)",
        }}
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertRounded />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} keepMounted>
        <Box style={{ width: 200, maxHeight: 300, overflowY: "auto" }}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <Checkbox checked={columns.every((i) => !i.hide)} onChange={(e, c) => handleToggleAll(!c)} />
              </ListItemIcon>
              <ListItemText>All</ListItemText>
            </ListItem>
            {columns
              ?.filter((i) => !i.key.includes("action"))
              .map(({ key, header, hide }, idx) => (
                <ListItem key={key}>
                  <ListItemIcon>
                    <Checkbox checked={!hide || false} onChange={(e) => handleToggleVisible(idx, !e.target.checked)} />
                  </ListItemIcon>
                  <ListItemText>{header || key || ""}</ListItemText>
                </ListItem>
              ))}
          </List>
        </Box>
      </Menu>
    </Box>
  );
}
