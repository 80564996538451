import React, { useRef, useState } from "react";
import { Box, IconButton, Popover, Typography } from "@mui/material";
import useSWR from "swr";
import LoadingButton from "common/LoadingButton";
import { useSession } from "features/Session/sessionsSlice";
import { getId } from "logic/utils";
import { checkedIn, checkedOut, IEntrance } from "api/entrace";
import { formatSeconds, formatTimesToHour } from "logic/date";
import CheckoutModal from "modules/Employee/CheckoutModal";
import checkIn from "assets/icons/header/Check_in.svg";
import checkOut from "assets/icons/header/Check_out.svg";

export default function CheckIn() {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkoutModal, setCheckoutModal] = useState(false);

  const session = useSession();
  const { data: entrance, mutate } = useSWR<{ result: IEntrance[]; total: number }>(
    getId(session) ? `/employee/${getId(session)}/entrance` : null
  );
  const hasActiveEntrance = entrance?.result.some((e) => e.active);
  const beforeDurationSum = entrance?.result.reduce((p, c) => (!c.active ? p + c.duration : p), 0) || 0;
  const lastActive = entrance?.result.find((e) => e.active);
  const total =
    (entrance?.result.reduce((p, c) => p + c.duration, 0) || 0) +
    Math.round(Number(new Date()) - (lastActive?.checkedInTime || 0));

  const toggleEntrance = async () => {
    try {
      setLoading(true);
      if (hasActiveEntrance) {
        await checkedOut(getId(session));
        setCheckoutModal(true);
      } else {
        await checkedIn(getId(session));
      }
      mutate();
      setOpen(false);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  let menuContent = <></>;
  if (hasActiveEntrance) {
    menuContent = (
      <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
        <Box sx={{ display: "flex", mx: 3 }}>
          {beforeDurationSum > 0 && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography sx={{ mt: 3, mb: 1, color: "grey" }}>
                Before {formatTimesToHour(lastActive?.checkedInTime)}
              </Typography>
              <Typography sx={{ textAlign: "center", fontWeight: 700, color: "#017e84" }}>
                {formatSeconds(Math.round(beforeDurationSum / 1000))}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
            <Typography sx={{ mt: 3, mb: 1, color: "grey" }}>
              Since: {formatTimesToHour(lastActive?.checkedInTime)}
            </Typography>
            <Typography sx={{ textAlign: "center", fontWeight: 700, color: "#017e84" }}>
              {formatSeconds(Math.round((Number(new Date()) - (lastActive?.checkedInTime || 0)) / 1000))}
            </Typography>
          </Box>
        </Box>
        <Box mt={2} display="flex" alignItems="center" justifyContent="center" width="80%">
          <Typography mr={1}>Total today:</Typography>
          <Typography fontWeight="bold">{formatSeconds(Math.round(total / 1000))}</Typography>
        </Box>
        <LoadingButton
          loading={loading}
          onClick={toggleEntrance}
          sx={{ mx: 3, my: 2, width: 150, background: "#02AE1E" }}
        >
          Check Out
        </LoadingButton>
      </Box>
    );
  } else {
    menuContent = (
      <Box width={200} height={70} display="flex" alignItems="center" justifyContent="center">
        <LoadingButton
          loading={loading}
          onClick={toggleEntrance}
          sx={{ mx: 3, my: 2, width: 150, background: "#02AE1E" }}
        >
          Check In
        </LoadingButton>
      </Box>
    );
  }

  return (
    <>
      <CheckoutModal open={checkoutModal} onClose={() => setCheckoutModal(false)} />
      <IconButton title={hasActiveEntrance ? "CheckIn" : "CheckOut"} ref={buttonRef} onClick={() => setOpen(true)}>
        {/* <Box sx={{ background: hasActiveEntrance ? "green" : "red", width: 15, height: 15, borderRadius: "50%" }}></Box> */}
        {hasActiveEntrance ? (
          <img src={checkIn} width={28} height={28} alt="checkIn" />
        ) : (
          <img src={checkOut} width={28} height={28} alt="checkout" />
        )}
      </IconButton>
      <Popover
        anchorEl={buttonRef.current}
        open={open}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {menuContent}
      </Popover>
    </>
  );
}
