import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage7({data,pageNumber}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    warranty:
      "For the applicable warranty period specified below, Seller warrants that the products to be delivered hereunder will be free from defects in materials and workmanship under normal use and service. The obligations of the Seller under this warranty are limited to replacing, repairing or giving credit for, at its option, any of said products which shall, within the warranty period be returned as provided herein to the Seller, transportation charges prepaid and which are, after examination, disclosed to the satisfaction of the Seller to be thus defective. Buyer acknowledges and agrees that the provisions of this warranty constitute the sole and exclusive remedy available to it with regard to said defective products. No agent, employee, or representative of Seller has any authority to bind Seller to any affirmation, representation or warranty relating to the products other than as specifically provided herein. The warranty provided herein is subject to the following conditions:",
    a: "If products become defective during the warranty period, Buyer shall notify Seller promptly in writing of any such claims and provide information pertinent to delivery dates of the product and/or date code information.",
    b: "If Seller advises Buyer to return the product for repair or replacement, Buyer will follow Seller's Material Return Authorization procedures. Any order returned after 90 days from the time the order arrived on site, will not get credit for the batteries.",
    c: "If product alleged by Buyer to be defective or returned to Seller for repair as provided in this section is either (I) not under warranty, or (ii) determined not to be defective, or (iii) defective due to any cause not covered under the warranty provided herein, Buyer agrees to reimburse Seller for all reasonable expenses incurred in traveling and/or shipping, handling, and inspection of such product.",
    d: "Products will be accepted by Seller for warranty claim verification only when returned by Buyer in a condition which allows for suitable testing by Seller.",
    e: "Seller shall reimburse Buyer for shipping charges to the extent of the percent of the total return that are found by Seller to be defective as specified herein. Reimbursement will be in the form of a credit adjustment to Buyer's account unless otherwise agreed upon.",
    f: "In no event shall Seller be liable for any defective products if examination discloses that the defective condition of such products was caused by misuse, abuse or improper installation, application, maintenance or repair assembly by other than Seller, alteration, accident or negligence in use, storage, transportation or handling outside of specified environmental conditions.",
  };

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="text-[16px] font-bold">Warranty and Sole Remedy:</span>
            <span className="text-[16px] font-light text-justify">{texts.warranty}</span>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">A.</span>
              <span className="text-[16px] font-light text-justify">{texts.a}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">B.</span>
              <span className="text-[16px] font-light text-justify">{texts.b}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">C.</span>
              <span className="text-[16px] font-light text-justify">{texts.c}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">D.</span>
              <span className="text-[16px] font-light text-justify">{texts.d}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">E.</span>
              <span className="text-[16px] font-light text-justify">{texts.e}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-[16px] text-primaryOrange">F.</span>
              <span className="text-[16px] font-light text-justify">{texts.f}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
