import Footer from "../Quote/footer/Footer";
import Header from "../PO/header/Header";
import { formatCurrency } from "logic/utils";
// import itemLines from "../../assets/data/SOLines.json"
import ItemTable from "./ItemTable";
export default function SOpagex({data,Items,pageNumber,page,lastPage}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} Items={Items} page={page} lastPage={lastPage}/>
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}
// console.log(itemLines);

function Content({data,Items,page,lastPage}) {
  return (
    <div className="flex flex-col items-center h-[98%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        {Items.length != 0 ? 
        <div>
          <ItemTable Items={Items}/>
        </div>
        : null}
        {lastPage === page ? 
        <div className="mt-[4%]">
        <div className="flex flex-col  items-center">
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 py-4 px-8 justify-between">
              <span className="text-white text-[16px]">SO Subtotal</span>
              <span className="text-white font-bold text-[16px]">{data.subtotal ? formatCurrency(data.subtotal) : '-'}</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-white text-[16px]">SO Subtotal Taxable:</span>
              <span className="text-white font-bold text-[16px]">{data.taxable ? formatCurrency(data.taxable) : '-'}</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-white text-[16px]">Discount Amount:</span>
              <span className="text-white font-bold text-[16px]">{data.discountAmount ? formatCurrency(data.discountAmount) : '-'}</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-white text-[16px]">Discount Rate</span>
              <span className="text-white font-bold text-[16px]">-</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-white text-[16px]">Sales Tax Rate</span>
              <span className="text-white font-bold text-[16px]">-</span>
            </div>
            <div className="flex flex-row bg-primaryOrange w-2/3 p-4 px-8 justify-between">
              <span className="text-white text-[16px]">Order Total:</span>
              <span className="text-white font-bold text-[16px]">{data.orderTotal ? formatCurrency(data.orderTotal) : '-'}</span>
            </div>
          </div>
        </div>
      : null  
      }
      </div>
    </div>
  );
}
