// import Logo from '../../../assets/svg/logo.svg?react';
// import HeaderLogo from "./header2.svg?react";
import { ReactComponent as Logo } from '../../../../assets/svg/logo.svg'
import { ReactComponent as HeaderLogo } from './header2.svg'

function Header() {
  return ( 
    <div className="flex flex-row ml-[7.5%] justify-between mt-[5.3%] w-full">
        <Logo className="w-[20%] h-auto" />  
        <HeaderLogo className="w-[76%] h-auto" />
      </div>
   );
}

export default Header;