import React, { useState, useEffect } from "react";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  List,
  ListItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import useSWR from "swr";
import { instructionType } from "api/instruction";
import { isInvalid, getHelperText } from "logic/Instruction/utils";
import TypeIcon from "modules/Engineering/Instruction/Modal/TypeIcon";
import { host } from "host";
import MyDialog from "app/Dialog";
import TextField from "app/TextField";
import Pagination from "./Pagination";
import UploadImage from "common/Upload/Image";
import Image from "common/Image";
// import UploadButton from "app/UploadButton";

export default function InstructionPreviewModal({
  open,
  instructionId,
  stepId,
  step,
  totalSteps,
  onStepChange,
  onClose,
  workOnPrev = false,
}: {
  instructionId?: string;
  stepId?: string;
  open: boolean;
  step: number;
  totalSteps: number;
  onStepChange: (
    step: number,
    payload?: {
      args: any;
      checkboxes: any;
      photo?: File;
      step: string;
      id: string;
      instruction: instructionType;
      currentStep: number;
    }
  ) => void;
  onClose: () => void;
  workOnPrev?: boolean;
}) {
  const phone = useMediaQuery("(max-width:700px)");

  const [args, setArgs] = useState<any>({});
  const [checks, setChecks] = useState<any>({});
  const [photo, setPhoto] = useState<File>();

  const { data: instruction, isValidating } = useSWR<instructionType>(
    stepId ? `/step/${stepId}` : `/instruction/${instructionId}`
  );
  const { data: photos } = useSWR(`/photo/${stepId ? "step" : "instruction"}/${stepId || instructionId}`);
  const { data: attachments } = useSWR<{ result: any }>(
    `/document/${stepId ? "step" : "instruction"}/${stepId || instructionId}`
  );

  useEffect(() => {
    setArgs({});

    const initialChecks: any = {};
    if (instruction?.checkBoxes && workOnPrev) {
      for (const checkbox of instruction.checkBoxes) {
        initialChecks[checkbox.title] = checkbox.selected || false;
      }
    }
    setChecks(initialChecks);

    setPhoto(undefined);
  }, [step, instruction, workOnPrev]);

  return (
    <MyDialog
      open={open}
      onClose={onClose}
      title="Step Preview"
      maxWidth="sm"
      fullWidth
      sx={{ maxHeight: phone ? "auto" : "800px", justifyContent: "center" }}
      fullScreen={phone}
    >
      {isValidating ? (
        <Box display="flex" alignItems="center" justifyContent="center" minHeight={400}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Box style={{ padding: "0.5em 2em" }} height="75vh" overflow="auto" display="flex" flexDirection="column">
            <Box mr={2} mb={2}>
              <Box display="flex" alignItems="center" mb={2}>
                {instruction?.contentType && (
                  <TypeIcon type={instruction.contentType as any} style={{ width: 40, height: 40 }} />
                )}
                <Typography variant="h4" style={{ fontWeight: "bold", marginLeft: "10px" }}>
                  Step {instruction?.step}:
                </Typography>
              </Box>
              <Typography variant="h5" fontWeight={700} mb={1}>
                {instruction?.subTitle}
              </Typography>

              <Typography fontSize={12} style={{ textAlign: "justify" }}>
                {instruction?.content}
                <br />
              </Typography>
            </Box>
            <Box mt="auto" gap={2}>
              {instruction?.checkBoxes?.map((c) => (
                <FormControlLabel
                  control={<Checkbox />}
                  label={c.title}
                  required={c.requiredValue === true}
                  checked={checks?.[c.title] || false}
                  onChange={(e, v) => setChecks((p: any) => ({ ...p, [c.title]: v }))}
                  style={{ width: "100%" }}
                />
              ))}
            </Box>
            <Box
              mt="auto"
              display="grid"
              gridTemplateColumns={
                instruction?.args && instruction?.args?.length > 6 ? "repeat(2,1fr) " : "repeat(1, 1fr)"
              }
              gap={2}
              mb={2}
            >
              {instruction?.args?.map((a) => (
                <Box display="flex" alignItems="center">
                  <TextField
                    label={`${a.key}`}
                    value={args?.[a.key] || ""}
                    onChange={(e) => setArgs((p: any) => ({ ...p, [a.key]: e.target.value }))}
                    error={isInvalid(a, args[a.key])}
                    helperText={isInvalid(a, args[a.key]) && getHelperText(a)}
                    style={{ width: instruction?.args && instruction?.args?.length > 6 ? "100%" : "50%" }}
                  />
                  <Typography mx={1}>{a.unit}</Typography>
                </Box>
              ))}
            </Box>
            <Box
              style={{ gap: 8, marginTop: "5px" }}
              display="column"
              alignItems="center"
              justifyContent="start"
              flexWrap="wrap"
            >
              {photos?.map((img: any) => (
                <Image
                  src={"https://erp.phocuss.com" + img?.path}
                  alt=""
                  style={{ flex: 1, width: "300px", height: 250, objectFit: "contain", aspectRatio: "auto" }}
                />
              ))}
            </Box>
            <Box style={{ marginTop: "auto" }}>
              <List>
                {attachments?.result &&
                  attachments?.result?.map((atc: any) => (
                    <ListItem>
                      <a href={host + atc} download>
                        {atc?.path}
                      </a>
                    </ListItem>
                  ))}
              </List>
            </Box>
            {instruction?.contentType === "camera" && (
              <UploadImage onChange={(f) => setPhoto(f)} />
              // <UploadButton
              //   width="300px"
              //   values={photo}
              //   onChange={(e: any) => e?.target.files !== null && setPhoto(e.target.files[0])}
              // />
            )}
          </Box>
          <Box display="flex" justifyContent="center">
            <Pagination
              page={step}
              pageCount={totalSteps}
              onPage={(p) => {
                if (instruction) {
                  onStepChange(p, {
                    args,
                    checkboxes: checks,
                    photo,
                    step: instruction.step,
                    id: (instruction as any).TestingInstructionId || (instruction as any).id,
                    instruction,
                    currentStep: step,
                  });
                }
              }}
            />
          </Box>
        </>
      )}
    </MyDialog>
  );
}
