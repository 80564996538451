import Footer from "./footer/Footer";
import Header from "./header/Header";
export default function POpage5({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={5} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content() {
  const AmperageRows = Array.from({ length: 2 }, (_, index) => {
    return {
      id: index + 1,
      Amperages: index === 0 ? "Input" : "Output", // Random value for the first row, "-" for others
      X: "",
      Y: "",
      Z: "",
      NEUTRAL: "",
      GROUND: "",
      KVA: "",
    };
  });

  const AmperageTitles = Object.keys(AmperageRows[0]).filter((title) => title !== "id");
  const voltageRows = Array.from({ length: 2 }, (_, index) => {
    return {
      id: index + 1,
      Voltages: index === 0 ? "Input" : "Output", // Random value for the first row, "-" for others
      "X-Y": "",
      "Y-Z": "",
      "Z-X": "",
      "X-N": "",
      "Y-N": "",
      "Z-N": "",
    };
  });

  const voltageTitles = Object.keys(voltageRows[0]).filter((title) => title !== "id");
  const serviceInfo = {
    "Service At": "-",
    "Service Contact Title": "-",
    "Service Phone": "-",
    "Product Type": "-",
    "Model No.": "-",
    "City State": "-",
    "Serial No.": "-",
    "Zip Code": "-",
    "Service Code": "-",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div>
          <table className="min-w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table align-top  p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service At:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Service At:"]}</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Contact Title:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Service Contact Title"]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Phone:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Service Phone"]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">
                      Product Type (I.e. UPS Regulator):
                    </span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Product Type"]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">-</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">-</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Model No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Model No."]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">City State:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["City State"]}</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Serial No.:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Serial No."]}</span>
                  </div>
                </td>
              </tr>
              <tr>
                <td className="border border-table p-[1%] w-1/2 bg-[#f6f6f6]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Zip Code:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Zip Code"]}</span>
                  </div>
                </td>
                <td className="border border-table align-top p-[1%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Service Code:</span>
                    <span className="text-[12px] ml-[1%] py-[1%]">{serviceInfo["Service Code"]}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                {voltageTitles?.map((title) => (
                  <th className="border border-table text-start p-[1%]" key={title}>
                    <div className={`flex flex-col ${title != "Voltages" ? "items-center" : ""}`}>
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">{title}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {voltageRows?.map((row) => (
                <tr key={row.id}>
                  {voltageTitles?.map((title) => (
                    <td className="border border-table p-[1%] " key={title}>
                      <div className={`flex flex-col ${title != "Voltages" ? "items-center" : ""}`}>
                        <span className="text-[12px] font-light">{row[title]}</span>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td className="border border-table p-[1%] w-[20%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">DC Bus Inverter</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%] bg-[#a6c7db]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
                <td colSpan={2} className="border border-table p-[1%] w-[30%]">
                  <div className="flex flex-col justify-center items-center ">
                    <span className="text-[12px] font-light">DC Bus Battery Charger </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""} </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%] bg-[#a6c7db]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                {AmperageTitles?.map((title) => (
                  <th className="border border-table text-start p-[1%] w-[12.5%]" key={title}>
                    <div className={`flex flex-col ${title !== "Amperages" ? "items-center" : ""}`}>
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">{title}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {AmperageRows?.map((row) => (
                <tr key={row.id}>
                  {AmperageTitles?.map((title) => (
                    <td className="border border-table p-[1%]" key={title}>
                      <div className={`flex flex-col ${title != "Amperages" ? "items-center" : ""}`}>
                        <span className="text-[12px] font-light">{row[title]}</span>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
              <tr>
                <td className="border border-table p-[1%] w-[20%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">DC Bus Inverter</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%] bg-[#a6c7db]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
                <td colSpan={2} className="border border-table p-[1%] w-[30%]">
                  <div className="flex flex-col justify-center items-center ">
                    <span className="text-[12px] font-light">DC Bus Battery Charger </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""} </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-[12.5%] bg-[#a6c7db]">
                  <div className="flex flex-col">
                    <span className="text-[12px] font-light">{""}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
