import { useCallback, useEffect, useState } from "react";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom";

import { openRequestedSinglePopup } from "./window";
import { IItem, getItem } from "api/items";

export const useQuery = async (request: (arg?: any) => Promise<any>) => {
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const refreshData = useCallback(async () => {
    setIsLoading(true);
    try {
      const resp = await request();
      if (resp) {
        setData(resp);
        setIsLoading(false);
      }
    } catch (error: any) {
      setError(error);
      setData(null);
      setIsLoading(false);
    }
  }, [request]);

  useEffect(() => {
    refreshData();
  }, [refreshData]);

  return { data, isLoading, error, refreshData };
};

export function useResponsiveNavigate() {
  const history = useHistory();
  const phone = useMediaQuery("(max-width:600px)");

  return (url: string) => {
    // @ test for open new tab in responsive mode

    // if (phone) {
    //   history.push(url);
    // } else {
    openRequestedSinglePopup({ url });
    // }
  };
}

export function useDevice(deviceId?: string) {
  const [device, setDevice] = useState<IItem>();
  const [isLoading, setIsLoading] = useState(false);

  const mutate = useCallback(() => {
    if (deviceId) {
      setIsLoading(true);
      getItem(deviceId)
        .then((d) => {
          console.log(d);
          setDevice(d);
        })
        .catch((e) => console.log(e))
        .finally(() => setIsLoading(false));
    }
  }, [deviceId]);

  useEffect(() => {
    if (deviceId && !device) {
      mutate();
    }
  }, [device, deviceId, mutate]);

  return { device, isLoading, mutate };
}
