import Fuse from "fuse.js";
import { Schema } from "./Schema";
import { panels } from "logic/Constants";

export function searchItems({ items, query }: { items: Schema["items"]["value"][]; query: string }) {
  const options = {
    includeScore: true,
    keys: ["no", "name", "description", "cluster"],
  };

  const fuse = new Fuse(items, options);

  return fuse.search(query).slice(0, 100);
}

export function searchPanels(query: string) {
  const options = {
    includeScore: true,
    keys: ["name", "path"],
  };

  const fuse = new Fuse(panels, options);

  return fuse.search(query);
}
