import React from "react";
import { Card } from "@mui/material";

import LoginForm from "features/Session/LoginForm";

import phocus from "assets/homeIcon/phocus.svg";
import "../styles/splash.css";

export default function SplashScreen() {
  return (
    <div className="splash-bg" style={{ textAlign: "center" }}>
      <Card sx={{ p: 5, background: "rgba(0, 0, 0, 0.5)" }}>
        <img src={phocus} alt="logo" style={{ opacity: "1" }} />
        <LoginForm />
      </Card>
    </div>
  );
}
