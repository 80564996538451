import React from "react";
import { Dialog, DialogActions, DialogContent, Button, useMediaQuery, Theme } from "@mui/material";
import { styled } from "@mui/system";

interface ImageDialogProps {
  open: boolean;
  imageUrl: string;
  actionTitle?: string;
  onClose: () => void;
  onAction?: () => void;
}

const StyledImg = styled("img")({
  width: "100%",
  height: "auto",
  maxHeight: "calc(100vh - 240px)", // Adjust based on your needs
  objectFit: "contain",
});

export default function ImageDialog({ open, actionTitle, onClose, imageUrl, onAction }: ImageDialogProps) {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isMobile} maxWidth="lg" fullWidth>
      <DialogContent>
        <StyledImg src={imageUrl} alt="Dialog content" />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
        <Button
          onClick={() => {
            onAction?.();
            onClose();
          }}
          color="primary"
          variant="contained"
        >
          {actionTitle}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
