import { post } from "api";
import { apiAgent } from "./config";

export const postAttendance = (data: { userId: string; photo: File | Blob }) => {
  const formData = new FormData();

  formData.append("userId", data.userId);
  formData.append("photo", data.photo);

  return apiAgent.post("/employee/attendance", formData);
};

export const postCheckout = (data: { userId: string; photo: File | Blob }) => {
  const formData = new FormData();

  formData.append("userId", data.userId);
  formData.append("photo", data.photo);

  return post("/employee/attendance/checkedout", formData);
};
