import React, { createContext, ReactNode, useState, useEffect } from "react";

export const context = createContext({ lock: true, setLock: (v: boolean | ((v: boolean) => boolean)) => {} });

export default function LockProvider({
  controlledLock,
  initialLock,
  children,
}: {
  initialLock?: boolean;
  controlledLock?: boolean;
  children: ReactNode;
}) {
  const [lock, setLock] = useState<boolean>(
    controlledLock !== undefined ? controlledLock : initialLock !== undefined ? initialLock : true
  );

  useEffect(() => {
    if (controlledLock !== undefined) {
      setLock(controlledLock);
    }
  }, [controlledLock]);

  return <context.Provider value={{ lock, setLock }}>{children}</context.Provider>;
}
