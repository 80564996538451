import { differenceInHours } from "date-fns";
import { DBSchema, openDB } from "idb";
import { getAndSaveItems } from "./Item";

export interface Schema extends DBSchema {
  "last-updated-entities": {
    key: "items" | "clusters";
    value: Date;
  };
  items: {
    key: string;
    value: {
      id: string;
      no: string;
      name: string;
      description: string;
      cluster: string;
    };
    indexes: {
      "by-cluster": string;
    };
  };
}

export async function connectDB() {
  const db = await openDB<Schema>("phocus-db", 1, {
    upgrade(db) {
      console.log("upgrading db...");

      db.createObjectStore("last-updated-entities");

      const itemsStore = db.createObjectStore("items", {
        keyPath: "id",
      });
      itemsStore.createIndex("by-cluster", "cluster");
    },
  });

  return db;
}

export async function initializeDB() {
  const db = await connectDB();
  const lastUpdated = await db.getAll("last-updated-entities", "items");
  const dateThreshold = 3;
  const needRefresh = Boolean(
    lastUpdated.some((i) => Math.abs(differenceInHours(i, new Date())) > dateThreshold) || !lastUpdated?.length
  );

  if (!needRefresh) {
    return;
  }

  console.log("Fetching items...");
  await getAndSaveItems();
}

export async function fetchLastUpdateDates() {
  const db = await connectDB();
  return db.getAll("last-updated-entities");
}
