import React from "react";
import { Box, IconButton, Typography } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

export default function Pagination({
  page,
  pageCount,
  forwardOnly,
  nextDisabled,
  prevDisabled,
  onPage,
}: {
  page: number;
  pageCount: number;
  prevDisabled?: boolean;
  nextDisabled?: boolean;
  forwardOnly?: boolean;
  onPage: (v: number) => void;
}) {
  if (forwardOnly) {
    return (
      <Box display="flex" alignItems="center" style={{ gap: 8 }}>
        <Typography>
          {page} of {pageCount}
        </Typography>
        <IconButton onClick={() => onPage(page + 1)} disabled={nextDisabled || page === pageCount}>
          <ChevronRight />
        </IconButton>
      </Box>
    );
  }

  return (
    <Box display="flex" alignItems="center" style={{ gap: 8 }}>
      <IconButton onClick={() => onPage(page - 1)} disabled={prevDisabled || page === 1}>
        <ChevronLeft />
      </IconButton>
      <Typography>
        {page} of {pageCount}
      </Typography>
      <IconButton onClick={() => onPage(page + 1)} disabled={nextDisabled}>
        <ChevronRight />
      </IconButton>
    </Box>
  );
}
