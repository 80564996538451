import React, { useEffect, useState } from "react";
import { Box, Checkbox, FormControlLabel, TextField, useMediaQuery } from "@mui/material";
import { Form, Formik } from "formik";
import Dialog from "app/Dialog";
import Button from "app/Button";
import { get, post } from "api";
import AsyncCombo from "common/AsyncCombo";
import { clusterType } from "api/cluster";
import { mutate } from "swr";
import { toast } from "react-toastify";

export default function AddTopicModal({
  open,
  onClose,
  setRefresh,
  initialTopic,
}: {
  open: boolean;
  onClose: any;
  setRefresh: any;
  initialTopic?: any;
}) {
  const phone = useMediaQuery("(max-width:900px)");
  const [selectedCluster, setSelectedCluster] = useState<clusterType>();
  const [checked, setChecked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!initialTopic || !initialTopic.ClusterId) return;
    setSelectedCluster(initialTopic.ClusterId);
  }, [initialTopic]);

  console.log({ initialTopic });

  const handleSubmit = (data: any) => {
    const topicData = {
      name: data.name,
      ClusterId: checked ? null : selectedCluster?.id,
    };
    setIsSubmitting(true);
    post(`/topic`, topicData)
      .then(() => {
        mutate("/topic");
        onClose();
        setRefresh((p: any) => p + 1);
        toast.success("Topic Added");
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <Dialog title="Add Topic" open={open} onClose={onClose} maxWidth="sm">
      <Formik onSubmit={handleSubmit} initialValues={{ name: initialTopic?.name || "" } as any}>
        {({ setFieldValue, values, handleSubmit }) => (
          <Form style={{ width: "30rem" }}>
            <Box display="grid" gridTemplateColumns="1fr" gap={2}>
              <TextField
                value={values?.name}
                name="name"
                label="Name"
                onChange={(e) => setFieldValue("name", e.target.value)}
              />

              <FormControlLabel
                label="Do Not Use Model Number"
                control={<Checkbox checked={checked} onChange={(e, nv) => setChecked(nv)} />}
                sx={{ ml: "-0.5rem" }}
              />

              {!checked && (
                <AsyncCombo
                  label="Model"
                  placeholder="All"
                  url="/cluster"
                  filterBy="clusterValue"
                  defaultParams={{ class: "device" }}
                  getOptionLabel={(o) => String(o?.clusterValue) || ""}
                  getOptionSelected={(o, v) => o?.id === v?.id}
                  defaultValue={initialTopic?.ClusterId}
                  value={selectedCluster}
                  onChange={(e, nv) => setSelectedCluster(nv)}
                  style={{ marginTop: "-0.8rem" }}
                />
              )}
            </Box>
            <Button
              sx={{ mt: "3rem" }}
              type="button"
              onClick={() => handleSubmit()}
              kind="add"
              variant="contained"
              color="success"
              disabled={isSubmitting || !values?.name}
            >
              Submit
            </Button>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
}
