import React from "react";

import AsyncCombo from "common/AsyncCombo";
import { getId } from "logic/utils";

interface ServerComboFilterProps {
  optionsUrl?: string;
  model: any;
  column: any;
  onModelChange: (model: any) => void;
}
export default function ServerComboFilter({ optionsUrl, model, onModelChange, column }: ServerComboFilterProps) {
  console.log({ optionsUrl, model, onModelChange, column });

  return (
    <>
      <AsyncCombo
        style={{ width: "100%" }}
        url={column.colDef?.optionsUrl}
        filterBy={column.colDef?.optionsFilterBy}
        getOptionLabel={column.colDef?.getOptionLabel || ((o: any) => o?.name || o?.no || o?.number || o?.title || "")}
        onChange={(e, v) => {
          console.log(column.colDef?.getOptionValue(v), "sbjh");
          onModelChange({
            type: "equals",
            filter: column.colDef?.getOptionValue ? column.colDef?.getOptionValue(v) : getId(v),
          });
        }}
      />
    </>
  );
}
