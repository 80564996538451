import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";
import useSWR from "swr";

import { IEmployee } from "api/employee";
import { formatTimestampToDate } from "logic/date";

import pageBg from "assets/icons/pageBg.png";

const ProcedureInfo = forwardRef<
  HTMLDivElement,
  {
    model: string;
    serial: string;
    modelName: string;
    description: string;
  }
>(({ description, model, modelName, serial }, ref) => {
  const { data: me } = useSWR<IEmployee>("/employee/me");

  return (
    <Box ref={ref} width="21cm" m="auto">
      <Box
        width="100%"
        display="flex"
        justifyContent="space-around"
        alignItems={"center"}
        sx={{ background: "#F4F4F4" }}
        position="relative"
        py={1}
      >
        <Box display="flex">
          <Typography fontWeight={"bold"}>Tester :</Typography>
          <Typography>{me?.username}</Typography>
        </Box>
        <Box display="flex">
          <Typography fontWeight={"bold"}>Serial No :</Typography>
          <Typography>{serial}</Typography>
        </Box>
        <Box display="flex">
          <Typography fontWeight={"bold"}>Date :</Typography>
          <Typography>{formatTimestampToDate(Number(new Date()))}</Typography>
        </Box>
      </Box>
      <Box position={"relative"}>
        <img src={pageBg} width="100%" style={{ position: "absolute", minHeight: 300 }} alt="background" />
        <Box
          pt={2}
          width="20cm"
          m="20px 10px"
          display="flex"
          justifyContent={"space-around"}
          flexWrap={"wrap"}
          position={"relative"}
        >
          <Box width={"30%"}>
            <Typography color="#FFFFFF" fontWeight={"bold"}>
              Model Name
            </Typography>
            <Typography color="#FFFFFF">{modelName}</Typography>
            <Typography color="#FFFFFF" fontWeight={"bold"} mt="10px">
              Model
            </Typography>
            <Typography color="#FFFFFF">{model}</Typography>
          </Box>
          <Box width="68%">
            <Typography color="#FFFFFF" fontWeight={"bold"}>
              Model Description
            </Typography>
            <pre style={{ color: "white" }}>{description.replaceAll("\r", "\n")}</pre>
          </Box>
        </Box>
      </Box>
    </Box>
  );
});

// export default function ProcedureInfo({
//   model,
//   serial,
//   modelName,
//   description,
// }: {
//   model: string;
//   serial: string;
//   modelName: string;
//   description: string;
// }) {
//   const { data: me } = useSWR<IEmployee>("/employee/me");

//   return (
//     <Box width="21cm">
//       <Box
//         width="100%"
//         display="flex"
//         justifyContent="space-around"
//         alignItems={"center"}
//         sx={{ background: "#F4F4F4" }}
//         position="relative"
//       >
//         <Box display="flex">
//           <Typography fontWeight={"bold"}>Tester :</Typography>
//           <Typography>{me?.username}</Typography>
//         </Box>
//         <Box display="flex">
//           <Typography fontWeight={"bold"}>Serial No :</Typography>
//           <Typography>{serial}</Typography>
//         </Box>
//         <Box display="flex">
//           <Typography fontWeight={"bold"}>Date :</Typography>
//           <Typography>{formatTimestampToDate(Number(new Date()))}</Typography>
//         </Box>
//       </Box>
//       <Box position={"relative"}>
//         <img src={pageBg} width="100%" style={{ position: "absolute", minHeight: 300 }} alt="background" />
//         <Box
//           width="20cm"
//           m="20px 10px"
//           display="flex"
//           justifyContent={"space-around"}
//           flexWrap={"wrap"}
//           position={"relative"}
//         >
//           <Box width={"30%"}>
//             <Typography color="#FFFFFF" fontWeight={"bold"}>
//               Model Name
//             </Typography>
//             <Typography color="#FFFFFF">{modelName}</Typography>
//             <Typography color="#FFFFFF" fontWeight={"bold"} mt="10px">
//               Model
//             </Typography>
//             <Typography color="#FFFFFF">{model}</Typography>
//           </Box>
//           <Box width="68%">
//             <Typography color="#FFFFFF" fontWeight={"bold"}>
//               Model Description
//             </Typography>
//             <pre style={{ color: "white" }}>{description.replaceAll("\r", "\n")}</pre>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// }

export default ProcedureInfo;
