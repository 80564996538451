import { MoreVertRounded } from "@mui/icons-material";
import { Box, Button, Checkbox, List, ListItem, ListItemIcon, ListItemText, Menu } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function MoreButton({
  columns,
  columnsState,
  setColumnsState,
  setColumnData,
}: {
  columns: any;
  columnsState: any;
  setColumnsState: (p?: any) => void;
  setColumnData: (p?: any) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleToggleVisible = (index: number, visible: boolean) => {
    setColumnsState((prev: any) => {
      let temp = prev.concat();
      temp[index].visible = visible;
      const checkedColumns = columnsState
        .filter((column: any) => column.visible)
        .map((column: any) => column?.header)
        .filter(Boolean)
        .join(",");
      setColumnData(checkedColumns);
      return temp;
    });
  };

  const getTotalVisibleColumns = (arr: any[]) => {
    const res = arr.reduce((total, { visible }) => {
      if (visible) {
        return total + 1;
      }
      return total;
    }, 0);

    return res;
  };

  const handleToggleAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setColumnsState((prev: any) => {
      const allColumnNames = prev
        .map((column: any) => column.header)
        .filter(Boolean)
        .join(",");
      setColumnData(allColumnNames);
      return prev.concat().map((c: any) => ({ ...c, visible: e.target.checked }));
    });
  };

  useEffect(() => {
    setColumnsState(columns.map((c: any) => ({ ...c, visible: c.visible !== undefined ? c.visible : true })));
  }, [columns]);

  return (
    <Box>
      <Button
        title="Culomns"
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          zIndex: 5,
          padding: "7px 0px",
          minWidth: "15px",
          display: "flex",
          alignItems: "center",
          backgroundColor: "rgb(230,128,49)",
        }}
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
      >
        <MoreVertRounded />
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)} keepMounted>
        <Box style={{ width: 200, maxHeight: 300, overflowY: "auto" }}>
          <List dense>
            <ListItem>
              <ListItemIcon>
                <Checkbox
                  checked={getTotalVisibleColumns(columnsState) === columnsState.length}
                  onChange={handleToggleAll}
                />
              </ListItemIcon>
              <ListItemText>All</ListItemText>
            </ListItem>
            {columnsState &&
              columnsState
                .filter((i: any) => i.name !== "action_view_more" && i.header !== "action_view_more")
                .map(({ name, header, visible }: any, i: number) => (
                  <ListItem key={i}>
                    <ListItemIcon>
                      <Checkbox
                        checked={visible}
                        onChange={(e) =>
                          handleToggleVisible(
                            columnsState.findIndex((c: any) => c.name === name || c.name === header) || i,
                            e.target.checked
                          )
                        }
                      />
                    </ListItemIcon>
                    <ListItemText>{header || name}</ListItemText>
                  </ListItem>
                ))}
          </List>
        </Box>
      </Menu>
    </Box>
  );
}
