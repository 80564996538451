import React, { useCallback, useRef, useState } from "react";
import { Box, Card, CircularProgress, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import Webcam from "react-webcam";

import Button from "app/Button";
import Confirm from "common/Confirm";
import Toast from "app/Toast";
import { postAttendance, postCheckout } from "api/attendance";

import phocus from "assets/homeIcon/phocus.svg";
import "../styles/splash.css";

export default function AutoAttendancePage() {
  const { userId } = useParams<{ userId: string }>();
  const webcamRef = useRef<Webcam>(null);

  const [status, setStatus] = useState<"loading" | "checkedIn" | "checkedOut" | "camera">("camera");

  const handleCapture = useCallback(
    async (imageSrc: string) => {
      try {
        const response = await fetch(imageSrc);
        const imageBlob = await response.blob();

        if (userId && imageSrc) {
          postAttendance({ userId, photo: imageBlob })
            .then(() => {
              setStatus("checkedIn");
            })
            .catch((error) => {
              if (error?.response?.data?.error === "entrance already checked in") {
                Confirm({
                  text: "Do you want to checkout?",
                  confirmText: "Yes",
                  async onConfirm() {
                    try {
                      await postCheckout({ userId, photo: imageBlob });
                      setStatus("checkedOut");
                    } catch (error) {
                      console.log(error);
                    }
                  },
                });
              }
            });
        }
      } catch (error) {
        Toast("An error on capturing image happened, Please try again.", "error");
      }
    },
    [userId]
  );

  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();

    if (imageSrc) {
      setStatus("loading");
      handleCapture(imageSrc);
    }
  }, [handleCapture]);

  let content = <></>;
  if (status === "camera") {
    content = (
      <>
        <Typography color="#fff" mb={2}>
          Camera step
        </Typography>
        <Typography color="#fff" mb={2}>
          {userId}
        </Typography>
        <Webcam
          audio={false}
          width="100%"
          height={480}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={{
            width: 1280,
            height: 720,
            facingMode: "user",
          }}
        />
        <Button kind="add" sx={{ mt: 2 }} onClick={capture}>
          Capture
        </Button>
      </>
    );
  }
  if (status === "loading") {
    content = (
      <Box my={2}>
        <CircularProgress />
      </Box>
    );
  }
  if (status === "checkedIn") {
    content = (
      <Box my={2}>
        <Typography color="white">You checked in successfully</Typography>
      </Box>
    );
  }
  if (status === "checkedOut") {
    content = (
      <Box my={2}>
        <Typography color="white">You checked out successfully</Typography>
      </Box>
    );
  }

  return (
    <div className="splash-bg" style={{ textAlign: "center" }}>
      <Card sx={{ p: 5, background: "rgba(0, 0, 0, 0.4)" }}>
        <img src={phocus} alt="logo" style={{ opacity: "1", marginBottom: "20px" }} />
        {content}
      </Card>
    </div>
  );
}
