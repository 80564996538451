import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage11({data,pageNumber}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    LimitationActions: "No action against Seller for breach hereof shall be commenced more than one (1) year after the accrual of the cause of action.",
    Assignment: "The Buyer shall not assign this order or any interest therein or any rights there under without the prior written consent of Seller.",
    LocalCurrency: "Any order placed hereunder is, and payment for such order will be in U.S. Dollars.",
    ConfidentialInformation: "Seller shall have no obligation to hold any information received from Buyer hereunder in confidence unless such information is covered by a separate negotiated Non-Disclosure Agreement, which is reduced to writing and signed by both parties.",
    GoverningLaw: "SEVERABILITY. These terms and conditions of sale shall be governed by the laws of the state of California. Any provisions hereof which are prohibited or unenforceable in any jurisdiction shall, as to such jurisdictions, be ineffective to the extent of such prohibition or unenforceable without invalidating the remaining provisions hereof or affecting the validity or enforceability of such provisions in any other jurisdiction. Buyer hereby consents to the exercise of jurisdiction over it by any state or federal court within the State of California.",
    Arbitration: "If a disagreement or controversy of any kind arises between the Buyer and Seller, both parties shall meet to attempt to resolve such disagreement. If the disagreement cannot be resolved by the parties, an informal binding arbitration shall be held. The rules of the informal arbitration shall be agreed upon by the parties prior to the arbitration. To the extent that Buyer and Seller cannot agree on the rules of the arbitration, the rules and procedures of the American Arbitration Association shall apply. As a minimum set of rules, the parties agree as follows:",
    a: "The arbitration shall be held by a single arbitrator mutually acceptable to both parties. If the parties cannot agree on a single arbitrator, each party shall identify one independent individual who shall meet to appoint a single arbitrator."
  }
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
      <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">Limitation of Actions:</span>
            <span className="font-light text-[16px] text-justify">{texts.LimitationActions}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">Assignment:</span>
            <span className="font-light text-[16px] text-justify">{texts.Assignment}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">Local Currency:</span>
            <span className="font-light text-[16px] text-justify">{texts.LocalCurrency}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">
            Confidential Information:
            </span>
            <span className="font-light text-[16px] text-justify">{texts.ConfidentialInformation}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">
            Governing Law:
            </span>
            <span className="font-light text-[16px] text-justify">{texts.GoverningLaw}</span>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <span className="font-bold text-[16px] text-justify">
            Arbitration:
            </span>
            <span className="font-light text-[16px] text-justify">{texts.Arbitration}</span>
          </div>
        </div>
        <div>
          <div className="flex gap-1">
          <span className="text-primaryOrange text-[16px]">A.</span>
            <span className="font-light text-[16px] text-justify">{texts.a}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
