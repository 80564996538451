import { useEffect, useState } from "react";

export default function useMediaDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("not supported or permission didn't granted try again.");
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then((list) => setDevices(list.filter((d) => !d.kind?.includes("front") && !d.label?.includes("front"))))
        .catch((err) => {
          setError(`${err.name}: ${err.message}`);
        });
    }
  }, []);

  return { devices, error };
}
