import { get } from "api";
import { getBillingAddress, getShippingAddress } from "api/address";
import { format } from "date-fns";

export const formatDate = (date: string, dateFormat: string) => {
  return format(new Date(date), dateFormat);
};

export const getModifiedValues = (values: any, initialValues: any) => {
  let modifiedValues: any = {};

  if (values) {
    Object.entries(values).forEach((entry) => {
      let key = entry[0];
      let value = entry[1];

      if (value !== initialValues[key]) {
        modifiedValues[key as any] = value;
      }
    });
  }

  return Object.keys(modifiedValues).length === 0 ? null : modifiedValues;
};

export function filterNullValues(values: any) {
  const temp: any = {};
  for (const key in values) {
    if (values[key] !== null && values[key] !== undefined && values[key] !== "") {
      temp[key] = values[key];
    }
  }

  return temp;
}

export const countProperty = (data: any[], value: string, propGetter: (item: any) => any) => {
  return data.filter((item) => propGetter(item) === value).length;
};

export type ParameterType = {
  [key: string]: string | number | boolean | null | undefined;
};

export const generateQuery = (params: ParameterType) => {
  const queryArray = [];
  let paramValue: any = "";

  for (const paramName in params) {
    paramValue = params[paramName];

    if (paramValue !== null && paramValue !== undefined && paramValue !== "") {
      queryArray.push(`${paramName}=${paramValue}`);
    }
  }

  return queryArray.join("&");
};

export const sleep = (ms: number = 100) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function camelCaseToRegular(name: string) {
  var words = name?.match(/[A-Za-z][a-z]*/g) || [];
  return words.map(capitalize).join(" ");
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.substring(1);
}

export const getId = (item: any): string => (typeof item === "string" ? item : item?.id ?? item?._id);

export function isFormDataEmpty(formData: FormData) {
  return formData.entries().next().done;
}

export function getUniques(data: string[] | number[]) {
  const cache: any = {};
  for (const item of data) {
    cache[item] = item;
  }

  return Object.keys(cache);
}

export function addPercent(value: number, percent: number) {
  return Math.round(value + (value * percent) / 100);
}

export async function urlContentToDataUri(url: string) {
  const response = await fetch(url);
  const blob = await response.blob();
  return await new Promise((callback) => {
    let reader = new FileReader();
    reader.onload = function () {
      callback(this.result);
    };
    reader.readAsDataURL(blob);
  });
}

export function arrayBufferToBase64(buffer: ArrayBuffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

export function getUniqueValues<T>(arr: T[], getKey: (i: T) => string | number): T[] {
  const uniqueObjects = new Map();

  arr.forEach((obj) => {
    const value = getKey(obj);

    if (value !== null && value !== undefined) {
      if (!uniqueObjects.has(value)) {
        uniqueObjects.set(value, obj);
      }
    }
  });

  return Array.from(uniqueObjects.values());
}

export async function getAddressData({
  values,
  clear,
  newValue,
  setFieldValue,
  type,
}: {
  newValue: any | "dspm";
  values: any;
  setFieldValue: (k: string, value: any) => void;
  type: "billing" | "shipping";
  clear: boolean;
}) {
  if (clear && type === "billing") {
    const body = {
      billingCompany: "",
      billingAddress: "",
      billingAttn: "",
      billingCity: "",
      billingState: "",
      billingCountry: "",
      billingFax: "",
      billingPhone: "",
      billingZip: "",
      billingEmail: "",
    };

    for (const field in body) {
      setFieldValue(field, (body as any)[field]);
    }
    return;
  }
  if (clear && type === "shipping") {
    const body = {
      shippingCompany: "",
      shippingAddress: "",
      shippingAttn: "",
      shippingCity: "",
      shippingState: "",
      shippingCountry: "",
      shippingFax: "",
      shippingPhone: "",
      shippingZip: "",
      shippingEmail: "",
    };
    for (const field in body) {
      setFieldValue(field, (body as any)[field]);
    }
    return;
  }
  if (type === "billing") {
    let billingData: any = undefined;
    if (newValue === "dspm") {
      billingData = await get("/address/dspm");
    } else {
      billingData =
        newValue !== null
          ? (await getBillingAddress(getId(newValue)))?.result?.[0]
          : (await getBillingAddress(getId(values?.ClientId)))?.result?.[0];
    }

    if (billingData) {
      let body = {
        billingCompany: newValue?.name || undefined,
        billingAddress: billingData?.address || undefined,
        billingAttn: billingData?.attn || undefined,
        billingCity: billingData?.city || undefined,
        billingState: billingData?.state || undefined,
        billingCountry: billingData?.country || undefined,
        billingFax: billingData?.fax || undefined,
        billingPhone: billingData?.phone || undefined,
        billingZip: billingData?.zip || undefined,
        billingEmail: billingData?.email || undefined,
      };

      for (const field in body) {
        setFieldValue(field, (body as any)[field]);
      }
    }
  } else if (type === "shipping") {
    let shippingData: any = undefined;

    if (newValue === "dspm") {
      shippingData = await get("/address/dspm");
    } else {
      shippingData =
        newValue !== null
          ? (await getShippingAddress(getId(newValue)))?.result?.[0]
          : (await getShippingAddress(getId(values?.RepId)))?.result?.[0];
    }

    if (shippingData) {
      const body = {
        shippingCompany: newValue?.name || undefined,
        shippingAddress: shippingData?.address || undefined,
        shippingAttn: shippingData?.attn || undefined,
        shippingCity: shippingData?.city || undefined,
        shippingState: shippingData?.state || undefined,
        shippingCountry: shippingData?.country || undefined,
        shippingFax: shippingData?.fax || undefined,
        shippingPhone: shippingData?.phone || undefined,
        shippingZip: shippingData?.zip || undefined,
        shippingEmail: shippingData?.email || undefined,
      };
      for (const field in body) {
        setFieldValue(field, (body as any)[field]);
      }
    }
  }
}

export function formatCurrency(n?: number | string | null): string {
  let value = n ? Number(n) : 0;
  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(Number(value.toFixed(3)));
}

export const getMutateMultipleKeys = (prefix: string) => (key: any) =>
  typeof key === "string" && key.startsWith(prefix);

export function resizeArray<T>(arr: T[], n: number): (T | 0)[] {
  if (n <= arr.length) {
    return arr.slice(0, n);
  } else {
    return [...arr, ...Array(n - arr.length).fill(0)];
  }
}

export function getFullName(data?: { firstName?: string; lastName?: string; username?: string }) {
  if (!data?.firstName && !data?.lastName) {
    return data?.username;
  }
  return (data?.firstName || "") + " " + (data?.lastName || "");
}

export function generateRandomString(length = 16) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export const removePrefix = (value: string, prefix: string) =>
  value.startsWith(prefix) ? value.slice(prefix.length) : value;

export function isNumeric(value: string): boolean {
  return /^\d+$/.test(value);
}

export function isValidTimestamp(timestamp: string): boolean {
  // Check if the string is a number with exactly 13 digits
  const isNumeric = /^\d{13}$/.test(timestamp);

  // Convert the string to an integer and check if it represents a valid date
  const parsedTimestamp = parseInt(timestamp, 10);

  return isNumeric && !isNaN(parsedTimestamp) && new Date(parsedTimestamp).getTime() > 0;
}
export function convertor(data: { id: string; label: string }[]) {
  return data.map((i) => i.id);
}
