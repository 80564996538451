import React, { forwardRef } from "react";
import { Box, Typography } from "@mui/material";

import header from "assets/icons/new_header.png";

const ProcedureHeader = forwardRef<
  HTMLDivElement,
  {
    variant: string;
    status: "" | "failed" | "success";
  }
>(({ variant, status }, ref) => {
  return (
    <div ref={ref} style={{ width: "21cm", height: 160, position: "relative", margin: "auto" }}>
      <img width="100%" src={header} alt="hear" style={{ position: "absolute", height: "auto" }} />
      <Box width="21cm" display="flex" justifyContent="space-between" position="relative">
        <Typography ml="30px" mt="30px" variant="h5" color="white">
          {variant}
        </Typography>
        {status && (
          <Box display="flex" alignItems="center" my={2} sx={{ background: "#FFCE1F", px: 1 }}>
            <Typography mx={1} fontWeight="bold">
              Status :
            </Typography>
            <Typography variant="body1" color={status === "success" ? "green" : "red"}>
              {status}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
});

export default ProcedureHeader;
