import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

export default function BaseDataTable(
  p: AgGridReactProps & { height?: string | number; headerBackgroundColor?: string }
) {
  const theme = useTheme();
  const phone = useMediaQuery("max-width:600px");
  const { headerBackgroundColor = "#202831", ...restProps } = p;
  return (
    <Box
      className="ag-theme-quartz"
      sx={{
        minWidth: "200px",
        height: p.height || "calc(100vh - 160px)",
        display: "flex",
        flexDirection: "column",

        "& .ag-sort-indicator-icon *": {
          color: "white",
        },

        "& .ag-header-row:first-child": {
          background: `${p.headerBackgroundColor || "#202831"} !important`,
          backgroundColor: `${p.headerBackgroundColor || "#202831"} !important`,
        },
        "& .ag-header-cell-resize::after": {
          backgroundColor: "white",
        },
        "& .ag-header-cell-text": {
          color: "white !important",
        },
        "& .ag-floating-filter-body": {
          color: "#202831 !important",
        },
        "& .MuiInputBase-root": {
          backgroundColor: "#fff",
        },
        "& .ag-row-odd": {
          backgroundColor: "rgb(245, 245, 245) !important",
        },
        "& .row-red": {
          backgroundColor: "#ffebeb !important",
        },
      }}
    >
      <Box
        className="ag-grid-container"
        sx={{
          flexGrow: 1,
          overflow: "auto",
          "& .ag-pinned-right-header, & .ag-pinned-right-cols-container": {
            overflowX: "auto",
            maxWidth: "100%",
            "&::-webkit-scrollbar": {
              height: 8,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: theme.palette.grey[500],
              borderRadius: 4,
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: theme.palette.grey[200],
            },
          },
        }}
      >
        <AgGridReact headerHeight={40} enableCellTextSelection {...p} stopEditingWhenCellsLoseFocus={true} />
      </Box>
    </Box>
  );
}

//"ag-paging-panel ag-unselectable ag-focus-managed"
