import React, { useRef, useState } from "react";
import { Box, Checkbox } from "@mui/material";

const states = [true, false, "both"];

export default function BooleanFilter({
  filterValue,
  onChange,
}: {
  filterValue: { value: boolean; name?: string };
  onChange?: (data: { value?: boolean }) => void;
}) {
  const [value, setValue] = useState<boolean | "both">(filterValue?.value ?? "both");
  const counter = useRef(
    filterValue?.value !== undefined
      ? states.findIndex((i) => filterValue.value === i) > -1
        ? states.findIndex((i) => filterValue.value === i)
        : 2
      : 0
  );

  const handleChange = (checked: boolean) => {
    if (!onChange) {
      return;
    }

    counter.current = (counter.current + 1) % states.length;
    const newState = states[counter.current] as boolean | "both";

    setValue(newState);
    onChange({ ...filterValue, value: newState === "both" ? undefined : newState });
  };

  return (
    <Box display="flex" justifyContent="center" m="2px">
      <Checkbox
        sx={{
          "&.MuiCheckbox-indeterminate ": {
            color: "#7986cb",
          },
          "&.Mui-checked ": {
            color: "#7986cb",
          },
        }}
        checked={value === "both" ? false : value}
        onChange={(e, c) => handleChange(c)}
        indeterminate={value === "both"}
      />
    </Box>
  );
}
