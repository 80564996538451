import React, { useEffect, useRef, useState } from "react";
import { Box, Button, LinearProgress } from "@mui/material";
import useSWR from "swr";

import MyDialog from "app/Dialog";
import PoHeader from "PDFTemplates/Receive/header";
import ReceivingLineItems from "PDFTemplates/Receive/lineitems";
import { receiveLineItemType, receiveType, uploadReceiveDocument } from "api/receive";
import { IPO } from "api/po";
import { IVendor } from "api/vendor";

import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { takeSnapshot } from "logic/pdf";
import { getAllModelDocuments } from "api/document";
import Toast from "app/Toast";
import { formatTimestampToDate } from "logic/date";

export default function ReceivePdfModal({
  open,
  receiveId,
  onClose,
  onDone,
}: {
  open: boolean;
  receiveId: string;
  onClose: () => void;
  onDone?: () => void;
}) {
  const { data: receive } = useSWR<receiveType>(open ? `/receive/${receiveId}` : null);
  const { data: receiveLineItems } = useSWR<{ result: receiveLineItemType[]; total: number }>(
    open ? `/rcvlineitem?ReceiveId=${receiveId}` : null
  );
  const { data: po } = useSWR<IPO>(receive?.POId ? `/po/${receive?.POId}` : null);
  const { data: vendor } = useSWR<IVendor>(po?.VendorId ? `/vendor/${po?.VendorId}` : null);

  const [uploading, setUploading] = useState(false);
  const [uploaded, setUploaded] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const lineItemsRef = useRef<HTMLTableElement | null>(null);

  const handleDownload = async (download?: boolean) => {
    try {
      if (headerRef.current && lineItemsRef.current) {
        const scale = 0.26;
        const doc = new jsPDF();
        const pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();

        // generate image urls from qr codes in table
        const qrcodeUrls: string[] = [];
        const qrcodeIds = lineItemsRef.current.getElementsByClassName("qrcode-column");
        for (const td of qrcodeIds) {
          const { data } = await takeSnapshot({
            docWidth: pageWidth,
            element: td as HTMLElement,
          });
          qrcodeUrls.push(data);
        }

        await doc.html(headerRef.current, {
          x: 0,
          y: 0,
          html2canvas: { scale, letterRendering: true, useCORS: true, taintTest: true },
        });

        autoTable(doc, {
          startY: 100,
          html: lineItemsRef.current,
          useCss: true,
          margin: { bottom: 50 },
          bodyStyles: { minCellHeight: 40 },
          headStyles: {
            lineWidth: {
              bottom: 0.5,
            },
            lineColor: "#6a6a6a",
          },
          columnStyles: {
            0: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            1: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            2: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            3: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            4: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            5: { fontSize: 7, cellWidth: "auto", minCellHeight: 35 },
            6: { fontSize: 7, cellWidth: 30, minCellHeight: 35 },
          },
          didDrawCell: function (data) {
            try {
              if (data.column.index === 6 && data.cell.section === "body") {
                doc.addImage(qrcodeUrls[data.row.index], data.cell?.x + 2, data.cell.y + 2, 30, 34);
              }
            } catch (error) {
              console.log(error);
            }
          },
          didDrawPage(data) {
            doc.setFillColor(28, 37, 50);
            doc.rect(0, pageHeight - 30, pageWidth, 15, "F");

            doc.setFillColor(28, 117, 188);
            doc.circle(-5, pageHeight - 22, 15, "F");

            doc.setTextColor("#fff");
            doc.setFontSize(13);
            doc.text(String(data.pageNumber), 5, pageHeight - 20);
            doc.setFontSize(11);
            doc.text("1.877.377.6769", 30, pageHeight - 20);
            doc.text("Quote@dspmanufacturing.com", 120, pageHeight - 20);
          },
        });

        if (download) {
          doc.save(`${receive?.number}-${formatTimestampToDate(Number(new Date()))}`);
        }
        return doc.output("blob");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const upload = async () => {
      try {
        setUploading(true);
        const docs = await getAllModelDocuments("receive", receiveId);
        if (docs.length < 1 || docs.result?.length < 1) {
          const pdf = await handleDownload(true);
          if (pdf) {
            await uploadReceiveDocument({
              blob: pdf,
              receiveId,
              receiveNumber: receive?.number,
            });

            Toast("Receive documents uploaded", "success");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setUploading(false);
        setUploaded(true);
        onDone && onDone();
      }
    };
    if (receiveId && receive && receiveLineItems && po && vendor && open && !uploaded) {
      upload();
    }
  }, [onDone, open, po, receive, receive?.number, receiveId, receiveLineItems, uploaded, vendor]);

  return (
    <MyDialog title="Receive pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth disabled={uploading}>
      <Box sx={{ height: "80vh", overflowY: "auto" }}>
        <Button disabled={uploading} sx={{ ml: 3, mb: 2 }} variant="contained" onClick={() => handleDownload(true)}>
          {uploading ? "Uploading" : "Download"}
        </Button>
        {receive && receiveLineItems && po && vendor ? (
          <div style={{ width: "21cm", height: "29cm", border: "1px solid", margin: "auto" }}>
            <PoHeader
              ref={headerRef}
              po={po}
              vendor={vendor}
              receivedByName={receive?.receivedByName}
              receiveNumber={receive?.number}
            />
            <ReceivingLineItems ref={lineItemsRef} data={receive} lines={(receiveLineItems?.result as any) || []} />
          </div>
        ) : (
          <LinearProgress />
        )}
      </Box>
    </MyDialog>
  );
}
