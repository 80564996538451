import React from "react";
import { Box, Typography, Dialog, IconButton, DialogTitle, useMediaQuery } from "@mui/material";
import { CloseRounded } from "@mui/icons-material";
import Button from "app/Button";
import warning from "assets/Warning.svg";
import confirm from "assets/Confirm.svg";

export const NoticeModal = ({
  open,
  onClose,
  onConfirm,
  text,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm?: any;
  text?: string;
}) => {
  const phone = useMediaQuery("(max-width:600px)");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        style={{
          boxShadow: "0px 1px 3px 0px #00000026",
        }}
      >
        <Box></Box>
        <Box display={"flex"} alignItems="center">
          <img loading="lazy" src={warning} alt="danger" width={23} height={23} />
          <DialogTitle sx={{ color: "#F8B200", fontWeight: 600, ml: -2 }}>Notice</DialogTitle>
        </Box>
        <IconButton
          title="Close"
          sx={{
            background: "#EEEFF2",
            borderRadius: 2,
            "& .MuiButtonBase-root ": {
              padding: "4px",
            },
          }}
          onClick={onClose}
        >
          <CloseRounded sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>

      <Box m={1} p={1} height={130}>
        <Typography variant={phone ? "body1" : "h6"}>{text}</Typography>

        <Box mt={4} mb={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            style={{ width: 100, marginRight: 4, background: "#EEEFF2", color: "#202831" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} color="primary" kind="edit" onClick={onConfirm}>
            Confirm
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export const ApprovalModal = ({
  open,
  onClose,
  onConfirm,
  text,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm?: any;
  text?: string;
}) => {
  const phone = useMediaQuery("(max-width:600px)");

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        style={{
          boxShadow: "0px 1px 3px 0px #00000026",
        }}
      >
        <Box></Box>
        <Box display={"flex"} alignItems="center">
          <img loading="lazy" src={confirm} alt="danger" width={23} height={23} />
          <DialogTitle sx={{ color: "#44B678", fontWeight: 600, ml: -2 }}>Confirmation</DialogTitle>
        </Box>
        <IconButton
          title="Close"
          sx={{
            background: "#EEEFF2",
            borderRadius: 2,
            "& .MuiButtonBase-root ": {
              padding: "4px",
            },
          }}
          onClick={onClose}
        >
          <CloseRounded sx={{ width: 16, height: 16 }} />
        </IconButton>
      </Box>

      <Box m={1} p={1} maxHeight={150} height={"auto"}>
        <Typography variant={phone ? "body1" : "h6"}>{text}</Typography>
        <Box mt={4} mb={2} display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="primary"
            variant="contained"
            style={{ width: 100, marginRight: 4, background: "#EEEFF2", color: "#202831" }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button style={{ width: 100 }} color="primary" kind="edit" onClick={onConfirm}>
            Ok
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
