import React from "react";
import { Box, Button, Divider, Popover, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function HelpMenu({
  open,
  anchorEl,
  onClose,
}: {
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
}) {
  const history = useHistory();
  const department = [
    { name: "Sales", route: "/panel/salesHelp" },
    { name: "Purchasing", route: "/panel/purchaseHelp" },
    { name: "Inventory", route: "" },
    { name: "Engineering", route: "/panel/engHelp" },
    { name: "Production", route: "" },
    { name: "Shipping", route: "" },
    { name: "Field Service", route: "" },
    { name: "Accounting", route: "/panel/accountingHelp" },
  ];

  return (
    <Popover
      id="help-menu"
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Box p={1} width={270} overflow="auto">
        <Typography sx={{ fontSize: 12, py: 1, fontWeight: 600, textAlign: "center" }}>Select Departments :</Typography>
        <Divider sx={{ borderColor: "#758195" }} />
        {department?.map((i: any, idx: number) => (
          <Box key={idx} sx={{ my: 1 }}>
            <Button
              onClick={() => {
                history.push(i.route);
                onClose();
              }}
              variant="outlined"
              sx={{ width: "100%" }}
            >
              {i.name}
            </Button>
          </Box>
        ))}
      </Box>
    </Popover>
  );
}
