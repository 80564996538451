import React, { forwardRef } from "react";
import useSWR from "swr";
import styled from "@emotion/styled";

import { ISO } from "api/so";
import { shippingType } from "api/shipping";
import { getId } from "logic/utils";
import { IClient } from "api/client";

const Wrapper = styled.div`
  width: 21cm;
  height: 29.7cm;

  .shipping-label {
    width: 400px;
    background: white;
    border: 2px solid #000;
    padding: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .header {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    border-bottom: 1px solid #000;
    padding-bottom: 10px;
  }

  .logo {
    width: 80px;
    height: 40px;
    background: #000;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 15px;
  }

  .company-info {
    font-size: 12px;
    line-height: 1.4;
  }

  .company-name {
    font-weight: bold;
    font-size: 14px;
  }

  .ship-to {
    margin-bottom: 20px;
  }

  .ship-to-label {
    font-weight: bold;
    margin-bottom: 5px;
  }

  .address {
    font-size: 14px;
    line-height: 1.4;
  }

  .attention {
    font-weight: bold;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }

  .so-number {
    font-family: monospace;
    padding: 5px;
    background: #f5f5f5;
    border: 1px solid #ddd;
    margin: 10px 0;
  }
`;

function Shipping({ shippingId }: { shippingId: string }, ref: any) {
  const { data: shipping } = useSWR<shippingType>(`/shipping/${shippingId}`);
  const { data: so } = useSWR<ISO>(shipping ? `/so/${getId(shipping.SOId)}` : null);
  const { data: soClient } = useSWR<IClient>(so?.ClientId ? `/client/${getId(so?.ClientId)}` : null);

  return (
    <Wrapper ref={ref}>
      <div className="shipping-label">
        <div className="header">
          <div className="logo">DSPM</div>
          <div className="company-info">
            <div className="company-name">Digital Signal Power Manufacturer, Inc.</div>
            436 S. Suddard Ave.
            <br />
            San Bernardino, CA 92401
            <br />
            (909) 338-3333 • FAX (909) 338-3335
          </div>
        </div>

        <div className="ship-to">
          <div className="ship-to-label">Ship To:</div>
          <div className="address">
            <div className="attention">Attention: {so?.shippingCompany}</div>
            {so?.shippingAddress}
            <br />
            {so?.shippingCity}, {so?.shippingState} {so?.shippingZip}
          </div>
        </div>

        <div className="so-number">SO # {so?.number}</div>

        <div className="footer">
          <div>24 hr</div>
          <div>{so?.clientContactPhone || so?.shippingPhone || soClient?.phone}</div>
        </div>
        <div style={{ fontSize: "12px", color: "#666" }}>
          {so?.clientContactEmail || so?.shippingEmail || soClient?.email}
        </div>
      </div>
    </Wrapper>
  );
}

export default forwardRef(Shipping);
