import React, { useCallback, useEffect, useRef, useState } from "react";
import useSWRImmutable from "swr/immutable";
import jsPDF from "jspdf";

import TermsHeader from "PDFTemplates/Quote/TermsHeader";
import QuoteHeader from "PDFTemplates/Quote/header";
import QuoteLineItems from "PDFTemplates/Quote/lineTable";
import QuoteNote from "PDFTemplates/Quote/note";
import Terms, { subList, subText, termsList } from "PDFTemplates/Quote/terms";
import FirstPage from "PDFTemplates/Quote/firstPage";

import MyDialog from "app/Dialog";
import { IQuote } from "api/quote";
import { getId } from "logic/utils";
import { ILineItem } from "api/lineItem";
import { Button, LinearProgress } from "@mui/material";

import numberBg from "assets/pdf/quote/noBg.png";
import { createAModelDocument } from "api/document";
import { formatTimestampToDate } from "logic/date";
import { addElementToPdf, renderList, addWrappedText } from "logic/pdf";
import Toast from "app/Toast";
import QRCode from "app/QRCode";
import { QR } from "logic/QR";
import Swal from "sweetalert2";
import ItemTable from "PDFTemplates/New/Quote/ItemTable";
// import PdfPages from "pages/PdfPages";
import QuotePage0 from "PDFTemplates/New/Quote/QuotePage0";
// import QuotePage1 from "PDFTemplates/New/Quote/QuotePage1";
// import QuotePage2 from "PDFTemplates/New/Quote/QuotePage2";
import QuotePage3 from "PDFTemplates/New/Quote/QuotePage3";
import QuotePage4 from "PDFTemplates/New/Quote/QuotePage4";
import QuotePage5 from "PDFTemplates/New/Quote/QuotePage5";
import QuotePage6 from "PDFTemplates/New/Quote/QuotePage6";
import QuotePage7 from "PDFTemplates/New/Quote/QuotePage7";
import QuotePage8 from "PDFTemplates/New/Quote/QuotePage8";
import QuotePage9 from "PDFTemplates/New/Quote/QuotePage9";
import QuotePage10 from "PDFTemplates/New/Quote/QuotePage10";
import QuotePage11 from "PDFTemplates/New/Quote/QuotePage11";
import QuotePage12 from "PDFTemplates/New/Quote/QuotePage12";
import QuotePage13 from "PDFTemplates/New/Quote/QuotePage13";
import QuotePage14 from "PDFTemplates/New/Quote/QuotePage14";
import QuotePage15 from "PDFTemplates/New/Quote/QuotePage15";
import QuotePage16 from "PDFTemplates/New/Quote/QuotePage16";
import QuotePage17 from "PDFTemplates/New/Quote/QuotePage17";
import QuotePage18 from "PDFTemplates/New/Quote/QuotePage18";
import QuotePage19 from "PDFTemplates/New/Quote/QuotePage19";
import QuotePage20 from "PDFTemplates/New/Quote/QuotePage20";
import QuotePageX from "PDFTemplates/New/Quote/QuotePageX";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

export default function QuotePdfModal({
  quoteId,
  open,
  onClose,
  onDone,
}: {
  quoteId: string;
  open: boolean;
  onClose: () => void;
  onDone: () => void;
}) {
  const [loading, setLoading] = useState(false);
  const [heights, setHeights] = useState<number[]>([]);
  const [uploaded, setUploaded] = useState(false);
  const { data: quote, isLoading } = useSWRImmutable<IQuote>(`/quote/${quoteId}`);
  const { data: lineItems, isLoading: isLinesValidating } = useSWRImmutable<{ result: ILineItem[]; total: number }>(
    `/lineitem?QuoteId=${getId(quote)}`
  );
  // console.log("=============== quote =============");
  // console.log(quote);
  // console.log("===============lineItems=============");
  // console.log(lineItems);

  const MAX_CONTENT_HEIGHT = 850; // Adjust this based on the A4 page content height

  // const contentRef = useRef(null);

  const [totalPagesContent, setTotalPagesContent] = useState<ILineItem[][]>([]);
  const [sectionNumber, setSectionNumber] = useState<number[]>([]);
  useEffect(() => {
    if (lineItems?.result) {
      // setOnoverflow(lineItems.result);

      CalcPages(MAX_CONTENT_HEIGHT, lineItems.result);
    }
  }, [heights]);

  useEffect(() => {
    const fetchDataAndCalculate = async () => {
      if (lineItems?.result) {
        // Run your async function here
        var heights = [];
        for (const item of lineItems.result) {
          const size = await measureDiv(item);
          heights.push(size.height);
        }
        setHeights(heights);
      }
    };
    fetchDataAndCalculate(); // Call the async function
  }, [lineItems]);

  const coverRef = useRef<HTMLDivElement | null>(null);
  const headerRef = useRef<HTMLDivElement | null>(null);
  const linesRef = useRef<HTMLDivElement | null>(null);
  const linesNotesRef = useRef<HTMLDivElement | null>(null);
  const termsHeaderRef = useRef<HTMLDivElement | null>(null);
  const termsContentRef = useRef<HTMLDivElement | null>(null);
  const qrRef = useRef<HTMLDivElement | null>(null);
  const QuotePagesRef = useRef<HTMLDivElement | null>(null);
  const handleDownload = useCallback(
    async (download: boolean) => {
      setLoading(true);
      try {
        if (!quote || !quoteId || !QuotePagesRef.current) {
          return;
        }
        const pdf = new jsPDF("p", "pt", "a4");
        const pages = QuotePagesRef.current.children;

        for (let i = 0; i < pages.length; i++) {
          const page = pages[i] as HTMLElement;
          const canvas = await html2canvas(page, { scale: 1.5 }); // Higher scale for better quality
          const imgData = canvas.toDataURL("image/jpeg", 0.6); // Using JPEG format with quality set to 0.8

          const imgWidth = 595.28; // A4 width in points
          const imgHeight = (canvas.height * imgWidth) / canvas.width;

          if (i > 0) pdf.addPage();
          pdf.addImage(imgData, "JPEG", 0, 0, imgWidth, imgHeight, undefined, "FAST");
        }

        if (download) {
          pdf.save(`Quote_${quote.number}.pdf`);
        }

        const generatedPdf = pdf.output("blob");
        await createAModelDocument({
          model: "quote",
          id: getId(quote),
          file: generatedPdf,
          description: `${new Date().toJSON().slice(0, 19)} - ${quote.number}`,
          name: `Quote_${quote.number}.pdf`,
          fileName: `Quote_${quote.number}.pdf`,
        });
        setUploaded(true);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [quote, quoteId]
  );

  const getQrUrl = () => {
    const qr = new QR("quote");
    return qr.generateQuoteUrl({ quoteId: quoteId });
  };

  if (isLoading || isLinesValidating) {
    return (
      <MyDialog title="Quote PDF" open={open} onClose={onClose}>
        <div style={{ width: "21cm", height: "29cm", margin: "auto" }}>
          <LinearProgress />
        </div>
      </MyDialog>
    );
  }
  const QuoteComponents = [
    { component: <QuotePage0 />, key: "CoverPage" },

    // Dynamically add QuotePageX components based on quotePageXNumber
    ...Array.from({ length: totalPagesContent.length }, (_, index) => ({
      component: (
        <QuotePageX
          data={quote}
          page={index}
          pageNumber={index + 3} // Start from page 3
          items={totalPagesContent[index]}
          QrCode={<QRCode width={69} height={69} value={getQrUrl()} />}
          sectionNumbers={sectionNumber}
        />
      ),
      key: `pagex${index + 3}`, // Adjust key accordingly
    })),
    { component: <QuotePage3 data={quote} pageNumber={3} />, key: "page3" },
    { component: <QuotePage4 data={quote} pageNumber={4} />, key: "page4" },
    { component: <QuotePage5 data={quote} pageNumber={5} />, key: "page5" },
    { component: <QuotePage6 data={quote} pageNumber={6} />, key: "page6" },
    { component: <QuotePage7 data={quote} pageNumber={7} />, key: "page7" },
    { component: <QuotePage8 data={quote} pageNumber={8} />, key: "page8" },
    { component: <QuotePage9 data={quote} pageNumber={9} />, key: "page9" },
    { component: <QuotePage10 data={quote} pageNumber={10} />, key: "page10" },
    { component: <QuotePage11 data={quote} pageNumber={11} />, key: "page11" },
    { component: <QuotePage12 data={quote} pageNumber={12} />, key: "page12" },
    { component: <QuotePage13 data={quote} pageNumber={13} />, key: "page13" },
    { component: <QuotePage14 data={quote} pageNumber={14} />, key: "page14" },
    { component: <QuotePage15 data={quote} pageNumber={15} />, key: "page15" },
    { component: <QuotePage16 data={quote} pageNumber={16} />, key: "page16" },
    { component: <QuotePage17 data={quote} pageNumber={17} />, key: "page17" },
    { component: <QuotePage18 data={quote} pageNumber={18} />, key: "page18" },
    { component: <QuotePage19 data={quote} pageNumber={19} />, key: "page19" },
    { component: <QuotePage20 data={quote} pageNumber={20} />, key: "page20" },
  ];

  const transformList = (inputList: number[]): number[] => {
    return inputList.reduce((accumulator: number[], current: number, index: number) => {
      // If it's the first element, just add it as is
      if (index === 0) {
        accumulator.push(current);
      } else {
        // Calculate the cumulative sum
        const newValue = accumulator[index - 1] + current;
        accumulator.push(newValue);
      }
      return accumulator;
    }, []);
  };
  function CalcPages(maxHeight: number, totalItems: ILineItem[]) {
    const pages: ILineItem[][] = [];
    let currentPage: ILineItem[] = [];
    let cumulativeHeight = 0;

    // Define the height for the first page
    const firstPageMaxHeight = maxHeight * (55 / 100);
    let isFirstPage = true; // Flag to check if it's the first page
    var count = 0;
    for (const item of totalItems) {
      // const itemHeight = ((item.description ? countTrimmedLines(item.description) : 1) * 16) + 40;
      const itemHeight = heights[count] + 40 + 16;
      count += 1;
      // Check if the cumulative height exceeds the current page's max height
      if (
        (isFirstPage && cumulativeHeight + itemHeight > firstPageMaxHeight) ||
        (!isFirstPage && cumulativeHeight + itemHeight > maxHeight)
      ) {
        // Push the current page to pages
        pages.push(currentPage);
        currentPage = []; // Reset current page
        cumulativeHeight = 0; // Reset cumulative height

        // Set the flag to false after the first page
        if (isFirstPage) {
          isFirstPage = false; // Switch to normal behavior for subsequent pages
        }
      }

      currentPage.push(item); // Add the item to the current page
      cumulativeHeight += itemHeight; // Update the cumulative height
    }

    // If there are items in currentPage, push them to pages
    if (currentPage.length > 0) {
      pages.push(currentPage);
    }
    // console.log('==============pages==============');
    // console.log(pages);
    // console.log('====================================');
    const lengths: number[] = transformList(pages.map((innerList) => innerList.length));

    setSectionNumber(lengths);
    // Update the state with the calculated pages
    setTotalPagesContent(pages);
  }

  const Quote = QuoteComponents.map((item, index) => {
    const pageNumber = index;

    return {
      component: React.cloneElement(item.component, { pageNumber }), // Pass pageNumber as a prop
      key: item.key, // Keep the original key for identification
    };
  });

  function measureDiv(item: ILineItem): Promise<{ width: number; height: number }> {
    return new Promise((resolve) => {
      // Create a temporary container
      const tempDiv = document.createElement("div");

      // Apply styles for offscreen positioning and sizing
      tempDiv.style.position = "absolute";
      tempDiv.style.top = "-9999px";
      tempDiv.style.left = "-9999px";
      tempDiv.style.width = "672.938px";
      tempDiv.style.height = "auto";

      // Append the tempDiv to the document body
      document.body.appendChild(tempDiv);

      // Render the ItemTable component into tempDiv
      ReactDOM.render(
        <div className="w-full h-auto">
          <ItemTable
            groupLine={item.group && item.sort ? `[${item.group}]-${item.sort}` : ""}
            description={item.description ? extractDescriptionParts(item.description) : null}
            qty={item.qty}
            unitPrice={item.price}
            totalPrice={item.total}
            descriptionElement={null}
          />
        </div>,
        tempDiv
      );

      // Use requestAnimationFrame to measure after rendering is complete
      requestAnimationFrame(() => {
        const dimensions = {
          width: tempDiv.offsetWidth,
          height: tempDiv.offsetHeight,
        };

        // Clean up
        ReactDOM.unmountComponentAtNode(tempDiv);
        document.body.removeChild(tempDiv);

        // Resolve with the measured dimensions
        resolve(dimensions);
      });
    });
  }

  return (
    <MyDialog
      title="Quote PDF"
      open={open}
      onClose={() => {
        if (loading) {
          Swal.fire({
            title: "Generating PDFs",
            text: "Please wait while we generate the PDFs for you, If you are sure you want to quit you can generate PDFs from document tab.",
            icon: "info",
            showCancelButton: true,
          }).then((r) => {
            if (r.isConfirmed) {
              onClose();
            }
          });
        } else {
          onClose();
        }
      }}
      maxWidth="lg"
      fullWidth
    >
      <Button variant="contained" disabled={loading} onClick={() => handleDownload(true)}>
        Download
      </Button>

      <div className="flex-1 overflow-y-auto bg-gray-100" ref={QuotePagesRef}>
        {Quote.map(({ component, key }) => (
          <div
            className="w-[21cm] h-[29.7cm] bg-white border border-gray-300 mx-auto my-4 shadow-lg pdf-page"
            key={key}
          >
            {component}
          </div>
        ))}
      </div>
    </MyDialog>
  );
}
function extractDescriptionParts(description: string) {
  // Check if the description contains any of the delimiters
  if (
    !description.includes("*") &&
    !description.includes(";") &&
    !description.includes(",") &&
    !description.includes("⚭")
  ) {
    return [description.trim()];
  }

  // Split the description into lines based on the delimiters: *, ;, or ,
  // const lines = description.split(/[\*\;\,]\s*/);
  const lines = description.split(/[\*\;\,\⚭]\s*/);

  // Trim each line and filter out any empty lines
  return lines.map((line) => line.trim()).filter((line) => line.length > 0);
}
