import React from "react";
import { Box, Checkbox, CircularProgress, FormControlLabel, useMediaQuery } from "@mui/material";
import { Formik, Form } from "formik";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { checkVariants, updateCheck } from "api/units";
import Dialog from "app/Dialog";
import Button from "app/Button";
import TextField from "app/TextField";

export default function CheckedModal({
  open,
  itemId,
  model,
  variant,
  initialValues,
  onClose,
}: {
  open: boolean;
  model: "unit";
  itemId: string;
  variant: checkVariants;
  initialValues?: { note?: string; check?: boolean };
  onClose: (p?: any) => void;
}) {
  const phone = useMediaQuery("(max-width:600px)");
  const handleSubmit = async (data: { check: boolean; note: string }, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
      const resp = await updateCheck({ id: itemId, model, variant, check: data.check, note: data.note });
      if (resp && data?.check === true) {
        mutate(`/unit/${itemId}`);
        onClose();
        toast.success("Success Checked");
      }
    } catch (error) {
      console.log(error);
      toast.error("Request Failed");
    } finally {
      mutate(`/unit/${itemId}`);
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} title={`Checked Modal`} maxWidth="xs" fullWidth>
      <Box height={"auto"} my={phone ? 1 : 2}>
        <Formik
          initialValues={{ check: initialValues?.check || false, note: initialValues?.note || "" } as any}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, getFieldProps, isSubmitting }) => (
            <Form>
              <Box width="100%" display="flex" alignItems="center" flexDirection="column" gap={1}>
                <TextField
                  label="Note"
                  {...getFieldProps("note")}
                  multiline
                  rows={3}
                  fullWidth
                  disabled={isSubmitting}
                />
                {!initialValues?.check && (
                  <FormControlLabel {...getFieldProps("check")} control={<Checkbox />} label="Confirm" />
                )}
                {isSubmitting ? (
                  <CircularProgress />
                ) : (
                  <Button
                    type="submit"
                    kind="add"
                    style={{ flex: 1 }}
                    dialogMode
                    disabled={!values?.check || isSubmitting}
                  >
                    submit
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export function NoteModal({
  open,
  initialValues,
  onClose,
}: {
  open: boolean;
  initialValues?: { note?: string; check?: boolean };
  onClose: (p?: any) => void;
}) {
  const handleSubmit = async (data: { check: boolean; note: string }, { setSubmitting }: any) => {
    try {
      setSubmitting(true);
    } catch (error) {
      console.log(error);
      toast.error("Request Failed");
    } finally {
      onClose();
      setSubmitting(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} title={`Note Modal`}>
      <Box width={300} height={"auto"} m={2}>
        <Formik
          initialValues={{ check: initialValues?.check || false, note: initialValues?.note || "" } as any}
          enableReinitialize
          onSubmit={handleSubmit}
        >
          {({ values, getFieldProps, isSubmitting }) => (
            <Form>
              <Box width="100%" display="flex" alignItems="center" flexDirection="column" gap={1}>
                <TextField
                  label="Note"
                  {...getFieldProps("note")}
                  multiline
                  rows={3}
                  fullWidth
                  disabled={isSubmitting}
                />
                {!initialValues?.check && (
                  <FormControlLabel {...getFieldProps("check")} control={<Checkbox />} label="Confirm" />
                )}
                <Button
                  type="submit"
                  kind="add"
                  style={{ flex: 1 }}
                  dialogMode
                  disabled={!values?.check || isSubmitting}
                >
                  submit
                </Button>
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}
