import { delete_, get, patch, post } from ".";
import { POLineItem } from "./po";
import { createAModelDocument } from "./document";
import { formatTimestampToDate } from "logic/date";
import { addPhoto } from "./photo";
import { getId } from "logic/utils";

export type receiveCartItemType = {
  _itemNo?: string;
  _itemDescription?: string;
  _LineItemObject?: POLineItem;
  POLineItemId: string;
  RMALineItemId: string;
  ItemId: string;
  photo?: File[];
  received: number;
  qty: number;
};

export type receiveType = {
  id: string;
  poNumber: string;
  number: string;
  POId: string;
  SOId?: string;
  soNumber: string;
  ReceivedById: string;
  receivedByName: string;
  date: number;
  qty: number;
  deleted: false;
  createdAt: number;
  updatedAt: number;
  __v: number;
};

export type receiveLineItemType = {
  id: string;
  RMALineItemId?: string;
  POLineItemId: string;
  POId: string;
  quantity: number;
  qty: number;
  ItemId: string;
  poNumber: string;
  number: string;
  soNumber: string;
  receivedBy: string;
  POLineItemObject?: any;
  date: number;
  itemLocation: string;
  itemDescription: string;
  item: string;
  itemNo: string;
  ordered: number;
  recId: string;
  photo: File;
  group: 1;
  sort: 1;
  ReceiveId: "643ea92fde295b25f3e70f2d";
  ReceiveNo: "RCV17791";
  receiveDate: number;
  itemName: "BREAKER, 1P, 32A, 277V, D CURVE";
  priceForEach: 6.8;
  total: 13.6;
  deleted: boolean;
  updatedAt: number;
  createdAt: number;
  __v: number;
};

export const createReceive = async (data: {
  lines: {
    RMALineItemId?: string;
    POLineItemId?: string;
    quantity?: number;
    photo?: File[];
    ItemId?: string;
  }[];
  POId?: string;
  RMAId?: string;
  receiveDate?: number;
}): Promise<receiveType> => {
  try {
    const receive = await post(data.RMAId ? "/rmareceive" : "/receive", { ...data, photo: undefined });

    let photoPromises = [];
    for (let i = 0; i < data.lines.length; i++) {
      const line = data.lines[i];
      if (line.photo) {
        photoPromises.push(
          new Promise((resolve, reject) => {
            if (line.photo) {
              addPhoto({
                id: getId(receive),
                model: "receive",
                photo: line.photo,
              })
                ?.then((d) => resolve(d))
                ?.catch((e) => reject(e));
            }
          })
        );
      }
    }
    const photoResp = await Promise.all(photoPromises);

    return { ...receive, photoResp };
  } catch (error) {
    throw error;
  }
};

export const updateReceive = (id: string, data: Partial<receiveType>) => {
  return patch(`/receive/${id}`, data);
};

export const deleteReceive = (id: string) => {
  return delete_(`/receive/${id}`);
};

export const uploadReceiveDocument = async ({
  blob,
  receiveId,
  receiveNumber,
}: {
  receiveNumber?: string;
  receiveId: string;
  blob: Blob | File;
}) => {
  try {
    await createAModelDocument({
      model: "receive",
      id: receiveId,
      file: blob,
      description: `Received document${receiveNumber ? `-${receiveNumber}` : ""}, ${formatTimestampToDate(new Date())}`,
      fileName: "receive.pdf",
      name: "receive.pdf",
    });

    const { POId } = await get(`/receive/${receiveId}`);
    const { SOId } = await get(`/po/${POId}`);

    if (SOId) {
      await createAModelDocument({
        model: "so",
        id: SOId,
        file: blob,
        description: `Received document${receiveNumber ? `-${receiveNumber}` : ""}, ${formatTimestampToDate(
          new Date()
        )}`,
        fileName: "receive.pdf",
        name: "receive.pdf",
      });
    }
  } catch (error) {
    throw error;
  }
};
