import { get, put, delete_ } from "api";

export const getTableColumnsFromServer = ({ name }: { name: string }) => {
  return get("/tableorder", { params: { name } });
};

export const setTableColumnsOnServer = async (data: { name: string; order: string[]; configs?: any }) => {
  return put("/tableorder", data);
};

export const clearTableColumnsOnServer = async () => {
  return delete_("/tableorder");
};
