import { delete_, post } from "api";

export type photoType = {
  id: string;
  path: string;
  createdAt: number;
  updatedAt: number;
  __v: number;
};

export const addPhoto = ({ model, id, photo }: { model: string; id: string; photo?: File | File[] | null }) => {
  if (!photo) {
    return;
  }
  if (Array.isArray(photo)) {
    return Promise.all(
      photo.map((f) => {
        const formData = new FormData();
        formData.append("photo", f);

        return post<photoType[]>(`/photo/${model}/${id}`, formData);
      })
    );
  } else {
    const formData = new FormData();
    formData.append("photo", photo);

    return post<photoType[]>(`/photo/${model}/${id}`, formData);
  }
};

export const deletePhoto = (id: string) => {
  return delete_(`/photo/${id}`);
};

export const getPhotos = (
  data: {
    id: string;
    type: string;
  }[]
) => {
  return post<photoType[]>(`/photo/models`, data);
};
