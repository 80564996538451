import React, { ReactNode, useState } from "react";
import { useHistory } from "react-router";
import { Box } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import ChatDrawer from "features/Chat/Drawer";
import MainAppBar from "./AppBar";
import BottomNav from "app/BottomNav";
import MainDrawer from "./MainDrawer";

export const drawerWidth = 221;
export const chatDrawerWidth = 340;

export default function PanelLayout({ children }: { children: ReactNode }) {
  const phone = useMediaQuery("(max-width:900px)");
  const history = useHistory();
  const [value, setValue] = React.useState("/panel/");
  const [mainDrawerOpen, setMainDrawerOpen] = useState(false);
  const [chatDrawerOpen, setChatDrawerOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    history.push(newValue);
  };

  return (
    <>
      <Box style={{ flex: 1, display: "flex" }}>
        <MainAppBar
          chatDrawerOpen={chatDrawerOpen}
          mainDrawerOpen={mainDrawerOpen}
          openChatDrawer={() => setChatDrawerOpen(true)}
          openMainDrawer={() => setMainDrawerOpen(true)}
          closeChatDrawer={() => setChatDrawerOpen(false)}
          closeMainDrawer={() => setMainDrawerOpen(false)}
        />
        <MainDrawer open={mainDrawerOpen} onClose={() => setMainDrawerOpen(false)} />
        <main
          style={{
            zIndex: 5,
            // flexGrow: 0.75,
            width: "100%",
            transition: "all 300ms ease-in-out",
            marginLeft: -drawerWidth,
            marginRight: -chatDrawerWidth,
            ...(mainDrawerOpen && {
              marginLeft: 0,
            }),
            ...(chatDrawerOpen && {
              marginRight: 0,
            }),
          }}
        >
          <Box
            style={
              phone
                ? { flexGrow: 1, padding: "1em", paddingBottom: "6em" }
                : { flexGrow: 1, padding: "1em", marginRight: chatDrawerOpen ? "20px" : 0 }
            }
          >
            <div style={{ minHeight: "60px" }} />
            {children}
          </Box>
        </main>
        <ChatDrawer open={chatDrawerOpen} onClose={() => setChatDrawerOpen(false)} />
      </Box>
      {phone && <BottomNav value={value} handleChange={handleChange} />}
    </>
  );
}
