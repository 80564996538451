import React, { useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { BrowserRouter } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { SWRConfig } from "swr";

import { theme } from "./theme";
import { useAppDispatch } from "./store";
import { getCurrentEmployee } from "./features/Session/sessionsSlice";
import { initializeMessaging } from "logic/notification";
import { SocketProvider } from "logic/Socket/Provider";

import "react-toastify/dist/ReactToastify.css";
import "./styles/main.css";
import BaseRouter from "./Router";

// Delete this after using baseurl somewhere
import * as config from "./api/config";
import { get } from "./api";
import { ChatSocketProvider } from "logic/Chat/ChatContext";
import { LockProvider } from "common/Lock";
import { PortalProvider } from "logic/PortalContext";
import { ErrorBoundary } from "react-error-boundary";
import OmniBar from "common/OmniBar";
console.log(config.BaseUrl);
// ---------------------------

function App() {
  const [openOmni, setOpenOmni] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getCurrentEmployee());
  }, [dispatch]);

  useEffect(() => {
    initializeMessaging();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.ctrlKey || event.metaKey) && event.shiftKey && event.key === " ") {
        event.preventDefault();
        setOpenOmni((p) => !p);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ErrorBoundary fallback={<div>Something went wrong</div>}>
        <SWRConfig value={{ fetcher: get, errorRetryCount: 2 }}>
          <BrowserRouter>
            <ChatSocketProvider>
              <ThemeProvider theme={theme}>
                <CssBaseline />
                <SocketProvider>
                  <LockProvider>
                    <PortalProvider>
                      {openOmni && <OmniBar open={openOmni} onClose={() => setOpenOmni(false)} />}

                      <BaseRouter />
                    </PortalProvider>
                  </LockProvider>
                </SocketProvider>
              </ThemeProvider>
            </ChatSocketProvider>
          </BrowserRouter>
        </SWRConfig>
      </ErrorBoundary>
    </LocalizationProvider>
  );
}

export default App;
