import React, { useMemo, useState } from "react";
import { Box } from "@mui/material";
import DataTable from "common/DataTable";
import DataGridAction from "common/DataGridAction";
import Confirm from "common/Confirm";
import { delete_ } from "api";
import { mutate } from "swr";
import Toast from "app/Toast";

function BlogTable({ onRowSelected, refresh }: { onRowSelected: any; refresh: any }) {
  const itemColumns = useMemo(() => {
    let res: any[] = [
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="view"
                style={{ cursor: "pointer" }}
                onClick={() => onRowSelected(data)}
                openAs={"current_window"}
              />
            </Box>
          );
        },
      },
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          function setRefresh(arg0: (p: any) => any) {
            throw new Error("Function not implemented.");
          }

          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="delete"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Confirm({
                    text: "Are you sure you want to delete this post?",
                    async onConfirm() {
                      try {
                        await delete_(`/post/${data?.id}`);
                        mutate(`/post`);
                        Toast("Post deleted successfully", "success");
                        setRefresh((p) => p + 1);
                      } catch (error) {
                        console.log(error);
                      }
                    },
                  });
                }}
              />
            </Box>
          );
        },
      },
      { field: "createdAt", headerName: "Date", defaultWidth: 170, defaultOperator: "startsWith", type: "date" },
      { field: "title", headerName: "Title", minWidth: 150, },
      { field: "authorName", headerName: "Author Name", minWidth: 150 },
      { field: "topic", headerName: "Topic", minWidth: 150 },
      { field: "summary", headerName: "Summary", defaultWidth: 400, flex: 1 },
    ];
    return res;
  }, [onRowSelected]);

  return (
    <Box width="100%" display="grid">
      <DataTable rowHeight={28} key={refresh} columns={itemColumns} url={`/post`} />
    </Box>
  );
}

export default BlogTable;
