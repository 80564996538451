import React, { useMemo, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { BasePaper } from "app/Paper";
import DataGridAction from "common/DataGridAction";
import { PostType } from "api/magazine";
import  { mutate } from "swr";
import DataTable from "common/DataTable";
import Confirm from "common/Confirm";
import { delete_ } from "api";
import Toast from "app/Toast";
import ViewImageModal from "./modals/ViewImageModal";

const PostImages = ({ selectedPost }: { selectedPost?: PostType }) => {
  const phone = useMediaQuery("(max-width:600px)");
  const [refresh, setRefresh] = useState(0);
  const [viewModal, setViewModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const imageColumns = useMemo(() => {
    let res: any[] = [
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="view"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setViewModal(true);
                  setSelectedImage(data);
                }}
              />
            </Box>
          );
        },
      },
      {
        field: "action",
        type: "action",
        initialWidth: 30,
        cellRenderer({ data }: any) {
          return (
            <Box display="flex" gap={1} alignItems="center">
              <DataGridAction
                icon="delete"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  Confirm({
                    text: "Are you sure you want to delete this image?",
                    async onConfirm() {
                      try {
                        await delete_(`/photo/${data?.id}`);
                        mutate(`/photo/post/${selectedPost?.id}`);
                        Toast("Photo deleted successfully", "success");
                        setRefresh((p) => p + 1);
                      } catch (error) {
                        console.log(error);
                      }
                    },
                  });
                }}
              />
            </Box>
          );
        },
      },
      { field: "createdAt", headerName: "Date", defaultOperator: "startsWith", type: "date", flex: 1 },
    ];
    return res;
  }, [selectedPost?.id]);

  return (
    <>
      {viewModal && (
        <ViewImageModal open={viewModal} onClose={() => setViewModal(false)} selectedImage={selectedImage} />
      )}
      <BasePaper style={{ height: "100%" }} sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          <DataTable
            height={"calc(100vh - 220px)"}
            rowHeight={28}
            key={refresh}
            columns={imageColumns}
            url={`photo/post/${selectedPost?.id}`}
          />
        </Box>
      </BasePaper>
    </>
  );
};

export default PostImages;
