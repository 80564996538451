import React, { forwardRef } from "react";

const PoTotal = forwardRef<HTMLDivElement, { sum: number; notes?: string }>(({ sum, notes }, ref) => {
  return (
    <div style={{ marginTop: 300 }}>
      <div style={{ width: "21cm" }} ref={ref}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              background: "#F8B11B",
              width: "200px",
              height: "30px",
              borderBottomLeftRadius: "10px",
              borderBottomRightRadius: "10px",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>Total:</p>
            <p style={{ fontSize: "12px", fontWeight: "bold" }}>{sum}$</p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
            marginBottom: "20px",
            marginLeft: "5px",
          }}
        >
          <div style={{ width: "50%" }}>
            <p style={{ fontStyle: "italic", color: "red" }}>
              Note: Any product that requires a data sheet, drawings or specs, needs to be made to the DSPM
              specifications provided on documents or it will not be accepted.
            </p>
            <p style={{ fontSize: "12px", margin: 0 }}>Notes:</p>
            <p style={{ fontSize: "12px", maxWidth: "500px" }}>{notes}</p>
          </div>

          <div style={{ marginTop: "20px", marginRight: "10px" }}>
            <p style={{ fontSize: "medium", fontWeight: "bold", margin: 0 }}>DSPM Manufacturing Inc.</p>
            <p style={{ fontSize: "small", margin: 0.5, color: "#545454" }}>
              439 S. Stoddard Ave,
              <br /> San Bernardino, CA 92401{" "}
            </p>
            <p style={{ fontSize: "small", margin: 0, color: "#545454" }}>(909) 930-3353</p>
            <p style={{ fontSize: "small", margin: 0, color: "#545454" }}>www.DSPManufacturing.com</p>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PoTotal;
