import React, { useState } from "react";
import { Box, Typography, IconButton, Button, CircularProgress, SxProps, Theme, useMediaQuery } from "@mui/material";
import useSWR from "swr";
import { formatTimestampToDate } from "logic/date";
import { checkVariants, clearCheck } from "api/units";
import { ApprovalModal } from "common/NoticeModal";
import { useLock } from "common/Lock";
import Toast from "app/Toast";
import CheckedModal from "./CheckedModal";
import noteIcon from "assets/icons/Note.svg";
import checkIcon from "assets/icons/tickCheck.png";

export default function Checked({
  model,
  itemId,
  variant,
  sx,
}: {
  model: "unit";
  itemId: string;
  variant: checkVariants;
  sx?: SxProps<Theme>;
}) {
  const phone = useMediaQuery("(max-width:600px)");
  const [deleteModal, setDeleteModal] = useState(false);
  const { lock } = useLock();
  const { data, isValidating, mutate } = useSWR(`/${model}/${itemId}`);
  const isChecked = data?.stage?.[`${variant}Check`];
  const checkedBy = data?.stage?.[`${variant}CheckedBy`];
  const checkDate = data?.stage?.[`${variant}CheckDate`];
  const note = data?.stage?.[`${variant}CheckNote`];

  const [clearing, setClearing] = useState(false);
  const [modal, setModal] = useState(false);

  const handleDelete = async () => {
    try {
      setClearing(true);
      await clearCheck({ id: itemId, model, variant });
      Toast("Record cleared", "success");
    } catch (error) {
      console.log(error);
      Toast("Request failed", "error");
    } finally {
      setClearing(false);
      mutate(`/${model}/${itemId}`);
      setDeleteModal(false);
    }
  };

  let content = <></>;

  if (isValidating) {
    content = <CircularProgress size="2rem" />;
  } else if (isChecked) {
    content = (
      <>
        <Box display="flex" alignItems="center" flexDirection={phone ? "column" : "row"} textAlign={"center"}>
          <IconButton onClick={() => setModal(true)}>
            <img alt="note" src={noteIcon} />
          </IconButton>
          <Typography sx={{ fontSize: phone ? 10 : 12, background: "#F4F4F4", p: 1, mx: 1, borderRadius: 2 }}>
            Checked By {checkedBy?.username}
          </Typography>
          <Typography sx={{ fontSize: phone ? 10 : 12, background: "#F4F4F4", p: 1, borderRadius: 2 }}>
            {formatTimestampToDate(checkDate)}
          </Typography>

          <Button
            disabled={lock || clearing}
            sx={{ mx: 1, color: "#1673AA", borderColor: "#1673AA" }}
            onClick={() => setDeleteModal(true)}
            endIcon={<img alt="check" src={checkIcon} />}
          >
            Checked
          </Button>
        </Box>
      </>
    );
  } else {
    content = (
      <Button
        disabled={lock || clearing}
        sx={{ mx: 1, color: "#1673AA", borderColor: "#1673AA" }}
        variant="outlined"
        onClick={() => setModal(true)}
      >
        Checked
      </Button>
    );
  }

  return (
    <>
      {deleteModal && (
        <ApprovalModal
          open={deleteModal}
          onClose={() => setDeleteModal(false)}
          onConfirm={handleDelete}
          text="You are clearing check log ! Are you sure?"
        />
      )}
      <CheckedModal
        model={model}
        variant={variant}
        itemId={itemId}
        open={modal}
        onClose={() => setModal(false)}
        initialValues={{ check: isChecked, note: isChecked ? note : "" }}
      />
      <Box sx={sx}>{content}</Box>
    </>
  );
}
