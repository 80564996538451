import React, { useState } from "react";
import { Box, Popover } from "@mui/material";
import grayMore from "assets/icons/grayMore.svg";
import orangeMore from "assets/icons/orangeMore.svg";
import IconList from "app/IconList";

export default function MoreButton({ children, width }: { children: React.ReactNode; width?: any }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);

  const handleOpenMenu = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  return (
    <>
      <IconList onClick={handleOpenMenu}>
        {open ? <img alt="more" src={orangeMore} /> : <img alt="more" src={grayMore} />}
      </IconList>
      <Popover
        id="more-menu"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box
          p={2}
          width={width || 200}
          overflow="auto"
          color="gray"
          onClick={() => {
            setAnchorEl(null);
            setOpen(false);
          }}
        >
          {children}
        </Box>
      </Popover>
    </>
  );
}
