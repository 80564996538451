import Footer from "../Quote/footer/Footer";
import Header from "../PO/header/Header";
import { formatCurrency } from "logic/utils";
import { formatTimestampToDate } from "logic/date";

import SectionHeader from "./SectionHeader";
export default function SOPage1({data,QrCode,pageNumber}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} QrCode={QrCode}/>
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content({data,QrCode}) {
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <SectionHeader title={"ACCOUNTING SALES COPY"}></SectionHeader>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-row w-full justify-between h-[120px]">
            <div className="flex flex-col justify-end">
            {QrCode}

            </div>
            <div className="flex flex-col bg-[#ececec] p-2 w-[41%] h-full ">
              <div>
                <span className="text-[12px]">Bill to:</span>
              </div>
              <div>
                <span className="text-[10px] font-light">
                  {data.billingCompany}
                </span>
              </div>
            </div>
            <div className="flex flex-col bg-[#ececec] p-2 w-[41%]">
              <div>
                <span className="text-[12px]">Ship to:</span>
              </div>
              <div>
                <span className="text-[10px] font-light">
                 {data.shippingAttn}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[4%]  bg-[#6a6a6a]">
          <div className="flex flex-row p-3 h-[330px]">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row h-full">
                <div className="flex flex-col justify-around">
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      PO Received By:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      SO Processed By:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      SO Issued Date:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      SO Issued By:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Sent to Shipping:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Approval-Sales:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Approval-Accounting:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Approval-Manufacturing:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Approval-Engineering
                    </span>
                  </div>
                </div>
                <div className="flex flex-col ml-[4%] justify-around">
                  <div>
                    <span className="text-white text-[12px] font-bold">
                     {data.receivedBy ? data.receivedBy.firstName : '-'}
                    </span>
                    <span className="text-white text-[12px] font-bold ml-1">
                     {data.receivedBy ? data.receivedBy.lastName : ''}
                    </span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-bold">{data.date ? formatTimestampToDate(data.date) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-bold">{data.requestedByName ? data.requestedByName : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="flex flex-row h-full">
                <div className="flex flex-col justify-around">
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Purchase Order Total:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Sales Tax:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Shipping & Handling:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Product Cost:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Reg. Commission Rate %:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Total Commission Amt:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Commission Name:
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Warranty
                    </span>
                  </div>
                  <div>
                    <span className="p-2 text-white text-[12px] font-light">
                      Net Total:
                    </span>
                  </div>
                </div>
                <div className="flex flex-col ml-[4%] justify-around">
                  <div>
                    <span className="text-white text-[12px]">{data.purchaseOrderTotal ? formatCurrency(data.purchaseOrderTotal) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.salesTaxAmount ? formatCurrency(data.salesTaxAmount) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.shippingAndHandling ? formatCurrency(data.shippingAndHandling) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.totalCommission ? formatCurrency(data.totalCommission) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.commissionLabel ? data.commissionLabel : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.warranty ? data.warranty : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
