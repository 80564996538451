import React from "react";
import { Drawer } from "@mui/material";

import { drawerWidth } from "..";
import DrawerContent from "./Content";

export default function MainDrawer({ open, onClose }: { open: boolean; onClose: () => void }) {
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        ".MuiPaper-root": {
          width: drawerWidth,
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <DrawerContent onClose={onClose} />
    </Drawer>
  );
}
