import React from "react";
import { Box, CircularProgress, FormControlLabel, useMediaQuery, Checkbox } from "@mui/material";
import { Formik, Form } from "formik";
import { mutate } from "swr";
import { host } from "host";
import { createAModelDocument, updateAModelDocument, deleteAModelDocument, IDocument } from "api/document";
import { toast } from "react-toastify";
import PDFPreview from "components/PDFPreview";
import { LockButton, LockProvider, useLock } from "common/Lock";
import { BasePaper } from "app/Paper";
import Dialog from "app/Dialog";
import Button from "app/Button";
import TextField from "app/TextField";
import UploadButton from "app/UploadButton";

interface IDocumentModal {
  open: boolean;
  model: string;
  itemId: string;
  data?: any;
  onDone?: () => void;
  onClose: () => void;
  setRefresh: any;
  sales?: boolean;
}

const mutateDocuments = (type: string, id: string) => {
  return mutate(`/document/${type}/${id}`);
};
function DocumentModalContent({ open, onClose, model, itemId, onDone, data, setRefresh, sales }: IDocumentModal) {
  const phone = useMediaQuery("(max-width:900px)");
  const { lock } = useLock();

  const deleteDocument = async () => {
    try {
      if (data && data.id) {
        await deleteAModelDocument(data.id);
        onDone && onDone();
        mutateDocuments(model, itemId);
        onClose();
        toast.success("Delete Document Successful");
      }
    } catch (error) {
      console.log(error);
      toast.success("No Delete Document");
    } finally {
      setRefresh((prev: any) => prev + 1);
    }
  };

  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      if (data && data?.id) {
        await updateAModelDocument(
          data?.id,
          values?.name,
          values?.number,
          values.file,
          values.description,
          values?.clientRepDocAccess
        );
        onDone && onDone();
        mutateDocuments(model, itemId);
        onClose();
      } else {
        setSubmitting(true);
        await createAModelDocument({ model, id: itemId, ...values });
        mutateDocuments(model, itemId);
        onClose();
        setSubmitting(false);
        toast.success("Add Document Successful");
      }
    } catch (error) {
      console.log(error);
      onClose();
      toast.error("Unsuccessful Add Document");
    } finally {
      setSubmitting(false);
      setSubmitting(false);
      setRefresh((prev: any) => prev + 1);
    }
  };

  const fileExtension = data?.path && data?.path.split(".").pop()?.toLowerCase();
  const isPdf = fileExtension === "pdf" || data?.name.split(".").pop()?.toLowerCase() === "pdf";

  if (!data) {
    return (
      <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth title={`Add Document`}>
        <Box height={data ? "82vh" : "auto"}>
          <Formik
            initialValues={data ? data : ({ ...data, clientRepDocAccess: false } as IDocument)}
            onSubmit={handleSubmit}
          >
            {({ values, handleSubmit, handleBlur, handleChange, setFieldValue, isSubmitting }) => (
              <Form>
                <Box width="100%" display="grid" alignItems="center" gridTemplateColumns={"1fr 1fr"} gap={2}>
                  <FormControlLabel
                    sx={{ "& .MuiTypography-root ": { fontSize: "0.8rem" }, gridColumnEnd: "span 2" }}
                    label={
                      model === "client" ? "View by Client" : model === "rep" ? "View by Rep" : "View by Client/Rep"
                    }
                    checked={values?.clientRepDocAccess || false}
                    value={values?.clientRepDocAccess || false}
                    name="clientRepDocAccess"
                    onChange={(e, nv) => setFieldValue("clientRepDocAccess", nv)}
                    control={<Checkbox size="small" />}
                  />

                  <TextField
                    fullWidth
                    value={values?.name}
                    name="name"
                    label="Name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={data && lock}
                  />
                  <TextField
                    fullWidth
                    value={values?.description}
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={data && lock}
                    style={{ gridColumnEnd: "span 2" }}
                  />

                  <div style={{ gridColumnEnd: "span 2" }}>
                    <UploadButton
                      values={values?.file}
                      onChange={(e: any) => e?.target.files !== null && setFieldValue("file", e.target.files[0])}
                    />
                  </div>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  {isSubmitting ? (
                    <CircularProgress />
                  ) : (
                    <Button
                      type="button"
                      onClick={() => handleSubmit()}
                      kind={data ? "edit" : "add"}
                      disabled={isSubmitting || (data && lock)}
                      // style={{ flex: 1 }}
                      dialogMode
                    >
                      Submit
                    </Button>
                  )}
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      </Dialog>
    );
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen={Boolean(data)} title={`Edit Document`}>
      <Box
        height={data ? "82vh" : "auto"}
        m={2}
        display="grid"
        gridTemplateColumns={phone ? "1fr" : lock ? "1fr 20px" : "1fr 300px"}
        gap={2}
      >
        <Box>
          {data?.path && open && isPdf && (
            <PDFPreview height="80vh" pdf={data.path?.includes("http") ? data.path : host + data.path} />
          )}
          <BasePaper>
            {data?.path && open && (fileExtension === "png" || fileExtension === "jpg") && (
              <img alt="" height="80%" width="60%" src={data.path?.includes("http") ? data.path : host + data.path} />
            )}
          </BasePaper>
        </Box>
        <Formik initialValues={data ? data : ({} as IDocument)} onSubmit={handleSubmit}>
          {({ values, handleBlur, handleChange, setFieldValue, isSubmitting }) => (
            <Form>
              <Box
                width={phone ? "300px" : "100%"}
                display="flex"
                alignItems="center"
                flexDirection="column-reverse"
                my={2}
              >
                <Box width="100%">
                  {!lock && (
                    <>
                      <FormControlLabel
                        sx={{ "& .MuiTypography-root ": { fontSize: "0.8rem" }, gridColumnEnd: "span 2" }}
                        label={
                          model === "client" ? "View by Client" : model === "rep" ? "View by Rep" : "View by Client/Rep"
                        }
                        checked={values?.clientRepDocAccess || false}
                        value={values?.clientRepDocAccess || false}
                        name="clientRepDocAccess"
                        onChange={(e, nv) => setFieldValue("clientRepDocAccess", nv)}
                        control={<Checkbox size="small" />}
                      />
                      <TextField
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        value={values?.name}
                        name="name"
                        label="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={data && lock}
                      />
                      <TextField
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        value={values?.number}
                        name="number"
                        label="Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={data.number || lock}
                      />
                      <TextField
                        style={{ marginBottom: "20px" }}
                        fullWidth
                        value={values?.description}
                        name="description"
                        label="Description"
                        multiline
                        rows={4}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={data && lock}
                      />
                      {!isPdf && (
                        <UploadButton
                          values={values?.file}
                          onChange={(e: any) => e?.target.files !== null && setFieldValue("file", e.target.files[0])}
                        />
                      )}
                      <div style={{ margin: "1em 0" }}>
                        {values.file ? (
                          String((values.file as any).name)
                        ) : data ? (
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            download={data.path.search("blob") > -1 ? "document.pdf" : "document"}
                            href={host + "/api/document/" + (data._id || data.id)}
                          >
                            Download File
                          </a>
                        ) : (
                          ""
                        )}
                      </div>
                      <Box style={{ display: "flex", width: "100%" }}>
                        {data && (
                          <Button
                            type="submit"
                            kind="edit"
                            disabled={isSubmitting || (data && lock)}
                            style={{ flex: 1 }}
                            dialogMode
                          >
                            Save
                          </Button>
                        )}
                        {data && (
                          <Button
                            style={{ marginLeft: "1em" }}
                            onClick={deleteDocument}
                            kind="delete"
                            disabled={isSubmitting || lock}
                            dialogMode
                          >
                            Delete
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </Box>
                {data && (
                  <Box display={"flex"} justifyContent="flex-end" alignItems={"center"} width="100%" my={1}>
                    <LockButton />
                  </Box>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </Dialog>
  );
}

export default function DocumentModal({
  open,
  onClose,
  model,
  itemId,
  onDone,
  data,
  setRefresh,
  sales,
}: IDocumentModal) {
  return (
    <LockProvider>
      <DocumentModalContent
        open={open}
        onClose={onClose}
        model={model}
        itemId={itemId}
        onDone={onDone}
        data={data}
        setRefresh={setRefresh}
        sales={sales}
      />
    </LockProvider>
  );
}
