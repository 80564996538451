import React, { ReactNode } from "react";
import { Box, IconButton } from "@mui/material";

export default function IconList({
  children,
  mx,
  onClick,
  title,
  disabled,
}: {
  children: ReactNode;
  mx?: any;
  onClick?: any;
  title?: string;
  disabled?: boolean;
}) {
  return (
    <Box
      sx={{
        opacity: disabled ? "0.5" : "1",
        width: 40,
        height: 40,
        border: 1,
        borderColor: "#E2E2E2",
        borderRadius: 1,
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        mr: mx || 0.5,
      }}
    >
      <IconButton title={title} onClick={onClick} disabled={disabled}>
        {children}
      </IconButton>
    </Box>
  );
}
