import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import AutoAwesomeRoundedIcon from "@mui/icons-material/AutoAwesomeRounded";
import { Popover, Typography, useMediaQuery, Box, styled, MenuItem, Menu } from "@mui/material";
import { sendMessage } from "api/ai";
import { Margin } from "@mui/icons-material";
import style from "./styles.module.css";
import { post } from "api";

const StyledPopover = styled(Popover)({
  // position: "absolute",
  // transform: "translateX(-9%)",

  "& .MuiPaper-root": {
    backgroundColor: "rgb(64,64,64)",
    // top: "-15%",
    // bottom: "85%",
    // left: "30%",
    color: "white",
    padding: "16px",
    marginTop: "35px",
    borderRadius: "8px",
  },
});

export default function CustomizedInputBase() {
  const phone = useMediaQuery("(max-width:600px)");
  const [value, setValue] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [response, setResponse] = React.useState("");

  const [open, setOpen] = React.useState(false);
  const inputRef = React.useRef<any>(null);

  const handleSendQuestion = async () => {
    try {
      setOpen(true);
      if (value) {
        setIsLoading(true);
        const response = await post<{
          timestamp: "2019-08-24T14:15:22Z";
          message: "string";
        }>(`https://digital.phocuss.com/api/ai/completion/`, { message: value });
        console.log({ response });

        // const response = await sendMessage(value);
        setResponse(response?.message);
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Paper
        component="form"
        onSubmit={(e) => {
          e.preventDefault();
          handleSendQuestion();
        }}
        className={isLoading ? style.boxContainer : ""}
        sx={{
          p: "2px 4px",
          borderRadius: "10px",
          height: 40,
          display: "flex",
          alignItems: "center",
          width: phone ? 150 : 400,
          bgcolor: "rgb(64,64,64)",
          position: "relative",
        }}
        ref={inputRef}
      >
        <InputBase
          value={value}
          onChange={(e: any) => setValue(e.target.value)}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Ask me anything..."
          inputProps={{ style: { backgroundColor: "rgb(64,64,64)", color: "white", fontSize: "12px" } }}
        />

        <IconButton onClick={handleSendQuestion} color="primary" type="button" sx={{ p: "10px" }} aria-label="search">
          <AutoAwesomeRoundedIcon />
        </IconButton>
      </Paper>
      {/* <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
        <MenuItem>{`your answer:  ${isLoading ? "..." : response}`}</MenuItem>
      </Menu> */}
      <StyledPopover
        sx={{
          "& .MuiPaper-root": { width: phone ? "250px" : "380px" },
        }}
        open={open}
        anchorEl={inputRef.current}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <Typography>{`your answer:  ${isLoading ? "..." : response}`}</Typography>
      </StyledPopover>
    </>
  );
}
