import { initializeApp } from "@firebase/app";
import { getAnalytics } from "@firebase/analytics";
import { getMessaging, getToken, onMessage } from "@firebase/messaging";

import { store } from "store";
import { setFirebaseToken } from "features/Session/sessionsSlice";

export const initializeMessaging = () => {
  if (window.location.protocol !== "https") {
    return;
  }
  const firebaseConfig = {
    apiKey: "AIzaSyAqJMPevGqVwPRrOUbP80kXZee_O_Fgla4",
    authDomain: "webfcm-94f4e.firebaseapp.com",
    projectId: "webfcm-94f4e",
    storageBucket: "webfcm-94f4e.appspot.com",
    messagingSenderId: "277927657645",
    appId: "1:277927657645:web:1507322710e375524ac624",
    measurementId: "G-RHE5Z4M47K",
  };

  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  const messaging = getMessaging();

  getToken(messaging, {
    vapidKey: "BH2_sgv9uL-FiPOcjgSJMWhNcQldX409rDakERixor3QqIDmYtjdjtSSN6VrsN33LHZVOLCvS7Im5o7nCIO3MK8",
  })
    .then((currentToken) => {
      if (currentToken) {
        store.dispatch(setFirebaseToken(currentToken));
        console.log(currentToken);
      } else {
        // Show permission request UI
        console.log("No registration token available. Request permission to generate one.");
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });

  onMessage(messaging, (payload) => {
    console.log("Message received. ", payload);
  });
};

export function notifyUser({ title, body }: { title: string; body: string }) {
  // Let's check if the browser supports notifications
  if (!("Notification" in window)) {
    alert("This browser does not support desktop notification");
  }

  // Let's check whether notification permissions have already been granted
  else if (Notification.permission === "granted") {
    // If it's okay let's create a notification
    new Notification(title, { body });
  }

  // Otherwise, we need to ask the user for permission
  else if (Notification.permission !== "denied") {
    Notification.requestPermission().then(function (permission) {
      // If the user accepts, let's create a notification
      if (permission === "granted") {
        new Notification(title, { body });
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them any more.
}
