import Footer from "./footer/Footer";
import Header from "./header/Header";
import SignatureComponent from "./SignatureComponent";
import { Fragment } from "react";
function POpage6({ data }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={6} DocNumber={data?.number} />
      </div>
    </div>
  );
}

export default POpage6;

function Content() {
  const items = [
    "INT. GROUND",
    "XFMR INPUTS",
    "DC BREAKER",
    "CIRCUIT BRKRS",
    "DIGITAL DISPLAY",
    "INT. NEUTRAL",
    "XFMR OUTPUTS",
    "BATTERY (S)",
    "LAMPS / LED's",
    "FUSES",
    "CABLES/GROUND",
    "XFMR TAPS",
    "J - BOX",
    "PC BOARDS",
    "FUSE LINK's",
    "CABLES/NEUTRAL",
    "MAIN INPUT BRKR",
    "PC BOARDS",
    "RELAYS",
    "SCR's",
    "CABLES/BRKRS",
    "MAIN OUTPUT BRKR",
    "EPO",
    "DC CAPACITORS",
    "FANS",
    "INTERFACES",
    "BY-PASS SWITCH",
    "REPO",
    "BATTERY (S)",
    "AC CAP's",
  ];

  const groupItems = (items, groupSize) => {
    const groups = [];
    for (let i = 0; i < items.length; i += groupSize) {
      groups.push(items.slice(i, i + groupSize));
    }
    return groups;
  };
  const groupedItems = groupItems(items, 5);
  const emptyItems = [
    [1, 2, 3, 4, 5],
    [1, 2, 3, 4, 5],
  ];

  const partsData = Array.from({ length: 10 }, (_, index) => {
    return {
      id: index + 1,
      partsUsed: "",
      qty: "",
    };
  });

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
        <div>
          <table className="min-w-full border-collapse">
            <thead>
              <tr>
                <th colSpan={6} className="border border-table border-r-primaryBlue text-start p-[1%]">
                  <div className="flex flex-col items-center">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">ELECTRICAL CONNECTIONS</span>
                  </div>
                </th>
                <th colSpan={4} className="border border-table border-l-primaryBlue text-start p-[1%]">
                  <div className="flex flex-col items-center">
                    <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">COMPONENTS</span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {groupedItems?.map((group, index) => (
                <tr key={index}>
                  {group?.map((item, i) => (
                    <Fragment key={i}>
                      <td
                        className={`border border-table ${
                          i === 3 ? "border-l-primaryBlue" : ""
                        } p-[1%] border-spacing-0 w-[16%]`}
                      >
                        <div className="flex flex-col">
                          <span className="text-[11px] text font-light">{item}</span>
                        </div>
                      </td>
                      <td
                        className={`border border-table ${
                          i == 2 ? "border-r-primaryBlue" : ""
                        } p-[1%] border-spacing-0 w-[4%] bg-[#f6f6f6]`}
                      >
                        <div className="flex flex-col">
                          <span className="text-[12px] font-light"></span>
                        </div>
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}

              {emptyItems?.map((group, index) => (
                <tr key={index}>
                  {group?.map((item, i) => (
                    <Fragment key={i}>
                      <td className={`border border-table ${i == 3 ? "border-l-primaryBlue" : ""} p-[1%]  w-[16%]`}>
                        <div className="flex flex-col">
                          <span className="text-[11px] font-light">&#8203;</span>
                        </div>
                      </td>
                      <td
                        className={`border border-table ${
                          i == 2 ? "border-r-primaryBlue" : ""
                        } p-[1%] border-spacing-0 w-[4%] bg-[#f6f6f6]`}
                      >
                        <div className="flex flex-col">
                          <span className="text-[12px] font-light">&#8203;</span>
                        </div>
                      </td>
                    </Fragment>
                  ))}
                </tr>
              ))}

              <tr className="bg-primaryBlue">
                <td
                  colSpan="10"
                  className="border border-table p-[1%]"
                  // style={{ width: "50%" }}
                >
                  <div className="flex flex-col items-center">
                    <span className="text-[10px] text-white font-bold">
                      If more space is needed please use Secondary Sheet for any &quot;Problems Found&quot;,
                      &quot;Comments&quot; and/or &quot;Parts Used&quot;
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mt-[2%]">
          <div className="flex flex-row flex-1">
            <table className="w-1/2 border-collapse">
              <thead>
                <tr>
                  <th className="border border-table text-start p-[1%]">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">&#8203;</span>
                    </div>
                  </th>

                  <th className="border border-table text-start">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">PARTS USED</span>
                    </div>
                  </th>
                  <th className="border border-table text-start">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Qty</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {partsData?.map((row) => (
                  <tr key={row.id}>
                    <td className="border border-table p-[1%] border-spacing-0 w-[10%]">
                      <div className="flex flex-col">
                        <span className="text-[12px] font-light">{row.id}</span>
                      </div>
                    </td>
                    <td className="border border-table p-[1%] border-spacing-0 w-[72%]]">
                      <div className="flex flex-col">
                        <span className="text-[12px] font-light">{row.partsUsed}</span>
                      </div>
                    </td>
                    <td className="border border-table p-[1%] border-spacing-0 w-[18%]">
                      <div className="flex flex-col">
                        <span className="text-[12px] font-light">{row.qty}</span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <table className="w-1/2 border-collapse">
              <tbody>
                <tr>
                  <td className="border border-table p-[1%] w-1/2 align-top border-l-0">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Problems Found:</span>
                      <span className="text-[12px] font-bold ml-[1%]">-</span>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="border border-table p-[1%] align-top border-l-0">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Comments:</span>
                      <span className="text-[12px] font-bold ml-[1%]">-</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-[2%] ">
          <SignatureComponent />
        </div>
      </div>
    </div>
  );
}
