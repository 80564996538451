import React, { useState } from "react";
import { CircularProgress } from "@mui/material";
import ImageDialog from "./ImageDialog";

export default function Image({
  alt,
  src,
  style,
  preview,
  actionTitle,
  onClick,
}: {
  src?: string;
  alt?: string;
  preview?: boolean;
  style?: React.CSSProperties;
  actionTitle?: string;
  onClick?: () => void;
}) {
  const [open, setOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);

  return (
    <>
      {!loaded && <CircularProgress sx={{ width: 24, height: 24 }} />}
      {open && src && (
        <ImageDialog
          open={open}
          actionTitle={actionTitle}
          imageUrl={src}
          onAction={onClick}
          onClose={() => setOpen(false)}
        />
      )}
      <img
        src={src}
        alt={alt}
        style={style}
        onLoad={() => setLoaded(true)}
        onClick={() => {
          if (preview) {
            setOpen(true);
          } else {
            onClick?.();
          }
        }}
      />
    </>
  );
}
