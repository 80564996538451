import React, { useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import Dialog from "app/Dialog";
import { host } from "host";

export default function ViewImageModal({
  open,
  onClose,
  selectedImage,
}: {
  open: boolean;
  onClose: any;
  selectedImage?: any;
}) {
  const phone = useMediaQuery("(max-width:900px)");

  return (
    <Dialog title="Add Topic" open={open} onClose={onClose} maxWidth="sm">
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <img src={host + selectedImage?.path} alt="" style={{ width: "100%", height: "100%" }} />
      </Box>
    </Dialog>
  );
}
