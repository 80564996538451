import React from "react";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import EmployeeList from "./List";
import ChatList from "./ChatList";
import ProfileInfo from "./ProfileInfo";
import { chatDrawerWidth, phonechatDrawerWidth } from "../../Router/Panel";
import { useChat } from "../../logic/Chat/ChatContext";
import { useMediaQuery } from "@mui/material";

export const colors = {
  textColor: "#484848",
  main: "#ffff",
  secondary: "#f9fafc",
  light: "#afbbc7",
  highlight: "#fff",
};

export default function ChatDrawer({ onClose, open }: { open: boolean; onClose: () => void }) {
  const phone = useMediaQuery("(max-width:600px)");
  const chatSocket = useChat();

  if (!chatSocket) {
    return (
      <div style={{ width: phone ? phonechatDrawerWidth : chatDrawerWidth, flexShrink: 0 }}>
        <LinearProgress />
      </div>
    );
  }

  const { ChatSocket, messages, users, selectedUser, setUsers, setSelectedUser, setMessages, sendPrivateMessage } =
    chatSocket;

  return (
    <Drawer
      anchor="right"
      variant="persistent"
      open={open}
      sx={{
        width: phone ? phonechatDrawerWidth : chatDrawerWidth,
        flexShrink: 0,
        "& .MuiPaper-root": {
          width: chatDrawerWidth + 20,
        },
      }}
    >
      <Box m={1} display="flex" flexDirection="column">
        <ProfileInfo onClose={onClose} />
        {!selectedUser ? (
          <EmployeeList
            users={users}
            socket={ChatSocket}
            onChange={(nu) => {
              setSelectedUser(nu);
              setUsers((prev) => {
                let res = prev.slice();
                const index = res.findIndex((u) => u.userID === nu.userID);
                res[index].hasNewMessages = false;

                return res;
              });
              setMessages(nu.messages);
            }}
          />
        ) : (
          <Box display="flex" flexDirection="column" maxHeight="650px">
            <div style={{ flexGrow: 1 }}>
              <ChatList
                messages={messages}
                user={selectedUser}
                handleSendMessage={sendPrivateMessage}
                handleBack={() => setSelectedUser(undefined)}
              />
            </div>
          </Box>
        )}
      </Box>
    </Drawer>
  );
}
