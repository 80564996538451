import React, { useCallback, useEffect, useRef } from "react";
import { Box, Button } from "@mui/material";
import { jsPDF } from "jspdf";
import { IInvoice } from "api/invoice";
import { createAModelDocument } from "api/document";
import { getId } from "logic/utils";
import { formatTimestampToDate } from "logic/date";
import { addElementToPdf } from "logic/pdf";
import Toast from "app/Toast";
import MyDialog from "app/Dialog";

import InvoiceOld from "PDFTemplates/InvoiceOld";

export default function InvoicePdfModal({
  open,
  invoice,
  onClose,
  onDone,
}: {
  invoice: IInvoice;
  open: boolean;
  onClose: () => void;
  onDone?: () => void;
}) {
  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleExport = useCallback(async () => {
    try {
      if (headerRef.current) {
        let doc = new jsPDF();

        doc = await addElementToPdf({
          pdf: doc,
          element: headerRef.current,
          x: 0,
          y: 0,
        });

        doc.save(
          invoice?.number
            ? `Invoice-${invoice?.number}-${formatTimestampToDate(Number(new Date()))}.pdf`
            : `Invoice-${formatTimestampToDate(Number(new Date()))}.pdf`
        );
        const generatedPdf = doc.output("blob");
        await createAModelDocument({
          model: "invoice",
          id: getId(invoice),
          file: generatedPdf,
          description: `${new Date().toJSON().slice(0, 19)} - ${invoice.number}`,
          name: `invoice_${invoice.number}.pdf`,
          fileName: `invoice_${invoice.number}.pdf`,
        });
        Toast("Document saved", "success");
        // onClose();
        onDone && onDone();
      }
    } catch (error) {
      console.log(error);
    }
  }, [invoice, onDone]);

  useEffect(() => {
    const t = setTimeout(() => {
      handleExport();
    }, 3000);

    return () => clearTimeout(t);
  }, [handleExport]);

  return (
    <MyDialog title="Invoice Pdf" open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <Box width="21cm" m={2} mx="auto">
        <Button variant="contained" onClick={handleExport} sx={{ mb: 1 }}>
          Save
        </Button>
        <InvoiceOld invoice={invoice} ref={headerRef} />
      </Box>
    </MyDialog>
  );
}
