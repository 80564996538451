// import FooterLogo from '../../../assets/svg/footer1.svg?react';
import { ReactComponent as FooterLogo } from '../../../../assets/svg/footer1.svg'

function Footer({ pageNumber, DocNumber }) {
  return (
    <div className="relative w-full">
      <FooterLogo className='h-auto w-full' />
        {/* Page Info */}
        <div className="absolute left-[15%] bottom-[25%] flex items-center">
          <span className="text-[16px] font-bold text-white mr-1">Page</span>
          <span className="text-[16px] font-bold text-white">{pageNumber}</span>
        </div>
        
        {/* Document Info */}
        <div className="absolute right-5 bottom-[25%] flex items-center">
          <span className="text-[16px] text-slate-500">Document No:</span>
          <span className="text-[16px] text-white ml-1">{DocNumber}</span>
        </div>
      </div>
  );
}

export default Footer;
