import React, { useEffect, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";

export default function TextEditor({
  initialValue,
  setHtmlContent,
  onEditorInit
}: {
  initialValue?: string;
  setHtmlContent: (content: string) => void;
  onEditorInit: (editor: any) => void;
}) {
  const [value, setValue] = useState({ level: { content: "" } });
  const [text, setText] = useState("");

  const onEditorInputChange = (newValue: any, editor: any) => {
    setValue(newValue);
    setText(editor.getContent({ format: "text" }));
    setHtmlContent(editor.getContent());
  };

  return (
    <>
      <Editor
        apiKey="32pbjjm0beewvyu6h3lsha7ai7v8wjsrd7hz5i5eekqzx7dr"
        onInit={(evt, editor) => {
          onEditorInit(editor);
        }}
        onChange={(newValue: any, editor: any) => onEditorInputChange(newValue, editor)}
        initialValue={initialValue || "<p>Hello World!</p>"}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "advlist",
            "autolink",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "code",
            "fullscreen",
            "insertdatetime",
            "media",
            "table",
            "code",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | blocks | " +
            "bold italic | alignleft aligncenter " +
            "alignright alignjustify | bullist numlist outdent indent | " +
            "removeformat | help",

          content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
        }}
      />
    </>
  );
}