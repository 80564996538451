import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import { formatCurrency } from "logic/utils";
export default function QuotePage3({ data,pageNumber }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content({ data }) {
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[2%]">
          <div className="flex flex-col  items-center">
            <div className="flex flex-row bg-[#666463] w-2/3 py-4 px-8 justify-between">
              <span className="text-[16px] text-white font-light">Total:</span>
              <span className="text-[16px] text-white">
                {formatCurrency(
                  data?.totalOrder || data?.lines?.flat().reduce((prev, curr) => prev + curr.qty * curr.price, 0) || 0
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-[4%]">
          <div className="flex flex-col  items-center">
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 py-4 px-8 justify-between">
              <span className="text-[16px] text-white">Commission Rate</span>
              <span className="text-[16px] text-white font-bold">
                {data.overageCommission ? data.overageCommission : 0}%
              </span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-[16px] text-white">Commission Total:</span>
              <span className="text-[16px] text-white font-bold">
                {data.regularCommission ? formatCurrency(data.regularCommission) : "0.00"}
              </span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-[16px] text-white">Subtotal:</span>
              <span className="text-[16px] text-white font-bold">{formatCurrency(data.subTotal)}</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-[16px] text-white">Sales Tax:</span>
              <span className="text-[16px] text-white font-bold">{formatCurrency(data.salesTax)}</span>
            </div>
            <div className="flex flex-row bg-[#9e9e9e] w-2/3 p-4 px-8 justify-between">
              <span className="text-[16px] text-white">Total Freight:</span>
              <span className="text-[16px] text-white font-bold">{formatCurrency(data.totalFreight)}</span>
            </div>
            <div className="flex flex-row bg-primaryOrange w-2/3 p-4 px-8 justify-between">
              <span className="text-[16px] text-white">Quote Total:</span>
              <span className="text-[16px] text-white font-bold">
                {formatCurrency(
                  data?.totalOrder || data?.lines?.flat().reduce((prev, curr) => prev + curr.qty * curr.price, 0) || 0
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="mt-[10%]">
          <div className="flex flex-col p-4 w-full bg-[#ececec]">
            <span className="text-[12px]">Note:</span>
            <span className="text-[12px] font-light">
              1. * This is the standard lead time; please consult the factory for others. a. Expediting fees are due and
              nonrefundable upon release of purchase order to manufacture. b. DSPM will put forth the best effort
              possible to comply with required dates but not guaranteed.
            </span>
            <span className="text-[12px] font-light  mt-3">
              2. ** Unit and batteries ship separately, may not arrive same day.
            </span>
            <span className="text-[12px] font-light  mt-3">
              3. For future reference, please provide ship to zip code for most accurate freight cost.
            </span>
            <span className="text-[12px] font-light mt-3">
              4. Line to neutral loads only; unless otherwise stated on purchase order from 3Kva to 21Kva.
            </span>
            <span className="text-[12px] font-light mt-3">
              5. Number of battery cabinets may vary; dependent on type of batteries available.
            </span>
            <span className="text-[12px] font-light mt-3">
              6. Battery manufacturer subject to change based on availability of batteries.
            </span>
            <span className="text-[12px] font-light mt-3">
              7. Customer is responsible for installing batteries into cabinet(s).
            </span>
            <span className="text-[12px] font-light mt-3">
              8. DSPM is bidding our standard product in lieu of specifications; please review carefully.
            </span>
            <span className="text-[12px] font-light mt-3">9. Freight is PREPAID AND ADD unless otherwise noted.</span>
          </div>
        </div>
      </div>
    </div>
  );
}
