import { delete_, get, patch, post } from ".";

import { IItem } from "./items";
import { createAModelDocument } from "./document";
import { formatTimestampToDate } from "logic/date";

export interface POLineItem {
  id: string;
  blanketPO?: boolean;
  ItemId: IItem;
  POId: string;
  VendorId: string;
  cost: number;
  expectedDate: number;
  itemDescription: string;
  itemNo: string;
  itemName: string;
  leadTime: number;
  qty: number;
  recId: string;
  received: number;
  status: string;
  total: number;
  uom: string;
  deleted: boolean;
  createdAt: number;
  updatedAt: number;
  __v: number;
}
export interface IPO {
  lines: never[];
  id?: string;
  recId: string;
  type: string;
  status:
    | "Quote"
    | "Open"
    | "Printed"
    | "Closed"
    | "Acknowledged"
    | "Shipped"
    | "Received"
    | "Canceled"
    | "On Hold"
    | "Partially Shipped";
  description: string;
  VendorId: string;
  vendorEmail: string;
  vendorName: string;
  publicNote: string;
  date: number;
  number: string;
  sentDate: number;
  ackDate: number;
  actualShipDate: number;
  privateNote: string;
  vendorShippingNote: string;
  billingCompany: string;
  billingAttn: string;
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZipcode: string;
  billingPhone: string;
  billingEmail: string;
  billingCountry: string;
  billingFax: string;
  shippingCompany: string;
  shippingAttn: string;
  shippingAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingZipcode: string;
  shippingPhone: string;
  shippingEmail: string;
  shippingCountry: string;
  shippingFax: string;
  SOId: string;
  dateRequired: number;
  vendorConfirmation: number;
  shippingConfirmation: number;
  freightCompany: string;
  freightCompanyWebsite: string;
  manualTrackingNumber: string;
  trackingConfirmation: string;
  statusNotes: string;
  terms: string;
  poConf: string;
  taxable: number;
  nonTaxable: number;
  salesTax: number;
  total: number;
  approved: boolean;
  accounting: string;
  receiveNo: string;
  createdAt: number;
  shippingType?: "dspm" | "Client" | "Rep";
}

export const createPO = (data: IPO) => {
  return post(`/po`, data);
};

export const updatePO = (id: string, data: Partial<IPO>) => {
  return patch(`/po/${id}`, data);
};

export const getPO = () => {
  return get("/po");
};

export const deletePO = (id: string) => {
  return delete_(`/po/${id}`);
};

export const uploadPODocument = async ({
  blob,
  POId,
  poNumber,
}: {
  poNumber?: string;
  POId: string;
  blob: Blob | File;
}) => {
  try {
    return await createAModelDocument({
      model: "po",
      id: POId,
      file: blob,
      description: `${poNumber ? `-${poNumber}` : ""}, ${formatTimestampToDate(new Date())}`,
      fileName: "po.pdf",
      name: "po.pdf",
    });
  } catch (error) {
    throw error;
  }
};

export const sendEmailToVendor = (data: { POId: string; link: string; html: any }) => {
  return post(`/po/sendemail`, data);
};
export const editRequiredPo = (data: { requiredBy: number }, id: any) => {
  return patch(`/requiredpo/${id}`, data);
};
