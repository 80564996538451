import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { IEmployee } from "api/employee";
import { getId } from "logic/utils";
import Dialog from "app/Dialog";
import PasswordForm from "./PasswordForm";
import RoleForm from "./RolesForm";
import GeneralForm from "./GeneralForm";

export const AddEmployeeModal = ({
  open,
  initTab,
  setRefresh,
  initialVals,
  onClose,
}: {
  open: boolean;
  setRefresh?: any;
  initTab?: number;
  initialVals?: IEmployee;
  onClose: () => void;
}) => {
  const [tab, setTab] = useState(initTab || 0);

  useEffect(() => {
    if (open && initTab) {
      setTab(initTab);
    }
  }, [initTab, open]);

  let content = <></>;
  if (tab === 0) {
    content = <GeneralForm onClose={onClose} setRefresh={setRefresh} initialVals={initialVals} setTab={setTab} />;
  }
  if (tab === 1) {
    content = <RoleForm onBack={() => setTab(0)} employeeId={getId(initialVals)} setRefresh={setRefresh} />;
  }
  if (tab === 2) {
    content = <PasswordForm onClose={onClose} setTab={setTab} initialVals={initialVals} />;
  }

  let title = "Add New Employee";
  if (tab === 0 && initialVals && getId(initialVals)) {
    title = "Edit Employee";
  }
  if (tab === 1) {
    title = "Edit Employee's Roles";
  }
  if (tab === 2) {
    title = "Change Employee's Password";
  }

  return (
    <Dialog
      open={open}
      onClose={() => {
        onClose();
        setTab(0);
      }}
      maxWidth="sm"
      fullWidth
      title={title}
    >
      <Box m={1} flexWrap={"wrap"}>
        {content}
      </Box>
    </Dialog>
  );
};
