import React, { useState } from "react";
import { Tabs, Tab, useMediaQuery, Box } from "@mui/material";
import { useLock } from "common/Lock";
import HomePage from "./HomePage";
import { BasePaper } from "app/Paper";
import Topic from "pages/topic";

export default function Management() {
  const sPhone = useMediaQuery("(max-width:850px)");
  const [activeTab, setActiveTab] = useState(0);
  const { lock, setLock } = useLock();

  return (
    <BasePaper style={{ height: "100%" }}>
      <Box>
        <Box display="flex" mb={1} alignItems="center">
          <Tabs
            value={activeTab}
            onChange={(e, v) => {
              setActiveTab(v);
              setLock(true);
            }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            sx={{ maxWidth: sPhone ? "calc(100vw - 60px)" : "calc(100vw - 700px)" }}
          >
            <Tab label="Homepage" />
            <Tab label="Topic" />
          </Tabs>
        </Box>
        {activeTab === 0 && <HomePage />}
        {activeTab === 1 && <Topic />}
      </Box>
    </BasePaper>
  );
}
