import React from "react";
import { Box, Button, Typography } from "@mui/material";
import useSWR from "swr";

import MyDialog from "app/Dialog";
import { useSession } from "features/Session/sessionsSlice";
import { formatSeconds, formatTimesToHour } from "logic/date";
import { IEntrance } from "api/entrace";
import { getId } from "logic/utils";

export default function CheckoutModal({ open, onClose }: { open: boolean; onClose: () => void }) {
  const session = useSession();
  const { data: entrance } = useSWR<{ result: IEntrance[]; total: number }>(
    getId(session) ? `/employee/${getId(session)}/entrance` : null
  );
  const total = entrance?.result?.reduce((p, c) => (!c.active ? p + c.duration : p), 0) || 0;

  return (
    <MyDialog title="Checkout" open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography textAlign="center" fontWeight="bold" fontSize={24}>
        Goodbye ({session?.username})
      </Typography>
      <Typography mt={2} textAlign="center">
        Checked out at: {formatTimesToHour(Number(new Date()))}
      </Typography>
      <Typography mt={2} textAlign="center">
        Hours today: {formatSeconds(Math.round(total / 1000))}
      </Typography>
      <Box my={4} textAlign="center">
        <Button variant="contained" onClick={onClose}>
          Goodbye
        </Button>
      </Box>
    </MyDialog>
  );
}
