import Footer from "./footer/Footer";
import Header from "../PO/header/Header";
import { formatCurrency } from "logic/utils";
import { formatTimestampToDate } from "logic/date";

import SectionHeader from "./SectionHeader";

export default function QuotePage1({ data, items, QrCode, pageNumber }) {
  const getItem = (items) => {
    if (items) {
      let item = items[0]; // Get the first item from the array
      item = item?.ItemId ? item : null; // Assign item or null based on ItemId check
      return item; // Return the final value
    } else return null;
  };


  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} item={getItem(items)} QrCode={QrCode} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content({ data, item, QrCode }) {
  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };

  function extractDescriptionParts(description) {
    // Check if the description contains the * sign
    if (!description.includes("*")) {
      return { mainText: description.trim(), otherTexts: [] };
    }

    // Split the description into lines based on the asterisk character
    const lines = description.split(/\*\s*/);

    // Initialize the main text and other texts
    let mainText = "";
    const otherTexts = [];

    // Iterate through each line to categorize them
    for (let i = 0; i < lines.length; i++) {
      const trimmedLine = lines[i].trim();

      // Identify the first line as main text
      if (i === 0) {
        mainText = trimmedLine; // This will be the main text
      } else {
        // Collect other texts
        if (trimmedLine) {
          // Ensure it's not an empty line
          otherTexts.push(trimmedLine);
        }
      }
    }

    // Return the result as an object
    return {
      mainText,
      otherTexts,
    };
  }

  const splitEmails = (emails) => {
    if (!emails) return []; // Return an empty array if the input is empty or null
    return emails
      .split(";")
      .map((email) => email.trim())
      .filter((email) => email); // Trim and filter empty entries
  };

  return (
    <div className="flex flex-col items-center h-[98%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[2%]">
          <div className="flex flex-row w-full justify-between">
            <div className="flex flex-col justify-center">{QrCode}</div>
            <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
              <div>
                <span className="text-[14px]">Agency:</span>
              </div>
              <div>
                <span className="text-[14px] font-light">{data.rep ? data.rep : "-"}</span>
              </div>
            </div>
            <div className="flex flex-col bg-[#ececec] p-3 w-[41%]">
              <div>
                <span className="text-[14px]">Requested:</span>
              </div>
              <div>
                <span className="text-[14px] font-light">{data.requestedByName ? data.requestedByName : "-"}</span>
              </div>
              <div className="mt-[4%]">
                <span className="text-[14px]">By:</span>
              </div>
              <div>
                {data.requestedByEmail ?  splitEmails(data.requestedByEmail).length === 0 ? ( // Check if there are no emails
                  <div>
                    <span className="text-[14px] font-light">-</span>
                  </div>
                ) : (
                  splitEmails(data.requestedByEmail).map((email, index) => (
                    <div key={index}>
                      <span className="text-[14px] font-light">{email}</span>
                    </div>
                  ))
                ) :'-'}
              </div>
              <div>
                <span className="text-[14px] font-light">{data.requestedByPhone ? data.requestedByPhone : ""}</span>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[2%] bg-[#6a6a6a]">
          <div className="flex flex-row p-3">
            <div className="flex flex-col flex-1">
              <div className="flex flex-row h-full">
                <div className="flex flex-col justify-around">
                  <div>
                    <span className="text-white text-[12px] font-light">Quote # :</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-light">Prepared On:</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-light">Expires:</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-light">Lead Time:</span>
                  </div>
                </div>
                <div className="flex flex-col ml-[4%] justify-around">
                  <div>
                    <span className="text-white text-[12px]">{data.number ? data.number : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.entryDate ? formatTimestampToDate(data.entryDate) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.expireDate ? formatTimestampToDate(data.expireDate) : '-'}</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">{data.leadTime ? data.leadTime : '-'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div className="flex flex-row">
                <div className="flex flex-col">
                  <div>
                    <span className="text-white text-[12px] font-light">Ship Via:</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-light">Freight Terms:</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-light">Payment Terms:</span>
                  </div>
                </div>
                <div className="flex flex-col ml-[4%] ">
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px]">-</span>
                  </div>
                  <div>
                    <span className="text-white text-[12px] font-bold">
                      {data.paymentTerms ?  insertLineBreaksAfterWords(data.paymentTerms, 6) : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-[4%]">{item != null ? <SectionHeader title={item.ItemId.no} number={1} /> : null}</div>
        {item != null ? (
          <div className="mt-[2%]">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left p-2">
                    <div className="">
                      <span className="text-[10px] text-primaryOrange">[Group]-Line</span>
                    </div>
                  </th>
                  <th className="text-left p-2">
                    <div>
                      <span className="text-[10px]">Item NO./Description</span>
                    </div>
                  </th>
                  <th className="text-left p-2">
                    <div>
                      <span className="text-[10px]">Qty</span>
                    </div>
                  </th>
                  <th className="text-left p-2">
                    <div>
                      <span className="text-[10px]">Unit Price</span>
                    </div>
                  </th>
                  <th className="text-left p-2">
                    <div>
                      <span className="text-[10px]">Total Price</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-left p-2 align-top">
                    <div>
                      <span className="text-[10px]">[1]-1</span>
                    </div>
                  </td>
                  <td className="text-left p-2 align-top">
                    <div>
                      <span className="text-[11px]">{extractDescriptionParts(item.description).mainText}</span>
                    </div>

                    {extractDescriptionParts(item.description).otherTexts.map((item, index) => {
                      return (
                        <div key={index}>
                          <span className="text-[10px] font-light">* {item}</span>
                        </div>
                      );
                    })}
                  </td>
                  <td className="text-left p-2 align-top">
                    <div>
                      <span className="text-[10px]">{item.qty}</span>
                    </div>
                  </td>
                  <td className="text-left p-2 align-top">
                    <div>
                      <span className="text-[10px] font-light">{formatCurrency(item.price)}</span>
                    </div>
                  </td>
                  <td className="text-left p-2 align-top">
                    <div>
                      <span className="text-[10px] font-light">{formatCurrency(item.total)}</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  );
}
