import React, { useEffect, useRef, useState } from "react";
import { Modal, Box, TextField, ListItemText, List, Divider } from "@mui/material";
import { initializeDB } from "logic/DB/Schema";
import { searchItems, searchPanels } from "logic/DB/Search";

import { Link } from "react-router-dom";
import { getId } from "logic/utils";
import { fetchItems } from "logic/DB/Item";

export default function OmniBar({ open, onClose }: { open: boolean; onClose: () => void }) {
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  const [query, setQuery] = useState("");
  const [result, setResult] = useState<
    {
      id: string;
      no: string;
      name: string;
      description: string;
      cluster: string;
    }[]
  >([]);
  const [resultPanels, setResultPanels] = useState<{ name: string; path: string }[]>([]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (query) {
        initializeDB().then(() => {
          fetchItems().then((items) => {
            const result = searchItems({ items, query });
            setResult(
              result.map((i) => ({
                ...i.item,
              }))
            );
          });
        });
        setResultPanels(searchPanels(query).map((i) => i.item));
      } else {
        setResult([]);
        setResultPanels([]);
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [query]);

  return (
    <Modal open={open} onClose={onClose} aria-labelledby="omni-modal" aria-describedby="omni bar, search anything">
      <Box
        sx={{
          position: "absolute" as "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          borderRadius: "10px",
          border: "none",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          width: "40vw",
          p: 2,
          "& .result-item": {
            cursor: "pointer",
            padding: "8px 12px",
            transition: "background 0.3s ease",
          },
          "& .result-item:hover": {
            background: "#f0f0f0",
            borderRadius: "6px",
          },
          "& a": {
            color: "#007bff",
            textDecoration: "none",
            fontWeight: "500",
          },
          "& a:hover": {
            textDecoration: "underline",
          },
        }}
      >
        <TextField
          autoFocus
          ref={inputRef}
          label="Search anything in anywhere..."
          fullWidth
          onChange={(e) => setQuery(e.target.value)}
          sx={{
            mb: 2,
            "& .MuiInputBase-input": {
              padding: "10px 14px",
            },
          }}
        />
        <List>
          {resultPanels.slice(0, 5).map((i) => (
            <div className="result-item" key={i.path}>
              <Link to={i.path}>{i.name}</Link>
            </div>
          ))}
        </List>
        <Divider sx={{ my: 2 }} />
        <List sx={{ height: 200, overflowY: "auto", paddingRight: "8px" }}>
          {result.map((i) => (
            <div className="result-item" key={i.id}>
              <Link to={`/panel/inventory/items/${getId(i)}`}>
                <ListItemText primary={i.name} secondary={`${i.name} - ${i.description}`} />
              </Link>
            </div>
          ))}
        </List>
      </Box>
    </Modal>
  );
}
