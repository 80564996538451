import React from "react";
import { Box } from "@mui/material";

export default function PurchaseHelp({ url }: { url: string }) {
  return (
    <Box sx={{ m: "-10px" }}>
      <iframe
        style={{
          padding: "0 !important",
        }}
        src={url}
        title="Web View"
        width="100%"
        height="1000px"
      ></iframe>
    </Box>
  );
}
