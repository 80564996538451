import { styled, Paper, PaperProps } from "@mui/material";

export const BasePaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: "8px",
  padding: "8px",
}));

export const IconPaper = styled(Paper)<PaperProps>(({ theme }) => ({
  borderRadius: 10,
  padding: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  color: "#fff",
}));
