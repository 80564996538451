function ItemTable({ groupLine, description, qty, unitPrice, totalPrice, descriptionElement }) {
  const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
    const words = text.split(" "); // Split the text into words
    const elements = []; // Array to hold React elements

    words.forEach((word, index) => {
      elements.push(<span key={index}>{word} </span>); // Push each word as a span

      // After every 'wordsPerBreak', add a line break
      if ((index + 1) % wordsPerBreak === 0) {
        elements.push(<br key={`break-${index}`} />); // Push a line break
      }
    });

    return elements; // Return the array of React elements
  };

  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="text-left p-2">
            <div className="">
              <span className="text-[10px] text-primaryOrange">[Group]-Line</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Item NO./Description</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Qty</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Unit Price</span>
            </div>
          </th>
          <th className="text-left p-2">
            <div>
              <span className="text-[10px]">Total Price</span>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left p-2 align-top">
            <div>
              <span className="text-[10px]">{groupLine}</span>
            </div>
          </td>
          <td className="text-left p-2 align-top w-[60%]">
            {descriptionElement != null
              ? descriptionElement
              : description?.map((item, index) => <span className="text-[10px] text-justify">{item}</span>)}
          </td>
          <td className="text-left p-2 align-top">
            <div>
              <span className="text-[10px]">{qty}</span>
            </div>
          </td>
          <td className="text-left p-2 align-top">
            <div>
              <span className="text-[10px] font-light">{unitPrice}</span>
            </div>
          </td>
          <td className="text-left p-2 align-top">
            <div>
              <span className="text-[10px] font-light">{totalPrice}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default ItemTable;
