import { useRef, useState } from 'react';
import POpage1 from '../PDFTemplates/New/PO/POpage1';

import POpage2 from '../PDFTemplates/New/PO/POpage2';
import POpage3 from '../PDFTemplates/New/PO/POpage3';
import POpage4 from '../PDFTemplates/New/PO/POpage4';
import POpage5 from '../PDFTemplates/New/PO/POpage5';
import POpage6 from '../PDFTemplates/New/PO/POpage6';
import POpage7 from '../PDFTemplates/New/PO/POpage7';
import POpage8 from '../PDFTemplates/New/PO/POpage8';
import POpage9 from '../PDFTemplates/New/PO/POpage9';
import QuotePage0 from '../PDFTemplates/New/Quote/QuotePage0';
import QuotePage1 from '../PDFTemplates/New/Quote/QuotePage1';
import QuotePage2 from '../PDFTemplates/New/Quote/QuotePage2';
import QuotePage3 from '../PDFTemplates/New/Quote/QuotePage3';
import QuotePage4 from '../PDFTemplates/New/Quote/QuotePage4';
import QuotePage5 from '../PDFTemplates/New/Quote/QuotePage5';
import QuotePage6 from '../PDFTemplates/New/Quote/QuotePage6';
import QuotePage7 from '../PDFTemplates/New/Quote/QuotePage7';
import QuotePage8 from '../PDFTemplates/New/Quote/QuotePage8';
import QuotePage9 from '../PDFTemplates/New/Quote/QuotePage9';
import QuotePage10 from '../PDFTemplates/New/Quote/QuotePage10';
import QuotePage11 from '../PDFTemplates/New/Quote/QuotePage11';
import QuotePage12 from '../PDFTemplates/New/Quote/QuotePage12';
import QuotePage13 from '../PDFTemplates/New/Quote/QuotePage13';
import QuotePage14 from '../PDFTemplates/New/Quote/QuotePage14';
import QuotePage15 from '../PDFTemplates/New/Quote/QuotePage15';
import QuotePage16 from '../PDFTemplates/New/Quote/QuotePage16';
import QuotePage17 from '../PDFTemplates/New/Quote/QuotePage17';
import QuotePage18 from '../PDFTemplates/New/Quote/QuotePage18';
import QuotePage19 from '../PDFTemplates/New/Quote/QuotePage19';
import QuotePage20 from '../PDFTemplates/New/Quote/QuotePage20';
import mainData from '../assets/data/main.json';
import itemData from '../assets/data/item.json';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

function PdfPages() {
  // Reference for the main content to capture
  const pagesRef = useRef(null);
  const [selectedPages, setSelectedPages] = useState('PO'); // State for selected pages
  const [downloadLoading,setDownloadLoading] = useState(false);

  const handleDownloadPDF = async () => {
    setDownloadLoading(true)

    const pdf = new jsPDF('p', 'pt', 'a4');
    const pages = pagesRef.current.querySelectorAll('.pdf-page');
    
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const canvas = await html2canvas(page, { scale: 2 }); // Higher scale for better quality
      // const imgData = canvas.toDataURL('image/png');
      const imgData = canvas.toDataURL('image/jpeg', 0.8); // Using JPEG format with quality set to 0.8

      const imgWidth = 595.28; // A4 width in points
      const imgHeight = (canvas.height * imgWidth) / canvas.width;

      if (i > 0) pdf.addPage();
      pdf.addImage(imgData, 'JPEG', 0, 0, imgWidth, imgHeight, undefined, 'MEDIUM');
    }
    
    pdf.save(selectedPages + '-document.pdf');
    setDownloadLoading(false)
  };

  const Quote = [
    { component: <QuotePage0 />, key: 'page1' },
    { component: <QuotePage1 />, key: 'page2' },
    { component: <QuotePage2 />, key: 'page3' },
    { component: <QuotePage3 />, key: 'page4' },
    { component: <QuotePage4 />, key: 'page5' },
    { component: <QuotePage5 />, key: 'page6' },
    { component: <QuotePage6 />, key: 'page7' },
    { component: <QuotePage7 />, key: 'page8' },
    { component: <QuotePage8 />, key: 'page9' },
    { component: <QuotePage9 />, key: 'page10' },
    { component: <QuotePage10 />, key: 'page11' },
    { component: <QuotePage11 />, key: 'page12' },
    { component: <QuotePage12 />, key: 'page13' },
    { component: <QuotePage13 />, key: 'page14' },
    { component: <QuotePage14 />, key: 'page15' },
    { component: <QuotePage15 />, key: 'page16' },
    { component: <QuotePage16 />, key: 'page17' },
    { component: <QuotePage17 />, key: 'page18' },
    { component: <QuotePage18 />, key: 'page19' },
    { component: <QuotePage19 />, key: 'page20' },
    { component: <QuotePage20 />, key: 'page21' },



  ];

  // Create an array of the PO page components to render
  const PO = [
    { component: <POpage1 data={mainData} />, key: 'page1' },
    { component: <POpage2 data={mainData} />, key: 'page2' },
    { component: <POpage3 />, key: 'page3' },
    { component: <POpage4 />, key: 'page4' },
    { component: <POpage5 />, key: 'page5' },
    { component: <POpage6 />, key: 'page6' },
    { component: <POpage7 />, key: 'page7' },
    { component: <POpage8 itemData={itemData.result} />, key: 'page8' },
    { component: <POpage9 />, key: 'page9' },

  ];

  // Choose which pages array to render based on selection
  const pagesToRender = selectedPages === 'PO' ? PO : Quote;

  return (
    <div className="flex flex-col h-screen">
      <header className="bg-primaryBlue text-white p-4 flex justify-between items-center">
        <h1 className="text-xl">Docs</h1>
        <div className="flex items-center space-x-4">
          {/* Dropdown to select pages */}
          <select
            value={selectedPages}
            onChange={(e) => setSelectedPages(e.target.value)}
            className="bg-white text-primaryBlue px-2 py-1 rounded shadow"
          >
            <option value="PO">PO</option>
            <option value="Quote">Quote</option>
          </select>
          <button 
            onClick={handleDownloadPDF} 
            className="bg-white text-primaryBlue px-4 py-2 rounded shadow hover:bg-gray-200 transition"
          >
            {downloadLoading ? (
              <div className="w-7 h-7 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>

            ) : 
            (<span>
              Download PDF
            </span>)
            }
          </button>
        </div>
      </header>
      <main className="flex-1 overflow-y-auto bg-gray-100" ref={pagesRef}>
        {pagesToRender.map(({ component, key }) => (
          <div className="w-[21cm] h-[29.7cm] bg-white border border-gray-300 mx-auto my-4 shadow-lg pdf-page" key={key}>
            {component} {/* Render the corresponding page component here */}
          </div>
        ))}
      </main>
    </div>
  );
}

export default PdfPages;
