import Footer from "./footer/Footer";
import Header from "./header/Header";
import { formatTimestampToDate } from "logic/date";
import { formatCurrency } from "logic/utils";
export default function POpage8({ data, itemData }) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content data={data} itemsData={itemData} />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={8} DocNumber={data?.number} />
      </div>
    </div>
  );
}

function Content({ data, itemsData }) {
  const itemData = itemsData?.[0];

  const billingData = {
    vendor: data?.vendorName,
    vendorEmail: data?.vendorEmail,
    BillTo: data?.billingCompany,
    shipTo: data?.shippingCompany,
  };
  const shippingData = {
    phoneNumber: data?.shippingPhone,
    faxNumber: "",
    rmaNumber: "",
    terms: data?.terms,
    dateAcknowledged: formatTimestampToDate(data?.ackDate),
    shipVia: data?.shippingCompany,
  };

  // Table headers
  const shippingDataHeader = ["Phone #", "Fax #", "RMA No.", "Terms", "Date Acknowledged", "Ship Via"];

  // Table headers
  const itemtHeaders = ["Item", "Descripton", "Qty", "U/M", "Date Required", "Rate", "MPN", "Amount"];

  // const insertLineBreaksAfterWords = (text, wordsPerBreak) => {
  //   const words = text.split(" "); // Split the text into words
  //   const elements = []; // Array to hold React elements

  //   words.forEach((word, index) => {
  //     elements.push(<span key={index}>{word} </span>); // Push each word as a span

  //     // After every 'wordsPerBreak', add a line break
  //     if ((index + 1) % wordsPerBreak === 0) {
  //       elements.push(<br key={`break-${index}`} />); // Push a line break
  //     }
  //   });

  //   return elements; // Return the array of React elements
  // };

  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full h-full">
        <div className="flex flex-col">
          <span className="text-primaryBlue font-bold text-2xl">Purchase Order</span>
        </div>
        <div className="flex flex-col mt-[2%]">
          <table className="w-1/2 border-collapse">
            <tbody>
              <tr className="h-full">
                <td className="border border-table p-[3%] w-1/2 align-top">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black">09-17-24</span>
                  </div>
                </td>
                <td className="border border-table p-[3%] w-1/2 align-top">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black">{itemData?.poNumber}</span>
                  </div>
                </td>
              </tr>
              <tr className="h-full">
                <td colSpan={2} className="border border-table p-[3%] align-top">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black">&#8203;</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className=" flex flex-col mt-[2%]">
          <table className="w-full border-collapse">
            <tbody>
              <tr>
                <td className="border border-table p-[1%] w-1/3 align-top">
                  <div className="flex flex-col ">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Vendor:</span>
                    </div>
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] break-words whitespace-normal text-black">
                        {billingData.vendor}
                      </span>
                      <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
                      <span className="text-[12px] font-light ml-[1%] text-primaryBlue">{billingData.vendorEmail}</span>
                    </div>
                  </div>
                </td>

                <td className="border border-table p-[1%] w-1/3 align-top">
                  <div className="flex flex-col ">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Bill To:</span>
                      {/* <span className="text-[12px] font-bold ml-[1%]">-</span> */}
                    </div>
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        {billingData.BillTo}
                      </span>
                      <span className="text-[12px] font-bold ml-[1%]">&#8203;</span>
                    </div>
                  </div>
                </td>
                <td className="border border-table p-[1%] w-1/3 align-top">
                  <div className="flex flex-col ">
                    <div className="flex flex-col">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">Ship To:</span>
                      {/* <span className="text-[12px] font-bold ml-[1%]">-</span> */}
                    </div>
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        {billingData.shipTo}
                      </span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className=" flex flex-col mt-[2%]">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
                {shippingDataHeader?.map((header, index) => (
                  <th key={index} className="border border-table p-[1%] align-top">
                    <div className="flex flex-col items-start">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">{header}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="border border-table p-[1%] py-[1.5%] w-[14%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.phoneNumber}
                    </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] py-[1.5%] w-[14%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.faxNumber}
                    </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] py-[1.5%] w-[14%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.rmaNumber}
                    </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] py-[1.5%] w-[24%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.terms}
                    </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] py-[1.5%] w-[20%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.dateAcknowledged}
                    </span>
                  </div>
                </td>
                <td className="border border-table p-[1%] py-[1.5%] w-[14%]">
                  <div className="flex flex-col ">
                    <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                      {shippingData.shipVia}
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="flex flex-col mt-[2%] h-full">
          <table className="min-w-full border border-gray-300">
            <thead>
              <tr>
                {itemtHeaders?.map((header, index) => (
                  <th key={index} className="border border-table p-[1%] align-top">
                    <div className="flex flex-col items-start">
                      <span className="text-[12px] font-bold ml-[1%] text-primaryOrange">{header}</span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {itemsData?.map((item, index) => (
                <tr key={index}>
                  <td className="border border-table p-[1%] py-[1.5%] w-[9%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        &#8203;
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[2%]  w-[19%] align-top">
                    <div className="flex flex-col w-full h-full ">
                      <span className="text-[10px] font-light h-full text-black break-words whitespace-normal   ">
                        {item.itemDescription}
                        {/* {insertLineBreaksAfterWords(itemTableData.description, 5)}{" "} */}
                        {/* Render the elements */}
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[8%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        {item.qty}
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[8%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        {item.ItemId.uom}
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[15%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        {formatTimestampToDate(item.expectedDate)}
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[13%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        &#8203;
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[15%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        &#8203;
                      </span>
                    </div>
                  </td>
                  <td className="border border-table p-[1%] py-[1.5%] w-[13%] align-top">
                    <div className="flex flex-col ">
                      <span className="text-[12px] font-light ml-[1%] text-black break-words whitespace-normal">
                        &#8203;
                      </span>
                    </div>
                  </td>
                </tr>
              ))}
              <tr className="bg-primaryBlue">
                <td colSpan="8" className="border border-table p-[1%]">
                  <div className="flex flex-row w-full items-start">
                    <div className="flex flex-col  me-[8%]">
                      <span className="text-[12px] text-white font-bold">Total</span>
                    </div>
                    <div className="flex flex-col flex-1">
                      <span className="text-[12px] text-white font-light">{formatCurrency(itemData?.total)}</span>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
