import Footer from "./footer/Footer";
import Header from "../PO/header/Header";

export default function QuotePage9({data,pageNumber}) {
  return (
    <div className="flex flex-col items-center h-full w-full  mx-auto">
      <div className="flex w-full">
        <Header />
      </div>
      <div className="flex flex-1 w-full">
        <Content />
      </div>
      <div className="flex w-full">
        <Footer pageNumber={pageNumber} DocNumber={data.number} />
      </div>
    </div>
  );
}

function Content() {
  const texts = {
    g: "Seller assumes no risk or liability for the suitability or unsuitability or results of its products, used in combination with any electrical or electronic components, circuits, systems, assemblies, or any other material substances, or environments.",
    h: "H. Any returned products electrically or mechanically destroyed by Buyer or third parties will not be covered under this warranty, and will not be returned to Buyer, but will be scrapped by Seller.",
    i: "This warranty shall exist for a period of twelve (12) months after the date of shipment from the Seller and is considered null and void if components or subassemblies other than those supplied or approved by Seller are used in the assembly of Seller’s products, or if Sellers product is modified in any way without written authorization of Seller.",
    j: "Developmental products of Seller are warranted to be free from defects in materials and workmanship and to meet the applicable specifications only at the time of receipt of Buyer and for no longer period of time. All accepted deliveries are deemed to be free from defects as of the time of delivery.",
    k: "Product sold but not manufactured by the Seller will be warranted as to defects in material and workmanship consistent with the warranty policy of the original manufacturer of the product. The Seller's only obligation shall be to assign Buyer, to the extent possible, whatever warranty the Seller receives from said manufacturer. In no event shall the Seller be liable for loss, damage or expense directly or indirectly arising from the use of the units or from any other cause, except as expressly stated in this warranty. THERE ARE NO WARRANTIES, WHICH EXTEND BEYOND THE DESCRIPTION ON THE FACE HEREOF. THE SELLER DISCLAIMS ANY IMPLIED WARRANTY OF MERCHANTABILITY OF THE GOODS OR OF THE FITNESS OF THE GOODS FOR ANY INTENDED PURPOSE. The Seller is not liable for, and the Buyer waives any right of action it has or may have against the Seller for any consequential or special damages arising out of any breach of warranty and for any damages the Buyer may claim for damage to any property or injury or death to any person arising out of its purchase or the use, operation, or maintenance of the product. The Seller will not be liable for any labor subcontracted or performed by the Buyer for preparation of item under warranty for return to the Seller’s factory or for preparation work for field repair or replacement. Invoicing of the Seller for labor either performed or subcontracted by the Buyer will not be considered as a liability by the Seller. The Seller's obligations under this warranty are conditioned upon timely receipt of all payments in strict accordance with payment terms, time being of the essence in this regard. During the time while the Seller has not received any amount overdue, the Seller shall have no obligation under this warranty. The expiration date of the warranty shall not be extended upon payment of the overdue amount.",
  };
  return (
    <div className="flex flex-col items-center h-[88%] w-full ">
      <div className="flex flex-col mt-[2%] px-[7.5%] w-full">
        <div className="mt-[4%]">
          <div className="flex flex-col">
            <div className="flex gap-1">
              <span className="text-primaryOrange text-[16px]">G.</span>
              <span className="font-light text-[16px] text-justify">{texts.g}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-primaryOrange text-[16px]">H.</span>
              <span className="font-light text-[16px] text-justify">{texts.h}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-primaryOrange text-[16px]">I.</span>
              <span className="font-light text-[16px] text-justify">{texts.i}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-primaryOrange text-[16px]">J.</span>
              <span className="font-light text-[16px] text-justify">{texts.j}</span>
            </div>
            <div className="flex gap-1">
              <span className="text-primaryOrange text-[16px]">K.</span>
              <span className="font-light text-[16px] text-justify">{texts.k}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
