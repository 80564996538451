import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import { host } from "host";
import { INote } from "api/note";
import { deleteAModelDocument, updateAccseeDocument } from "api/document";
import { fileType } from "logic/fileType";
import ConfirmModal from "features/Modals/Confirm";
import { useLock } from "common/Lock";
import NewDataGrid from "app/NewDataGrid";
import { Download, Delete, DetialIcon } from "app/IconPack";
import Toast from "app/Toast";
import BoolEditor from "@inovua/reactdatagrid-community/BoolEditor";
import DataTable from "common/DataTable";

export default function DocumentTable({
  itemId,
  model,
  url,
  refresh,
  downloadAs,
  setRefresh,
  setAddModal,
  setSelected,
  ticket,
}: {
  model: string;
  itemId: string;
  url?: any;
  refresh?: number;
  downloadAs?: string;
  setRefresh?: React.Dispatch<React.SetStateAction<number>>;
  setAddModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSelected?: React.Dispatch<React.SetStateAction<INote | undefined>>;
  ticket?: boolean;
}) {
  const { lock } = useLock();
  const [open, setOpen] = useState(false);
  const [docId, setDocId] = useState("");

  const handleDelete = async (data: any) => {
    try {
      await deleteAModelDocument(docId as string);
      Toast("Delete Successfully", "success");
    } catch (error) {
      console.log(error);
    } finally {
      setRefresh && setRefresh((p) => p + 1);
      setOpen(false);
    }
  };

  const columns = [
    {
      name: "action_01",
      header: "",
      width: 65,
      render: ({ data }: any) => {
        return (
          <Box display="flex" alignItems="center">
            <div
              style={{ cursor: "pointer", marginTop: "1px" }}
              title="View"
              onClick={() => {
                setSelected && setSelected(data);
                setAddModal && setAddModal(true);
              }}
            >
              <DetialIcon />
            </div>

            {!data.modelType?.includes("cluster") ? (
              <div title="Delete">
                <IconButton
                  disabled={lock}
                  onClick={() => {
                    setOpen(true);
                    setDocId(data?.id);
                  }}
                  sx={{ p: 0, mx: "5px", mb: "4px", cursor: "pointer" }}
                >
                  <Delete sx={{ width: 12, height: 12 }} />
                </IconButton>
              </div>
            ) : (
              <></>
            )}
            <div title="Download">
              <IconButton disabled={lock} sx={{ p: 0 }}>
                <a download={downloadAs || true} href={data.path?.includes("http") ? data.path : host + data.path}>
                  <Download sx={{ width: 12, height: 12 }} />
                </a>
              </IconButton>
            </div>
          </Box>
        );
      },
    },
    { name: "createdAt", header: "Date", type: "date", defaultWidth: 140 },
    { name: "creator", header: "Creator", defaultWidth: 120 },
    { name: "name", header: "Name", flex: 1, minWidth: 120 },
    // { name: "number", header: "Number", defaultWidth: 100 },
    { name: "description", header: "Description", minWidth: 200, flex: 1 },
    {
      name: "clientRepDocAccess",
      header: "View by Client/Rep",
      type: "boolean",
      editor: BoolEditor,
      editable: true,
      defaultWidth: 140,
      defaultOperator: "eq",
    },
    { name: "", header: "File Type", render: ({ data }: any) => fileType(data?.path), defaultWidth: 120 },
  ];

  const ticketCols = [
    {
      name: "action_01",
      header: "",
      width: 65,
      render: ({ data }: any) => {
        return (
          <Box display="flex" alignItems="center" style={{ gap: 4 }}>
            <div
              style={{ cursor: "pointer" }}
              title="View"
              onClick={() => {
                setSelected && setSelected(data);
                setAddModal && setAddModal(true);
              }}
            >
              <DetialIcon />
            </div>
            <div
              onClick={() => {
                setOpen(true);
                setDocId(data?.id);
              }}
              title="Delete"
              style={{ cursor: "pointer" }}
            >
              <Delete sx={{ width: 12, height: 12 }} />
            </div>
            <a
              title="Download"
              download={downloadAs || true}
              href={data.path?.includes("http") ? data.path : host + data.path}
            >
              <Download sx={{ width: 12, height: 12 }} />
            </a>
          </Box>
        );
      },
    },
    { name: "createdAt", header: "Date", type: "date", defaultWidth: 140 },
    { name: "creator", header: "Creator", defaultWidth: 120 },
    { name: "name", header: "Name", flex: 1 },
    { name: "", header: "File Type", render: ({ data }: any) => fileType(data?.path), defaultWidth: 120 },
  ];

  const handleColumnEdit = async (c: any) => {
    try {
      if (c.columnId === "clientRepDocAccess") {
        await updateAccseeDocument(docId, { clientRepDocAccess: c.value });
        Toast("Updated successfully", "success");
      }
      // switch (columnId) {
      //   case "clientRepDocAccess":
      //     await updateAModelDocument(docId, undefined, undefined, undefined, undefined, data?.clientRepDocAccess);
      //     break;
      //   default:
      //     console.warn(`Unhandled column edit: ${columnId}`);
      //     return;
      // }
      // await updateAModelDocument(docId, { clientRepDocAccess: data?.clientRepDocAccess });
    } catch (error) {
      console.log(error);
    } finally {
      setRefresh && setRefresh((p) => p + 1);
    }
  };

  return (
    <>
      <ConfirmModal open={open} onClose={() => setOpen(false)} text="Document" onConfirm={handleDelete} />
      {/* <DataTable
        columns={ticket ? ticketCols : columns}
        url={url ? url : `/document/${model}/${itemId}`}
        onCellValueChanged={handleColumnEdit}
        onRowSelected={(data: any) => {
          setDocId(data?.id);
        }}
        key={refresh}
       
      /> */}
      <NewDataGrid
        columns={ticket ? ticketCols : columns}
        url={url ? url : `/document/${model}/${itemId}`}
        onEditComplete={handleColumnEdit}
        onRowSelected={(data: any) => {
          setDocId(data?.id);
        }}
        refresh={refresh}
        style={{ marginBottom: "10px", height: "calc(100vh - 250px)" }}
      />
    </>
  );
}
