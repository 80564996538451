import React, { useEffect, useState } from "react";
import { Box, SxProps, Theme } from "@mui/material";
import { QrReader } from "react-qr-reader";
// import CameraswitchIcon from "@mui/icons-material/Cameraswitch";

// import CameraSelect from "./CameraSelect";
import useMediaDevices from "common/hooks/useMediaDevices";

export default function QRScanner({
  sx,
  height,
  width,
  onResult,
}: {
  sx?: SxProps<Theme>;
  height?: number | string;
  width?: number | string;
  onResult?: (result?: string) => void;
}) {
  const { devices } = useMediaDevices();
  const [showCamera, setShowCamera] = useState(false);
  const [constraints, setConstraints] = useState<MediaTrackConstraints>({ facingMode: "environment" });

  useEffect(() => {
    let t: ReturnType<typeof setTimeout>;
    if (!showCamera) {
      t = setTimeout(() => setShowCamera(true), 100);
    }

    return () => clearTimeout(t);
  }, [showCamera]);

  useEffect(() => {
    if (devices?.length > 0) {
      setConstraints((p) => ({ ...p, deviceId: devices[0].deviceId }));
      setShowCamera(false);
    }
  }, [devices]);

  return (
    <Box sx={sx}>
      {/* <Box display="flex" alignItems="center" gap={1}>
        <CameraSelect
          value={(constraints?.deviceId as string) || null}
          onChange={(v) => {
            setConstraints((p) => ({ ...p, deviceId: v }));
            setShowCamera(false);
          }}
        />
        <IconButton
          onClick={() => {
            setConstraints((p) => ({ ...p, facingMode: p?.facingMode === "user" ? "environment" : "user" }));
            setShowCamera(false);
          }}
        >
          <CameraswitchIcon />
        </IconButton>
      </Box> */}
      {
        <div
          style={{
            height: height || 400,
            width: width || 400,
            textAlign: "center",
            margin: "auto",
          }}
        >
          <QrReader
            constraints={constraints}
            onResult={(p) => {
              if (onResult) {
                onResult(p?.getText());
              }
            }}
          />
        </div>
      }
    </Box>
  );
}
